import { useState, useEffect } from "react";
import $ from 'jquery';


const Login = (props) =>  {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    const inputChangeHandler = (setFunction, event) => {
        setFunction(event.target.value)
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
        setShowError(false)
        setErrorMsg("");


        var form = new FormData();
        form.append("email", email);
        form.append("password", password);

        var settings = {
            "url": "https://aprocal.plego.cloud/buildfly_api/api/login",
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Client-Service": "app-client",
                "Auth-Key": "AmeriPro App",
                "Content-Type": "application/json",
                "email": email,
                "password": password
            },
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": form
        };

        $.ajax(settings).done(function (response) {
            if(response === undefined){
                setShowError(true)
                setErrorMsg('Mismatched email or password.')
                return
            }
            var data = JSON.parse(response)

            localStorage.setItem('userID', data.id)
            localStorage.setItem('userToken', data.token)

            window.location.href = "/"
        });
    }

    
    return (
        <div className="loginMain">
            <div className="center">
                <div className="logo">
                    <img src={require('../../assets/images/logo.png')}></img>
                </div>
                <div className="head">
                    <h1>LOGIN NOW</h1>
                    <p>Login using your Buildfly Account.</p>
                </div>
                <form onSubmit={onSubmitHandler}>
                    <input type="text" id="email" onChange={(e)=>inputChangeHandler(setEmail, e)} placeholder="Email Address" className="form-group"/>
                    <input type="password" id="password" onChange={(e)=>inputChangeHandler(setPassword, e)} placeholder="password" className="form-group"/>
                    <input type="submit" id="btn-submit" className="form-group submit"/>
                    <span style={showError ? {} : { display: 'none' }} className="error" id="err-msg">{errorMsg}</span>
                </form>
                <div className="seperator">
                    {/* <span>OR</span> */}
                    <hr/>
                </div>
                <div className="bottom">
                    {/* <a className="tile">
                        <img src={require('../../assets/images/linkedin.png')}></img>
                        <span>Continue with linkedin</span>
                    </a> */}
                    {/* <a className="tile">
                        <img src={require('../../assets/images/microsoft.png')}></img>
                        <span>Continue with Microsoft Account</span>
                    </a>
                    <a className="tile">
                        <img src={require('../../assets/images/github.png')}></img>
                        <span>Continue with Github</span>
                    </a>
                    <a className="tile">
                        <img src={require('../../assets/images/linkedin.png')}></img>
                        <span>Continue with Google</span>
                    </a> */}
                </div>
            </div>
        </div>
    )
}

<script>
    
</script>

export default Login