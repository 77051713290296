import React, { useEffect, useState } from "react"
import $ from 'jquery';


const CementSidingTable = () =>  {

    var sidingPrice = 0;
    let laborPrice = [];
    let taxRates = [];
    let overhead = [];

    let targetSalesPrice = 0;
    let lowTargetSalesPrice = 0;

    var sidingTIWithFanfold = 0;
    var sidingTIWithOutFanfold = 0;
    var sidingGableVent = 0;
    var sidingWindowWrapSmall = 0;
    var sidingWindowWrapMedium = 0;
    var sidingWindowWrapLarge = 0;
    var sidingGarageDoorWrap = 0;
    var sidingDoorWrap = 0;
    var sidingSoffit12 = 0;
    var sidingSoffit24 = 0;
    var sidingFascia = 0;

    var roofing25SQDurationStormCount = 0;
    var roofing25SQDurationStormABC = 0;
    var roofing25SQDurationStormBeacon = 0;
    var roofing25SQDurationStormMidwest = 0;

    var roofing25SQDurationCount = 0;
    var roofing25SQDurationABC = 0;
    var roofing25SQDurationBeacon = 0;
    var roofing25SQDurationMidwest = 0;

    var avgOakridgeCostCount = 0;
    var avgOakridgeCostABC = 0;
    var avgOakridgeCostBeacon = 0;
    var avgOakridgeCostMidwest = 0;

    var avgOakridgeCost = 0;
    var avgDurationCost = 0;
    var avgDStormCost = 0;
    var avgDurationHDZCost = 0;
    var avgPeelStickCapCost = 0;
    var avgPeelStickBaseCost = 0;

    var avgSidingCostSQ = 0;
    var avgSidingCostFFSQ = 0;
    var avgSidingTrimCoil0192450 = 0;
    var avgSidingT412SolidVSE = 0;
    var avgSidingWindowWrap = 0;

    var taxesAddedTSP = 0;
    var taxAvgOakridgeCost = 0;
    var taxAvgDurationCost = 0;
    var taxAvgDStormCost = 0;
    var taxAvgSidingCost = 0;
    var taxAvgSidingFFCost = 0;


    function resetValues(){        
        sidingPrice = 0;
        
        sidingTIWithFanfold = 0;
        sidingTIWithOutFanfold = 0;
        sidingGableVent = 0;
        sidingWindowWrapSmall = 0;
        sidingWindowWrapMedium = 0;
        sidingWindowWrapLarge = 0;
        sidingGarageDoorWrap = 0;
        sidingDoorWrap = 0;
        sidingSoffit12 = 0;
        sidingSoffit24 = 0;
        sidingFascia = 0;

        roofing25SQDurationStormCount = 0;
        roofing25SQDurationStormABC = 0;
        roofing25SQDurationStormBeacon = 0;
        roofing25SQDurationStormMidwest = 0;

        roofing25SQDurationCount = 0;
        roofing25SQDurationABC = 0;
        roofing25SQDurationBeacon = 0;
        roofing25SQDurationMidwest = 0;

        avgOakridgeCostCount = 0;
        avgOakridgeCostABC = 0;
        avgOakridgeCostBeacon = 0;
        avgOakridgeCostMidwest = 0;

        avgOakridgeCost = 0;
        avgDurationCost = 0;
        avgDStormCost = 0;
        avgDurationHDZCost = 0;
        avgPeelStickCapCost = 0;
        avgPeelStickBaseCost = 0;

        avgSidingCostSQ = 0;
        avgSidingCostFFSQ = 0;
        avgSidingTrimCoil0192450 = 0;
        avgSidingT412SolidVSE = 0;
        avgSidingWindowWrap = 0;

        taxesAddedTSP = 0;
        taxAvgOakridgeCost = 0;
        taxAvgDurationCost = 0;
        taxAvgDStormCost = 0;
        taxAvgSidingCost = 0;
        taxAvgSidingFFCost = 0;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function computeRoofingPrice(labor_type_id, location_id, name, val){
        if(name == 'Tear-Off SQ'){		
            sidingTIWithFanfold = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingTIWithFanfold += laborPrice['13' + '-' + location_id + '-' + 'Install SQ'] * val;
            sidingTIWithFanfold += laborPrice['14' + '-' + location_id + '-' + 'Fanfold'] * val;
            sidingTIWithFanfold += avgSidingCostSQ * val;
            sidingPrice += sidingTIWithFanfold;
        }else if(name == 'Install SQ'){
            sidingTIWithOutFanfold = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingTIWithOutFanfold += laborPrice['12' + '-' + location_id + '-' + 'Tear-Off SQ'] * val;
            sidingTIWithOutFanfold += avgSidingCostFFSQ * val;
            sidingPrice += sidingTIWithOutFanfold;
        }else if(name == 'Gable Vent'){
            sidingGableVent = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingGableVent;
        }else if(name == 'S Window Wrap'){
            sidingWindowWrapSmall = (avgSidingWindowWrap/10) * val;
            sidingWindowWrapSmall += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapSmall;
        }else if(name == 'M Window Wrap'){
            sidingWindowWrapMedium = (avgSidingWindowWrap/8) * val;
            sidingWindowWrapMedium += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapMedium;
        }else if(name == 'L Window Wrap'){
            sidingWindowWrapLarge = (avgSidingWindowWrap/6) * val;
            sidingWindowWrapLarge += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapLarge;
        }else if(name == 'Car Door Wrap'){
            sidingGarageDoorWrap = (avgSidingWindowWrap/4) * val;
            sidingGarageDoorWrap += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingGarageDoorWrap;
        }else if(name == 'Door Wrap'){
            sidingDoorWrap = (avgSidingWindowWrap/5) * val;
            sidingDoorWrap += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingDoorWrap;
        }else if(name == 'Soffit'){
            sidingSoffit12 = (avgSidingTrimCoil0192450/50) * val;
            sidingSoffit12 += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingSoffit12 += laborPrice['17' + '-' + location_id + '-' + 'Tear-Off Sof/Fas'] * val;
            sidingPrice += sidingSoffit12;
        }else if(name == 'Tear-Off Sof/Fas'){
            sidingSoffit24 = (avgSidingTrimCoil0192450/50) * val;
            sidingSoffit24 += 0.49 * val;
            sidingSoffit24 += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingSoffit24 += laborPrice['15' + '-' + location_id + '-' + 'Soffit'] * val;
            sidingPrice += sidingSoffit24;
        }else if(name == 'Fascia'){
            sidingFascia = (avgSidingT412SolidVSE/12) * val;
            sidingFascia += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingFascia += laborPrice['17' + '-' + location_id + '-' + 'Tear-Off Sof/Fas'] * val;
            sidingPrice += sidingFascia;
        }
    }


    function RoofEstimatePricing25SQ(){
        avgOakridgeCostCount = 0;
        roofing25SQDurationCount = 0;
        roofing25SQDurationStormCount = 0;
        
        const items = JSON.parse(localStorage.getItem('_averageCostRoofing'));
        for (const item of items) {
            if(item.name == 'ABC 25SQ Duration Storm ABC') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration Storm Beacon') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration Storm Midwest') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormMidwest = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ ABC') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ Beacon') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ Midwest') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationMidwest = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge ABC') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge Beacon') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge Midwest') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostMidwest = item.avg_cost;
            }else if(item.name == 'Peel & Stick Cap') {
                avgPeelStickCapCost = item.avg_cost;
            }else if(item.name == 'Peel & Stick Base') {
                avgPeelStickBaseCost = item.avg_cost;
            }else if(item.name == 'avg siding cost sq') {
                avgSidingCostSQ = item.avg_cost;
            }else if(item.name == 'avg siding ff sq') {
                avgSidingCostFFSQ = item.avg_cost;
            }else if(item.name == 'siding window wrap') {
                avgSidingWindowWrap = item.avg_cost;
            }else if(item.name == 'T4 12" Solid/Vented Soffit Economy') {
                avgSidingT412SolidVSE = item.avg_cost;
            }else if(item.name == '.019 24X50 Trim Coil') {
                avgSidingTrimCoil0192450 = item.avg_cost;
            }
        }

        avgDStormCost = ((roofing25SQDurationStormABC + roofing25SQDurationStormBeacon + roofing25SQDurationStormMidwest) / roofing25SQDurationStormCount) /25;
        avgDurationHDZCost = ((roofing25SQDurationABC + roofing25SQDurationBeacon + roofing25SQDurationMidwest) / roofing25SQDurationCount) /25;
        avgOakridgeCost = ((avgOakridgeCostABC + avgOakridgeCostBeacon + avgOakridgeCostMidwest) / avgOakridgeCostCount) /25;
    }


    function ComputeCost(location_id, labor_type_id, val, name, trade_type) {
        var computationDiscount = $('#discountPercentage').val()
        if(computationDiscount == "" || computationDiscount < 0){ computationDiscount = 9 }
        computationDiscount = (100 - computationDiscount) / 100;

        const localData = JSON.parse(localStorage.getItem('_laborPrice'));
        for (const item of localData) {
            if(item.locationid == location_id && item.labortypeid == labor_type_id){
                computeRoofingPrice(labor_type_id, location_id, name, val);

                if(sidingTIWithFanfold > 0) { $('.sidingTIWithFanfold').html('$' + numberWithCommas(Number(sidingTIWithFanfold * computationDiscount * 2.2).toFixed(2))); $('.sidingTIWithFanfold_org').html('$' + numberWithCommas(Number(sidingTIWithFanfold).toFixed(2))); } else { $('.sidingTIWithFanfold').html(''); $('.sidingTIWithFanfold_org').html(''); }
                if(sidingTIWithOutFanfold > 0) { $('.sidingTIWithOutFanfold').html('$' + numberWithCommas(Number(sidingTIWithOutFanfold * computationDiscount * 2.2).toFixed(2))); $('.sidingTIWithOutFanfold_org').html('$' + numberWithCommas(Number(sidingTIWithOutFanfold).toFixed(2))); } else { $('.sidingTIWithOutFanfold').html(''); $('.sidingTIWithOutFanfold_org').html(''); }
                if(sidingGableVent > 0) { $('.sidingGableVent').html('$' + numberWithCommas(Number(sidingGableVent * computationDiscount * 2.2).toFixed(2))); $('.sidingGableVent_org').html('$' + numberWithCommas(Number(sidingGableVent).toFixed(2))); } else { $('.sidingGableVent').html(''); $('.sidingGableVent_org').html(''); }
                if(sidingWindowWrapSmall > 0) { $('.sidingWindowWrapSmall').html('$' + numberWithCommas(Number(sidingWindowWrapSmall * computationDiscount * 2.2).toFixed(2))); $('.sidingWindowWrapSmall_org').html('$' + numberWithCommas(Number(sidingWindowWrapSmall).toFixed(2))); } else { $('.sidingWindowWrapSmall').html(''); $('.sidingWindowWrapSmall_org').html(''); }
                if(sidingWindowWrapMedium > 0) { $('.sidingWindowWrapMedium').html('$' + numberWithCommas(Number(sidingWindowWrapMedium * computationDiscount * 2.2).toFixed(2))); $('.sidingWindowWrapMedium_org').html('$' + numberWithCommas(Number(sidingWindowWrapMedium).toFixed(2))); } else { $('.sidingWindowWrapMedium').html(''); $('.sidingWindowWrapMedium_org').html(''); }
                if(sidingWindowWrapLarge > 0) { $('.sidingWindowWrapLarge').html('$' + numberWithCommas(Number(sidingWindowWrapLarge * computationDiscount * 2.2).toFixed(2))); $('.sidingWindowWrapLarge_org').html('$' + numberWithCommas(Number(sidingWindowWrapLarge).toFixed(2))); } else { $('.sidingWindowWrapLarge').html(''); $('.sidingWindowWrapLarge_org').html(''); }
                if(sidingGarageDoorWrap > 0) { $('.sidingGarageDoorWrap').html('$' + numberWithCommas(Number(sidingGarageDoorWrap * computationDiscount * 2.2).toFixed(2))); $('.sidingGarageDoorWrap_org').html('$' + numberWithCommas(Number(sidingGarageDoorWrap).toFixed(2))); } else { $('.sidingGarageDoorWrap').html(''); $('.sidingGarageDoorWrap_org').html(''); }
                if(sidingDoorWrap > 0) { $('.sidingDoorWrap').html('$' + numberWithCommas(Number(sidingDoorWrap * computationDiscount * 2.2).toFixed(2))); $('.sidingDoorWrap_org').html('$' + numberWithCommas(Number(sidingDoorWrap).toFixed(2))); } else { $('.sidingDoorWrap').html(''); $('.sidingDoorWrap_org').html(''); }
                if(sidingSoffit12 > 0) { $('.sidingSoffit12').html('$' + numberWithCommas(Number(sidingSoffit12 * computationDiscount * 2.2).toFixed(2))); $('.sidingSoffit12_org').html('$' + numberWithCommas(Number(sidingSoffit12).toFixed(2))); } else { $('.sidingSoffit12').html(''); $('.sidingSoffit12_org').html(''); }
                if(sidingSoffit24 > 0) { $('.sidingSoffit24').html('$' + numberWithCommas(Number(sidingSoffit24 * computationDiscount * 2.2).toFixed(2))); $('.sidingSoffit24_org').html('$' + numberWithCommas(Number(sidingSoffit24).toFixed(2))); } else { $('.sidingSoffit24').html(''); $('.sidingSoffit24_org').html(''); }
                if(sidingFascia > 0) { $('.sidingFascia').html('$' + numberWithCommas(Number(sidingFascia * computationDiscount * 2.2).toFixed(2))); $('.sidingFascia_org').html('$' + numberWithCommas(Number(sidingFascia).toFixed(2))); } else { $('.sidingFascia').html(''); $('.sidingFascia_org').html(''); }

                $('#price-siding').html('$' + numberWithCommas(Number(sidingPrice * computationDiscount * 2.2).toFixed(2)));

                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#price-siding-org').html('$' + numberWithCommas(Number(sidingPrice).toFixed(2)));
                }else{
                    $('#price-siding-org').html('');
                }
                localStorage.setItem('price-siding', JSON.stringify(sidingPrice));
            }
        }
    }

    function setLaborPrice(){
        const localData = JSON.parse(localStorage.getItem('_laborPrice'))
        for (const item of localData) {
            laborPrice[item.labortypeid + '-' + item.locationid + '-' + item.labor_type] = item.price
        }
    }


    function startComputation(){
        resetValues();
        RoofEstimatePricing25SQ()
        $('.compute-cost-siding').each(function(){
            var name = $(this).attr('data-name');
            var labor_type_id = $(this).attr('data-laborType');
            var trade_type = $(this).attr('data-tradeType');
            var location_id = $('#locations option:selected').val();
            var value = $(this).val();

            if(name == "No Of Salesman"){
                if(value > 0){
                    var foundThreshold = false;
                    const _salesCommission = JSON.parse(localStorage.getItem('_salesCommission'))
                    for (const item of _salesCommission) {
                        if(item.locationid == location_id && value <= item.threshold && !foundThreshold){
                            var salesCost = item.percent / 100;
                            salesCost *= $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val();
                            $('#salesCost').html('$' + numberWithCommas(Number(salesCost).toFixed(2)));
                            foundThreshold = true;
                        }
                    }
                }
            }else{
                if(value > 0) {
                    ComputeCost(location_id, labor_type_id, value, name, trade_type);
                }
            }
        });	
    }

    function clearValues(){
        $('.sidingTIWithFanfold').html('')
        $('.sidingTIWithOutFanfold').html('')
        $('.sidingGableVent').html('')
        $('.sidingWindowWrapSmall').html('')
        $('.sidingWindowWrapMedium').html('')
        $('.sidingWindowWrapLarge').html('')
        $('.sidingGarageDoorWrap').html('')
        $('.sidingDoorWrap').html('')
        $('.sidingSoffit12').html('')
        $('.sidingSoffit24').html('')
        $('.sidingFascia').html('')

        //$('#price-siding').html('$0.00')
        localStorage.setItem('price-siding', 0)

        $('#BSANRDOverhead').html('')
        $('#BSANRDCredit').html('')
        $('#absoluteLowest').html('')
        $('#finalDeliveryPickupFees').html('')
        $('#taxesAddedTSP').html('')

        localStorage.setItem('taxAvgOakridgeCost', 0);
        localStorage.setItem('taxAvgDurationCost', 0);
        localStorage.setItem('taxAvgDStormCost', 0);
        localStorage.setItem('taxAvgSidingCost', 0);
        localStorage.setItem('taxAvgSidingFFCost', 0);
    }


    function totalEstimatedComputation(){
        RoofEstimatePricing25SQ()
        var tmpVal = ""

        var location_id = $('#locations option:selected').val();

        var _taxRates = JSON.parse(localStorage.getItem('_taxRates'))
        for (const item of _taxRates) {
            taxRates[item.locationid] = item.tax;
        }
        var _overhead = JSON.parse(localStorage.getItem('_overhead'))
        for (const item of _overhead) {
            overhead[item.locationid] = item.percent;
        }
        
        
        var taxesAddedTSP = 0;
        if($('input[data-name="OC Oakridge Arch*"]').val() > 0){
            taxAvgOakridgeCost = (avgOakridgeCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="OC Oakridge Arch*"]').value))
        }
        if($('input[data-name="OC Duration Tru-Def*"]').val() > 0){
            taxAvgDurationCost = (avgDurationHDZCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="OC Duration Tru-Def*"]').value))
        }
        if($('input[data-name="Duration Storm"]').val() > 0){
            taxAvgDStormCost = (avgDStormCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Duration Storm"]').value))
        }
        if($('input[data-name="Tear-Off SQ"]').val() > 0){
            taxAvgSidingCost = (avgSidingCostSQ * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Tear-Off SQ"]').value))
        }
        if($('input[data-name="Install SQ"]').val() > 0){
            taxAvgSidingFFCost = (avgSidingCostFFSQ * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Install SQ"]').value))
        }

        taxesAddedTSP = 0;
        var taxesAddedTSPA = taxAvgOakridgeCost + taxAvgDurationCost + taxAvgDStormCost + taxAvgSidingCost + taxAvgSidingFFCost;
        
        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#taxesAddedTSP').html('');
                $('#taxesAddedTSPA').html('');
        }else{
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                $('#taxesAddedTSP').html('$' + numberWithCommas(Number(taxesAddedTSP).toFixed(2)));
                $('#taxesAddedTSPA').html('$' + numberWithCommas(Number(taxesAddedTSPA).toFixed(2)));
            }else{
                $('#taxesAddedTSP').html('');
                $('#taxesAddedTSPA').html('');
            }
        }
        localStorage.setItem('taxesAddedTSP', taxesAddedTSP);
        localStorage.setItem('taxesAddedTSPA', taxesAddedTSPA);

        var TotalEstimatedCost = Number(localStorage.getItem('price-roof'))
        TotalEstimatedCost = (TotalEstimatedCost + Number(localStorage.getItem('price-siding')))
        TotalEstimatedCost = (TotalEstimatedCost + Number(localStorage.getItem('price-gutter')))

        var finalDeliveryPickupFees = 0;
        var finalDeliveryPickupFeesA = 0;
        if(($('input[data-name="OC Oakridge Arch*"]').val() +
            $('input[data-name="OC Duration Tru-Def*"]').val() +
            $('input[data-name="Duration Storm"]').val() + 
            $('input[data-name="Low-Pitch Roofing"]').val()) > 0){
                finalDeliveryPickupFeesA += 75;
                finalDeliveryPickupFees = 0;
        }
        if(($('input[data-name="Tear-Off SQ"]').val() +
            $('input[data-name="Install SQ"]').val() +
            $('input[data-name="S Window Wrap"]').val() + 
            $('input[data-name="Door Wrap"]').val() +
            $('input[data-name="Soffit"]').val() +
            $('input[data-name="Tear-Off Sof/Fas"]').val() +
            $('input[data-name="Fascia"]').val()) > 0){
                finalDeliveryPickupFeesA += 75;				
                finalDeliveryPickupFees = 0;
        }
        
        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#finalDeliveryPickupFees').html('');
                $('#finalDeliveryPickupFeesA').html('');
        }else{
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                $('#finalDeliveryPickupFees').html('$' + numberWithCommas(Number(finalDeliveryPickupFees).toFixed(2)));
                $('#finalDeliveryPickupFeesA').html('$' + numberWithCommas(Number(finalDeliveryPickupFeesA).toFixed(2)));
            }else{
                $('#finalDeliveryPickupFees').html('');
                $('#finalDeliveryPickupFeesA').html('');
            }
        }
        localStorage.setItem('finalDeliveryPickupFees', finalDeliveryPickupFees);
        localStorage.setItem('finalDeliveryPickupFeesA', finalDeliveryPickupFeesA);
        TotalEstimatedCost = (TotalEstimatedCost + taxesAddedTSP)
        TotalEstimatedCost = (TotalEstimatedCost + finalDeliveryPickupFees)

        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#TotalEstimatedCost').html('');
        }else{        
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                    document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                    document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                        $('#TotalEstimatedCost').html('');
                    }else{
                        //$('#TotalEstimatedCost').html('$' + numberWithCommas(Number(TotalEstimatedCost).toFixed(2)));
                        $('#TotalEstimatedCost').html('$' + numberWithCommas((Number(TotalEstimatedCost) + Number(finalDeliveryPickupFeesA) + Number(taxesAddedTSPA)).toFixed(2)));
                    }
            }else{
                $('#TotalEstimatedCost').html('');
            }
        }
        localStorage.setItem('totalEstimatedCost', TotalEstimatedCost)


        
        targetSalesPrice = ((TotalEstimatedCost * 2) * 1.1)
        localStorage.setItem('targetSalesPrice2', targetSalesPrice);
        if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
            if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#targetSalesPrice').html('');
                }else{
                    $('#targetSalesPrice').html('$' + numberWithCommas(Number(targetSalesPrice).toFixed(2)));
                }
        }else{
            $('#targetSalesPrice').html('');
        }
        $('.targetSalesPrice').html('$' + numberWithCommas(Number(targetSalesPrice).toFixed(2)));
        
        var numberTEN = ((TotalEstimatedCost * 2) * 1.1) * 0.9;
        $('#numberTEN').html('$' + numberWithCommas(Number(numberTEN).toFixed(2)));
        
        
        var BSANRDCredit = $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val();
        $('#BSANRDCredit').html('$' + numberWithCommas(Number(BSANRDCredit).toFixed(2)));

        var currentRevenue = BSANRDCredit + Number($('input[data-name="Agreed Retail Amounts"]').val());
        $('#currentRevenue').html('$' + numberWithCommas(Number(currentRevenue).toFixed(2)));
        
        var BSANRDOverhead = BSANRDCredit;
        BSANRDOverhead *= overhead[location_id] / 100;
        BSANRDOverhead += TotalEstimatedCost;
        $('#BSANRDOverhead').html('$' + numberWithCommas(Number(BSANRDOverhead).toFixed(2)));
        
        var absoluteLowest = targetSalesPrice * 0.55
        $('#absoluteLowest').html('$' + numberWithCommas(Number(absoluteLowest).toFixed(2)));

        lowTargetSalesPrice = (TotalEstimatedCost -finalDeliveryPickupFees - taxesAddedTSP)* 1.6364
        localStorage.setItem('targetSalesPrice1', lowTargetSalesPrice);
        var lowTargetSalesPrice_step1 = lowTargetSalesPrice * 0.9
        if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
            if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#lowTargetSalesPrice').html('');
                }else{
                    $('#lowTargetSalesPrice').html('$' + numberWithCommas(Number(lowTargetSalesPrice_step1).toFixed(2)));
                }
        }else{
            $('#lowTargetSalesPrice').html('');
        }
        $('.lowTargetSalesPrice').html('$' + numberWithCommas(Number(lowTargetSalesPrice_step1).toFixed(2)));

        var additionalDollarRequired = "N/A"
        if((lowTargetSalesPrice_step1 - currentRevenue) > 0){
            additionalDollarRequired = (lowTargetSalesPrice_step1 - currentRevenue)
            $('#additionalDollarRequired').html('$' + numberWithCommas(Number(additionalDollarRequired).toFixed(2)));
        }else{
            $('#additionalDollarRequired').html(additionalDollarRequired);
        }
        if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#additionalDollarRequired').html('');
        }


        var overheadCost = currentRevenue * 0.22
        $('#overheadCost').html('$' + numberWithCommas(Number(overheadCost).toFixed(2)));

        var salesCommissionCost = currentRevenue * 0.12
        $('#salesCommissionCost').html('$' + numberWithCommas(Number(salesCommissionCost).toFixed(2)));

        var estimatedNetProfitAfterOverhead = currentRevenue - (overheadCost + salesCommissionCost) - TotalEstimatedCost
        if(estimatedNetProfitAfterOverhead < 0) { tmpVal = '$ (' + numberWithCommas(Number(estimatedNetProfitAfterOverhead).toFixed(2)) + ')'
        } else { tmpVal = '$' + numberWithCommas(Number(estimatedNetProfitAfterOverhead).toFixed(2)) }
        $('#estimatedNetProfitAfterOverhead').html(tmpVal);

        var netProfitAfterOverhead = estimatedNetProfitAfterOverhead / TotalEstimatedCost * 100
        $('#netProfitAfterOverhead').html(numberWithCommas(Number(netProfitAfterOverhead).toFixed(2)) + '%');

        
        var estimatedNetProfit = $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val() - BSANRDOverhead;
        if(estimatedNetProfit < 0) {
            $('#estimatedNetProfit').html('$(' + numberWithCommas(Number((estimatedNetProfit * -1)).toFixed(2)) + ')');
        }else{
            $('#estimatedNetProfit').html('$' + numberWithCommas(Number(estimatedNetProfit).toFixed(2)));
        }
        
        var awayFromTarget = 1 - (BSANRDCredit / targetSalesPrice);
        awayFromTarget = awayFromTarget * 100;
        $('#awayFromTarget').html(Number(awayFromTarget).toFixed(1) + '%');
        
        
        var additionalRequiredAmount = 0;
        if(awayFromTarget < 45){
            additionalRequiredAmount = "N/A";
            $('#estimatedNetProfit').html(additionalRequiredAmount);
        }else{
            additionalRequiredAmount = absoluteLowest - ($('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val());
            $('#additionalRequiredAmount').html('$' + numberWithCommas(Number(additionalRequiredAmount).toFixed(2)));
        }


        var targetSalesDiscount = (Number(localStorage.getItem('targetSalesPrice2')) - Number(lowTargetSalesPrice_step1))
        $('.targetSalesDiscount').html('$' + numberWithCommas(Number(targetSalesDiscount).toFixed(2)))
        localStorage.setItem('targetSalesDiscount', targetSalesDiscount);
        var val3 = (Number(localStorage.getItem('targetSalesPrice2')) - Number(lowTargetSalesPrice_step1)) / Number(localStorage.getItem('targetSalesPrice2')) * 100
        var commission = 0
        var median = (Number(localStorage.getItem('targetSalesPrice2')) + (Number(localStorage.getItem('targetSalesPrice1')) * 0.9)) / 2;
         var medianDiscount = (lowTargetSalesPrice_step1 - median) / median * 100;
         if(medianDiscount > 19.9 && medianDiscount <= 40.9) { commission = 11 / 100 } else
            if(medianDiscount > 9.9 && medianDiscount <= 19.9) { commission = 10 / 100 } else
            if(medianDiscount > -1.9 && medianDiscount <= 9.9) { commission = 9 / 100 } else
            if(medianDiscount > -9.9 && medianDiscount <= -1.9) { commission = 7 / 100 } else
            if(medianDiscount > -15.9 && medianDiscount <= -9.9) { commission = 5 / 100 } else
            if(medianDiscount > -16.9 && medianDiscount <= -15.9) { commission = 3 / 100 } else
            if(medianDiscount > -25.9 && medianDiscount <= -16.9) { commission = 2 / 100 } else
            { commission = 0 }
         /*
         if(val3 > -1 && val3 <= 5.9) { commission = 12 / 100 } else
         if(val3 > 5.9 && val3 <= 10.9) { commission = 9.5 / 100 } else
         if(val3 > 10.9 && val3 <= 15.9) { commission = 8.5 / 100 } else
         if(val3 > 15.9 && val3 <= 20.9) { commission = 7 / 100 } else
         if(val3 > 20.9 && val3 <= 30.9) { commission = 5 / 100 } else
         if(val3 > 30.9 && val3 <= 40.9) { commission = 2.5 / 100 } else { commission = 0 }
         */
        var targetSalesCommission = Number(lowTargetSalesPrice_step1) * commission
        localStorage.setItem('targetSalesCommissionPercentage', (commission * 100));
        localStorage.setItem('targetSalesCommission', targetSalesCommission);
        $('.targetSalesCommission').html('$' + numberWithCommas(Number(targetSalesCommission).toFixed(2)));


        /***** Computation of default Discount - Begin *****/
        var inputDiscount = $('#discountPercentage').val()
        
        if(inputDiscount == "" || inputDiscount < 0){
            inputDiscount = 9
        }
        $('#discountPercentage').val(inputDiscount).trigger("input")
        /***** Computation of default Discount - End *****/
    }



    $(function() {          
        $(document).on('input', '.compute-cost-siding', function(e){
            setLaborPrice()
            startComputation()
            totalEstimatedComputation()
        })

        
        $(document).on('change', '#locations', function(e){
            clearValues()
            setLaborPrice()
            startComputation()
            totalEstimatedComputation()
        })
        
    })


    return (
        <table className="table table-bordered table-striped table-hover PriceInput-Bx centerPC">
            <thead className="tableHead">
                <tr>
                    <th>Vinyl Siding</th>
                    <th></th>
                    <th className="wd-50">Quantity</th>
                    <th className="wd-220">Price</th>
                    <th className="wd-220 admin-content">Cost</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="leftAlign">Total Install w/ Fanfold</td>
                    <td>SQ</td>
                    <td><input data-laborType="12"  data-tradeType="1" data-name="Tear-Off SQ" type="text" data-total="sidingTIWithFanfold" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingTIWithFanfold rightAlign"></td>
                    <td className="sidingTIWithFanfold_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Total Install w/o Fanfold</td>
                    <td>SQ</td>
                    <td><input data-laborType="13"  data-tradeType="1" data-name="Install SQ" type="text" data-total="sidingTIWithOutFanfold" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingTIWithOutFanfold rightAlign"></td>
                    <td className="sidingTIWithOutFanfold_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Gable Vent</td>
                    <td>EA</td>
                    <td><input data-laborType="20"  data-tradeType="1" data-name="Gable Vent" type="text" data-total="sidingGableVent" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingGableVent rightAlign"></td>
                    <td className="sidingGableVent_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Window Wrap Small</td>
                    <td>EA</td>
                    <td><input data-laborType="21"  data-tradeType="1" data-name="S Window Wrap" type="text" data-total="sidingWindowWrapSmall" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingWindowWrapSmall rightAlign"></td>
                    <td className="sidingWindowWrapSmall_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Window Wrap Medium</td>
                    <td>EA</td>
                    <td><input data-laborType="22"  data-tradeType="1" data-name="M Window Wrap" type="text" data-total="sidingWindowWrapMedium" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingWindowWrapMedium rightAlign"></td>
                    <td className="sidingWindowWrapMedium_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Window Wrap Large</td>
                    <td>EA</td>
                    <td><input data-laborType="23"  data-tradeType="1" data-name="L Window Wrap" type="text" data-total="sidingWindowWrapLarge" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingWindowWrapLarge rightAlign"></td>
                    <td className="sidingWindowWrapLarge_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Garage Door Wrap</td>
                    <td>EA</td>
                    <td><input data-laborType="25"  data-tradeType="1" data-name="Car Door Wrap" type="text" data-total="sidingGarageDoorWrap" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingGarageDoorWrap rightAlign"></td>
                    <td className="sidingGarageDoorWrap_org rightAlign admin-content"></td>
                </tr>

                <tr>
                    <td className="leftAlign">Door Wrap</td>
                    <td>EA</td>
                    <td><input data-laborType="24"  data-tradeType="1" data-name="Door Wrap" type="text" data-total="sidingDoorWrap" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingDoorWrap rightAlign"></td>
                    <td className="sidingDoorWrap_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Soffit 12" Overhang</td>
                    <td>LF</td>
                    <td><input data-laborType="15"  data-tradeType="1" data-name="Soffit" type="text" data-total="sidingSoffit12" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingSoffit12 rightAlign"></td>
                    <td className="sidingSoffit12_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Soffit 24" Overhang</td>
                    <td>LF</td>
                    <td><input data-laborType="17"  data-tradeType="1" data-name="Tear-Off Sof/Fas" type="text" data-total="sidingSoffit24" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingSoffit24 rightAlign"></td>
                    <td className="sidingSoffit24_org rightAlign admin-content"></td>
                </tr>
                <tr>
                    <td className="leftAlign">Fascia</td>
                    <td>LF</td>
                    <td><input data-laborType="16"  data-tradeType="1" data-name="Fascia" type="text" data-total="sidingFascia" className="compute-cost-siding quantity form-control input-number" /></td>
                    <td className="sidingFascia rightAlign"></td>
                    <td className="sidingFascia_org rightAlign admin-content"></td>
                </tr>
            </tbody>
        </table>
        )
}

export default CementSidingTable