import React, { useState, useEffect } from "react";
import Header from '../essentials/Header.js'
import SideNav from '../essentials/SideNav.js'
import Footer from '../essentials/Footer.js'
import MainCont from '../essentials/MainCont.js'
import SelectCalOpt from '../partials/SelectCalOpt'
import $ from 'jquery';


const Home = () =>  {
    const url = 'https://apro-cal.plego.cloud'
    const [locations, setLocations] = useState([{key: 'Loading...', value: 0}])    

    var baseURL = "https://apro-cal.plego.cloud";

    /********************************
     * Computation - Roofing - Begin
     ********************************/
    var roofPrice = 0;
    let laborPrice = [];
    let taxRates = [];
    let overhead = [];

    var roofOCOakridgePrice = 0;
    var roofDurationHDZPrice = 0;
    var roofDurationStormPrice = 0;
    var roofLowPitchRoofingPrice = 0;
    var roofPitch812 = 0;
    var roofPitch1112 = 0;
    var roofLayers = 0;
    var roofShake = 0;
    var roof2Story = 0;
    var roofChimneyFlashing = 0;
    var roofSkylightFlashing = 0;
    var roofPlywood = 0;

    var roofing25SQDurationStormCount = 0;
    var roofing25SQDurationStormABC = 0;
    var roofing25SQDurationStormBeacon = 0;
    var roofing25SQDurationStormMidwest = 0;

    var roofing25SQDurationCount = 0;
    var roofing25SQDurationABC = 0;
    var roofing25SQDurationBeacon = 0;
    var roofing25SQDurationMidwest = 0;

    var avgOakridgeCostCount = 0;
    var avgOakridgeCostABC = 0;
    var avgOakridgeCostBeacon = 0;
    var avgOakridgeCostMidwest = 0;

    var avgOakridgeCost = 0;
    var avgDurationCost = 0;
    var avgDStormCost = 0;
    var avgDurationHDZCost = 0;
    var avgPeelStickCapCost = 0;
    var avgPeelStickBaseCost = 0;

    var avgSidingCostSQ = 0;
    var avgSidingCostFFSQ = 0;
    var avgSidingWindowWrap = 0;
    var avgSidingT412SolidVSE = 0;
    var avgSidingTrimCoil0192450 = 0;

    var taxesAddedTSP = 0;
    var taxAvgOakridgeCost = 0;
    var taxAvgDurationCost = 0;
    var taxAvgDStormCost = 0;
    var taxAvgSidingCost = 0;
    var taxAvgSidingFFCost = 0;



    var gutterPrice = 0;
    var guttersInstall = 0;
    var guttersOSGutters = 0;
    var guttersDownspouts = 0;
    var guttersOSDownspouts = 0;
    var guttersApron = 0;
    var guttersScreens = 0;
    var guttersGuards = 0;

    var sidingPrice = 0;
    var sidingTIWithFanfold = 0;
    var sidingTIWithOutFanfold = 0;
    var sidingGableVent = 0;
    var sidingWindowWrapSmall = 0;
    var sidingWindowWrapMedium = 0;
    var sidingWindowWrapLarge = 0;
    var sidingGarageDoorWrap = 0;
    var sidingDoorWrap = 0;
    var sidingSoffit12 = 0;
    var sidingSoffit24 = 0;
    var sidingFascia = 0;

    var loadingHardie = false;
    var loadingAVG = false;
 
 
    function resetValues(){        
        roofPrice = 0;
        gutterPrice = 0;
        sidingPrice = 0;

        guttersInstall = 0;
        guttersOSGutters = 0;
        guttersDownspouts = 0;
        guttersOSDownspouts = 0;
        guttersApron = 0;
        guttersScreens = 0;
        guttersGuards = 0;
        
        sidingTIWithFanfold = 0;
        sidingTIWithOutFanfold = 0;
        sidingGableVent = 0;
        sidingWindowWrapSmall = 0;
        sidingWindowWrapMedium = 0;
        sidingWindowWrapLarge = 0;
        sidingGarageDoorWrap = 0;
        sidingDoorWrap = 0;
        sidingSoffit12 = 0;
        sidingSoffit24 = 0;
        sidingFascia = 0;
         
        roofOCOakridgePrice = 0;
        roofDurationHDZPrice = 0;
        roofDurationStormPrice = 0;
        roofLowPitchRoofingPrice = 0;
        roofPitch812 = 0;
        roofPitch1112 = 0;
        roofLayers = 0;
        roofShake = 0;
        roof2Story = 0;
        roofChimneyFlashing = 0;
        roofSkylightFlashing = 0;
        roofPlywood = 0;

        roofing25SQDurationStormCount = 0;
        roofing25SQDurationStormABC = 0;
        roofing25SQDurationStormBeacon = 0;
        roofing25SQDurationStormMidwest = 0;

        roofing25SQDurationCount = 0;
        roofing25SQDurationABC = 0;
        roofing25SQDurationBeacon = 0;
        roofing25SQDurationMidwest = 0;

        avgOakridgeCostCount = 0;
        avgOakridgeCostABC = 0;
        avgOakridgeCostBeacon = 0;
        avgOakridgeCostMidwest = 0;

        avgOakridgeCost = 0;
        avgDurationCost = 0;
        avgDStormCost = 0;
        avgDurationHDZCost = 0;
        avgPeelStickCapCost = 0;
        avgPeelStickBaseCost = 0;

        avgSidingCostSQ = 0;
        avgSidingCostFFSQ = 0;
        avgSidingWindowWrap = 0;
        avgSidingT412SolidVSE = 0;
        avgSidingTrimCoil0192450 = 0;

        taxesAddedTSP = 0;
        taxAvgOakridgeCost = 0;
        taxAvgDurationCost = 0;
        taxAvgDStormCost = 0;
        taxAvgSidingCost = 0;
        taxAvgSidingFFCost = 0;

        $('.hardie1').html('');
        $('.hardie2').html('');
        $('.hardie3').html('');
        $('.hardie4').html('');
        $('.hardie5').html('');
        $('.hardie6').html('');
        $('.hardie7').html('');
        $('.hardie8').html('');
        $('.hardie9').html('');
        $('.hardie10').html('');
        $('.hardie11').html('');
        $('.hardie12').html('');
        $('.accessories1').html('');
        $('.accessories2').html('');
        $('.accessories3').html('');
        $('.accessories4').html('');
        $('.accessories5').html('');
        $('.accessories6').html('');
        $('.accessories7').html('');
        $('.accessories8').html('');
        $('.accessories9').html('');
        $('.accessories10').html('');
        $('.accessories11').html('');
        $('.accessories12').html('');
        $('.accessories13').html('');
    }
 
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
 
    function computeRoofingPrice(labor_type_id, location_id, name, val){
        if(name == 'OC Oakridge Arch*'){
            roofOCOakridgePrice = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofOCOakridgePrice += avgOakridgeCost * val;
            roofPrice += roofOCOakridgePrice;
        }else if(name == 'OC Duration Tru-Def*'){
            roofDurationHDZPrice = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofDurationHDZPrice += avgDurationHDZCost * val;
            roofPrice += roofDurationHDZPrice;
        }else if(name == 'Duration Storm'){
            roofDurationStormPrice = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofDurationStormPrice += avgDStormCost * val;
            roofPrice += roofDurationStormPrice;
        }else if(name == 'Low-Pitch Roofing'){
            roofLowPitchRoofingPrice = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofLowPitchRoofingPrice += avgPeelStickCapCost * val;
            roofLowPitchRoofingPrice += avgPeelStickBaseCost * val;
            roofPrice += roofLowPitchRoofingPrice;
        }else if(name == 'Pitch 8/12+'){
            roofPitch812 = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofPitch812;
        }else if(name == 'Pitch 11/12+'){
            roofPitch1112 = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofPitch1112;
        }else if(name == 'Layers'){
            roofLayers = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofLayers;
        }else if(name == 'Shake'){
            roofShake = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofShake;
        }else if(name == '2-Story'){
            roof2Story = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roof2Story;
        }else if(name == 'Chimney Flashing'){
            roofChimneyFlashing = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofChimneyFlashing;
        }else if(name == 'Skylight Flashing'){
            roofSkylightFlashing = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofSkylightFlashing;
        }else if(name == 'Plywood'){		
            roofPlywood = 48 * val;
            roofPlywood += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPlywood = roofPlywood * 3;
            roofPrice += roofPlywood;
        }


        else if(name == 'Tear-Off SQ'){		
            sidingTIWithFanfold = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingTIWithFanfold += laborPrice['13' + '-' + location_id + '-' + 'Install SQ'] * val;
            sidingTIWithFanfold += laborPrice['14' + '-' + location_id + '-' + 'Fanfold'] * val;
            sidingTIWithFanfold += avgSidingCostSQ * val;
            sidingPrice += sidingTIWithFanfold;
        }else if(name == 'Install SQ'){
            sidingTIWithOutFanfold = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingTIWithOutFanfold += laborPrice['12' + '-' + location_id + '-' + 'Tear-Off SQ'] * val;
            sidingTIWithOutFanfold += avgSidingCostFFSQ * val;
            sidingPrice += sidingTIWithOutFanfold;
        }else if(name == 'Gable Vent'){
            sidingGableVent = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingGableVent;
        }else if(name == 'S Window Wrap'){
            sidingWindowWrapSmall = (avgSidingWindowWrap/10) * val;
            sidingWindowWrapSmall += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapSmall;
        }else if(name == 'M Window Wrap'){
            sidingWindowWrapMedium = (avgSidingWindowWrap/8) * val;
            sidingWindowWrapMedium += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapMedium;
        }else if(name == 'L Window Wrap'){
            sidingWindowWrapLarge = (avgSidingWindowWrap/6) * val;
            sidingWindowWrapLarge += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapLarge;
        }else if(name == 'Car Door Wrap'){
            sidingGarageDoorWrap = (avgSidingWindowWrap/4) * val;
            sidingGarageDoorWrap += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingGarageDoorWrap;
        }else if(name == 'Door Wrap'){
            sidingDoorWrap = (avgSidingWindowWrap/5) * val;
            sidingDoorWrap += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingDoorWrap;
        }else if(name == 'Soffit'){
            sidingSoffit12 = (avgSidingTrimCoil0192450/50) * val;
            sidingSoffit12 += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingSoffit12 += laborPrice['17' + '-' + location_id + '-' + 'Tear-Off Sof/Fas'] * val;
            sidingPrice += sidingSoffit12;
        }else if(name == 'Tear-Off Sof/Fas'){
            sidingSoffit24 = (avgSidingTrimCoil0192450/50) * val;
            sidingSoffit24 += 0.49 * val;
            sidingSoffit24 += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingSoffit24 += laborPrice['15' + '-' + location_id + '-' + 'Soffit'] * val;
            sidingPrice += sidingSoffit24;
        }else if(name == 'Fascia'){
            sidingFascia = (avgSidingT412SolidVSE/12) * val;
            sidingFascia += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingFascia += laborPrice['17' + '-' + location_id + '-' + 'Tear-Off Sof/Fas'] * val;
            sidingPrice += sidingFascia;
        }

        else if(name == 'Gutters'){
            guttersInstall = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            guttersInstall += laborPrice['29' + '-' + location_id + '-' + 'Gutter Removal'] * val;
            gutterPrice += guttersInstall;
        }else if(name == 'OS Gutters'){
            guttersOSGutters = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            guttersOSGutters += laborPrice['29' + '-' + location_id + '-' + 'Gutter Removal'] * val;
            gutterPrice += guttersOSGutters;
        }else if(name == 'Downspouts'){
            guttersDownspouts = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            gutterPrice += guttersDownspouts;
        }else if(name == 'OS Downspouts'){
            guttersOSDownspouts = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            gutterPrice += guttersOSDownspouts;
        }else if(name == 'Gutter Apron'){
            guttersApron = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            gutterPrice += guttersApron;
        }else if(name == 'Gutter Screens'){
            guttersScreens = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            gutterPrice += guttersScreens;
        }else if(name == 'Gutter Guards'){
            guttersGuards = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            gutterPrice += guttersGuards;
        }
    }
 
 
    function RoofEstimatePricing25SQInner(){
        avgOakridgeCostCount = 0;
        roofing25SQDurationCount = 0;
        roofing25SQDurationStormCount = 0;
        
        const items = JSON.parse(localStorage.getItem('_averageCostRoofing'));
        for (const item of items) {
            if(item.name == 'ABC 25SQ Duration Storm ABC') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration Storm Beacon') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration Storm Midwest') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormMidwest = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ ABC') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ Beacon') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ Midwest') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationMidwest = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge ABC') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge Beacon') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge Midwest') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostMidwest = item.avg_cost;
            }else if(item.name == 'Peel & Stick Cap') {
                avgPeelStickCapCost = item.avg_cost;
            }else if(item.name == 'Peel & Stick Base') {
                avgPeelStickBaseCost = item.avg_cost;
            }else if(item.name == 'avg siding cost sq') {
                avgSidingCostSQ = item.avg_cost;
            }else if(item.name == 'avg siding ff sq') {
                avgSidingCostFFSQ = item.avg_cost;
            }else if(item.name == 'siding window wrap') {
                avgSidingWindowWrap = item.avg_cost;
            }else if(item.name == 'T4 12" Solid/Vented Soffit Economy') {
                avgSidingT412SolidVSE = item.avg_cost;
            }else if(item.name == '.019 24X50 Trim Coil') {
                avgSidingTrimCoil0192450 = item.avg_cost;
            }
        }
        
        avgDStormCost = ((roofing25SQDurationStormABC + roofing25SQDurationStormBeacon + roofing25SQDurationStormMidwest) / roofing25SQDurationStormCount) /25;
        avgDurationHDZCost = ((roofing25SQDurationABC + roofing25SQDurationBeacon + roofing25SQDurationMidwest) / roofing25SQDurationCount) /25;
        avgOakridgeCost = ((avgOakridgeCostABC + avgOakridgeCostBeacon + avgOakridgeCostMidwest) / avgOakridgeCostCount) /25;
    }
 
    function setLaborPrice(){
        const localData = JSON.parse(localStorage.getItem('_laborPrice'))
        for (const item of localData) {
            laborPrice[item.labortypeid + '-' + item.locationid + '-' + item.labor_type] = item.price
        }
    }



    function computeCementSiding(name, trade_type, location_id, supplier_id, value){
        var computationDiscount = localStorage.getItem('discountPercentage')
        if(computationDiscount == "" || computationDiscount < 0){ computationDiscount = 9 }
        computationDiscount = (100 - computationDiscount) / 100;

        const items = JSON.parse(localStorage.getItem('_cementSiding'));
        var laborPriceSQ = 250;
        var trimPriceLF = 2;
        for (const item of items) {
            if(item.name == '5.25 Primed CM/SM' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie1 = item.price * value * item.quantity
                hardie1 = hardie1 + laborPriceSQ
                hardie1 = hardie1 * 2 * 1.1
                $('.hardie1-pieces').html(item.quantity)
                if(hardie1 > 0) { $('.hardie1').html('$' + numberWithCommas(Number(hardie1 * computationDiscount * 2.2).toFixed(2))); $('.hardie1_org').html('$' + numberWithCommas(Number(hardie1).toFixed(2))); } else { $('.hardie1').html('');  $('.hardie1_org').html(''); }
            }else if(item.name == '6.25 Primed CM/SM' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie2 = item.price * value * item.quantity
                hardie2 = hardie2 + laborPriceSQ
                hardie2 = hardie2 * 2 * 1.1
                $('.hardie2-pieces').html(item.quantity)
                if(hardie2 > 0) { $('.hardie2').html('$' + numberWithCommas(Number(hardie2 * computationDiscount * 2.2).toFixed(2))); $('.hardie2_org').html('$' + numberWithCommas(Number(hardie2).toFixed(2))); } else { $('.hardie2').html(''); $('.hardie2_org').html(''); }
            }else if(item.name == '7.25 Primed CM/SM' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie3 = item.price * value * item.quantity
                hardie3 = hardie3 + laborPriceSQ
                hardie3 = hardie3 * 2 * 1.1
                $('.hardie3-pieces').html(item.quantity)
                if(hardie3 > 0) { $('.hardie3').html('$' + numberWithCommas(Number(hardie3 * computationDiscount * 2.2).toFixed(2))); $('.hardie3_org').html('$' + numberWithCommas(Number(hardie3).toFixed(2))); } else { $('.hardie3').html(''); $('.hardie3_org').html(''); }
            }else if(item.name == '8.25 Primed CM/SM' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie4 = item.price * value * item.quantity
                hardie4 = hardie4 + laborPriceSQ
                hardie4 = hardie4 * 2 * 1.1
                $('.hardie4-pieces').html(item.quantity)
                if(hardie4 > 0) { $('.hardie4').html('$' + numberWithCommas(Number(hardie4 * computationDiscount * 2.2).toFixed(2))); $('.hardie4_org').html('$' + numberWithCommas(Number(hardie4).toFixed(2))); } else { $('.hardie4').html(''); $('.hardie4_org').html(''); }
            }else if(item.name == '4/4 2.5 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie5 = item.price * value / item.quantity
                hardie5 = hardie5 + trimPriceLF
                hardie5 = hardie5 * 2 * 1.1
                $('.hardie5-pieces').html(item.quantity / 12)
                if(hardie5 > 0) { $('.hardie5').html('$' + numberWithCommas(Number(hardie5 * computationDiscount * 2.2).toFixed(2))); $('.hardie5_org').html('$' + numberWithCommas(Number(hardie5).toFixed(2))); } else { $('.hardie5').html(''); $('.hardie5_org').html(''); }
            }else if(item.name == '4/4 5.5 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie6 = item.price * value / item.quantity
                hardie6 = hardie6 + trimPriceLF
                hardie6 = hardie6 * 2 * 1.1
                $('.hardie6-pieces').html(item.quantity / 12)
                if(hardie6 > 0) { $('.hardie6').html('$' + numberWithCommas(Number(hardie6 * computationDiscount * 2.2).toFixed(2))); $('.hardie6_org').html('$' + numberWithCommas(Number(hardie6).toFixed(2))); } else { $('.hardie6').html(''); $('.hardie6_org').html(''); }
            }else if(item.name == '4/4 7.25 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie7 = item.price * value / item.quantity
                hardie7 = hardie7 + trimPriceLF
                hardie7 = hardie7 * 2 * 1.1
                $('.hardie7-pieces').html(item.quantity / 12)
                if(hardie7 > 0) { $('.hardie7').html('$' + numberWithCommas(Number(hardie7 * computationDiscount * 2.2).toFixed(2))); $('.hardie7_org').html('$' + numberWithCommas(Number(hardie7).toFixed(2))); } else { $('.hardie7').html(''); $('.hardie7_org').html(''); }
            }else if(item.name == '4/4 11.25 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie8 = item.price * value / item.quantity
                hardie8 = hardie8 + trimPriceLF
                hardie8 = hardie8 * 2 * 1.1
                $('.hardie8-pieces').html(item.quantity / 12)
                if(hardie8 > 0) { $('.hardie8').html('$' + numberWithCommas(Number(hardie8 * computationDiscount * 2.2).toFixed(2))); $('.hardie8_org').html('$' + numberWithCommas(Number(hardie8).toFixed(2))); } else { $('.hardie8').html(''); $('.hardie8_org').html(''); }
            }else if(item.name == '5/4 3.5 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie9 = item.price * value / item.quantity
                hardie9 = hardie9 + trimPriceLF
                hardie9 = hardie9 * 2 * 1.1
                $('.hardie9-pieces').html(item.quantity / 12)
                if(hardie9 > 0) { $('.hardie9').html('$' + numberWithCommas(Number(hardie9 * computationDiscount * 2.2).toFixed(2))); $('.hardie9_org').html('$' + numberWithCommas(Number(hardie9).toFixed(2))); } else { $('.hardie9').html(''); $('.hardie9_org').html(''); }
            }else if(item.name == '5/4 5.5 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie10 = item.price * value / item.quantity
                hardie10 = hardie10 + trimPriceLF
                hardie10 = hardie10 * 2 * 1.1
                $('.hardie10-pieces').html(item.quantity / 12)
                if(hardie10 > 0) { $('.hardie10').html('$' + numberWithCommas(Number(hardie10 * computationDiscount * 2.2).toFixed(2))); $('.hardie10_org').html('$' + numberWithCommas(Number(hardie10).toFixed(2))); } else { $('.hardie10').html(''); $('.hardie10_org').html(''); }
            }else if(item.name == '5/4 7.25 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie11 = item.price * value / item.quantity
                hardie11 = hardie11 + trimPriceLF
                hardie11 = hardie11 * 2 * 1.1
                $('.hardie11-pieces').html(item.quantity / 12)
                if(hardie11 > 0) { $('.hardie11').html('$' + numberWithCommas(Number(hardie11 * computationDiscount * 2.2).toFixed(2))); $('.hardie11_org').html('$' + numberWithCommas(Number(hardie11).toFixed(2))); } else { $('.hardie11').html(''); $('.hardie11_org').html(''); }
            }else if(item.name == '5/4 11.25 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie12 = item.price * value / item.quantity
                hardie12 = hardie12 + trimPriceLF
                hardie12 = hardie12 * 2 * 1.1
                $('.hardie12-pieces').html(item.quantity / 12)
                if(hardie12 > 0) { $('.hardie12').html('$' + numberWithCommas(Number(hardie12 * computationDiscount * 2.2).toFixed(2))); $('.hardie12_org').html('$' + numberWithCommas(Number(hardie12).toFixed(2))); } else { $('.hardie12').html(''); $('.hardie12_org').html(''); }
            }


            else if(item.name == 'Housewrap 3X165' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories1 = item.price * value;
                accessories1 = accessories1 * 2 * 1.1
                if(accessories1 > 0) { $('.accessories1').html('$' + numberWithCommas(Number(accessories1 * computationDiscount * 2.2).toFixed(2))); $('.accessories1_org').html('$' + numberWithCommas(Number(accessories1).toFixed(2))); } else { $('.accessories1').html(''); $('.accessories1_org').html(''); }
            }else if(item.name == 'Housewrap Tape' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories2 = item.price * value;
                accessories2 = accessories2 * 2 * 1.1
                if(accessories2 > 0) { $('.accessories2').html('$' + numberWithCommas(Number(accessories2 * computationDiscount * 2.2).toFixed(2))); $('.accessories2_org').html('$' + numberWithCommas(Number(accessories2).toFixed(2))); } else { $('.accessories2').html('');  $('.accessories2_org').html(''); }
            }else if(item.name == 'Touch Up Kits' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories3 = item.price * value;
                accessories3 = accessories3 * 2 * 1.1
                if(accessories3 > 0) { $('.accessories3').html('$' + numberWithCommas(Number(accessories3 * computationDiscount * 2.2).toFixed(2))); $('.accessories3_org').html('$' + numberWithCommas(Number(accessories3).toFixed(2))); } else { $('.accessories3').html(''); $('.accessories3_org').html(''); }
            }else if(item.name == '.019 24X50 Trim Coil' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories4 = item.price * value;
                accessories4 = accessories4 * 2 * 1.1
                if(accessories4 > 0) { $('.accessories4').html('$' + numberWithCommas(Number(accessories4 * computationDiscount * 2.2).toFixed(2))); $('.accessories4_org').html('$' + numberWithCommas(Number(accessories4).toFixed(2))); } else { $('.accessories4').html(''); $('.accessories4_org').html(''); }
            }else if(item.name == 'J-Block Light Block' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories5 = item.price * value;
                accessories5 = accessories5 * 2 * 1.1
                if(accessories5 > 0) { $('.accessories5').html('$' + numberWithCommas(Number(accessories5 * computationDiscount * 2.2).toFixed(2))); $('.accessories5_org').html('$' + numberWithCommas(Number(accessories5).toFixed(2))); } else { $('.accessories5').html(''); $('.accessories5_org').html(''); }
            }

            else if(item.name == 'Recessed J-Block/Split Mini' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories6 = item.price * value;
                accessories6 = accessories6 * 2 * 1.1
                if(accessories6 > 0) { $('.accessories6').html('$' + numberWithCommas(Number(accessories6 * computationDiscount * 2.2).toFixed(2))); $('.accessories6_org').html('$' + numberWithCommas(Number(accessories6).toFixed(2))); } else { $('.accessories6').html('');  $('.accessories6_org').html(''); }
            }else if(item.name == 'Dryer Vent' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories7 = item.price * value;
                accessories7 = accessories7 * 2 * 1.1
                if(accessories7 > 0) { $('.accessories7').html('$' + numberWithCommas(Number(accessories7 * computationDiscount * 2.2).toFixed(2))); $('.accessories7_org').html('$' + numberWithCommas(Number(accessories7).toFixed(2))); } else { $('.accessories7').html(''); $('.accessories7_org').html(''); }
            }else if(item.name == 'Hooded Vent' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories8 = item.price * value;
                accessories8 = accessories8 * 2 * 1.1
                if(accessories8 > 0) { $('.accessories8').html('$' + numberWithCommas(Number(accessories8 * computationDiscount * 2.2).toFixed(2))); $('.accessories8_org').html('$' + numberWithCommas(Number(accessories8).toFixed(2))); } else { $('.accessories8').html('');  $('.accessories8_org').html(''); }
            }else if(item.name == 'Color Match Caulk' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories9 = item.price * value;
                accessories9 = accessories9 * 2 * 1.1
                if(accessories9 > 0) { $('.accessories9').html('$' + numberWithCommas(Number(accessories9 * computationDiscount * 2.2).toFixed(2))); $('.accessories9_org').html('$' + numberWithCommas(Number(accessories9).toFixed(2))); } else { $('.accessories9').html(''); $('.accessories9_org').html(''); }
            }else if(item.name == 'Joint Flashing (Bear Skins)' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories10 = item.price * value;
                accessories10 = accessories10 * 2 * 1.1
                if(accessories10 > 0) { $('.accessories10').html('$' + numberWithCommas(Number(accessories10 * computationDiscount * 2.2).toFixed(2))); $('.accessories10_org').html('$' + numberWithCommas(Number(accessories10).toFixed(2))); } else { $('.accessories10').html(''); $('.accessories10_org').html(''); }
            }

            else if(item.name == 'Siding Coil Nail 2-3/16"' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories11 = item.price * value;
                accessories11 = accessories11 * 2 * 1.1
                if(accessories11 > 0) { $('.accessories11').html('$' + numberWithCommas(Number(accessories11 * computationDiscount * 2.2).toFixed(2)));  $('.accessories11_org').html('$' + numberWithCommas(Number(accessories11).toFixed(2))); } else { $('.accessories11').html(''); $('.accessories11_org').html(''); }
            }else if(item.name == 'Finish Nail 2.5" Straight' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories12 = item.price * value;
                accessories12 = accessories12 * 2 * 1.1
                if(accessories12 > 0) { $('.accessories12').html('$' + numberWithCommas(Number(accessories12 * computationDiscount * 2.2).toFixed(2))); $('.accessories12_org').html('$' + numberWithCommas(Number(accessories12).toFixed(2))); } else { $('.accessories12').html(''); $('.accessories12_org').html(''); }
            }else if(item.name == '18X24 Rectangle Gable Vent' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories13 = item.price * value;
                accessories13 = accessories13 * 2 * 1.1
                if(accessories13 > 0) { $('.accessories13').html('$' + numberWithCommas(Number(accessories13 * computationDiscount * 2.2).toFixed(2))); $('.accessories13_org').html('$' + numberWithCommas(Number(accessories13).toFixed(2))); } else { $('.accessories13').html(''); $('.accessories13_org').html(''); }
            }
        }
    }
 
    function ComputeCost(location_id, labor_type_id, val, name, trade_type) {
        var computationDiscount =  localStorage.getItem('discountPercentage')
        if(computationDiscount == "" || computationDiscount < 0){ computationDiscount = 9 }
        computationDiscount = (100 - computationDiscount) / 100;


         const localData = JSON.parse(localStorage.getItem('_laborPrice'));
         for (const item of localData) {
             if(item.locationid == location_id && item.labortypeid == labor_type_id){
                laborPrice[labor_type_id + '-' + location_id + '-' + name] = item.price;
                computeRoofingPrice(labor_type_id, location_id, name, val);

                if(roofOCOakridgePrice > 0) { $('.roofOCOakridgePrice').html('$' + numberWithCommas(Number(roofOCOakridgePrice * computationDiscount * 2.2).toFixed(2))); $('.roofOCOakridgePrice_org').html('$' + numberWithCommas(Number(roofOCOakridgePrice).toFixed(2))); } else { $('.roofOCOakridgePrice').html(''); $('.roofOCOakridgePrice_org').html(''); }
                if(roofDurationHDZPrice > 0) { $('.roofDurationHDZPrice').html('$' + numberWithCommas(Number(roofDurationHDZPrice * computationDiscount * 2.2).toFixed(2))); $('.roofDurationHDZPrice_org').html('$' + numberWithCommas(Number(roofDurationHDZPrice).toFixed(2))); } else { $('.roofDurationHDZPrice').html(''); $('.roofDurationHDZPrice_org').html(''); }
                if(roofDurationStormPrice > 0) { $('.roofDurationStormPrice').html('$' + numberWithCommas(Number(roofDurationStormPrice * computationDiscount * 2.2).toFixed(2))); $('.roofDurationStormPrice_org').html('$' + numberWithCommas(Number(roofDurationStormPrice).toFixed(2))); } else { $('.roofDurationStormPrice').html(''); $('.roofDurationStormPrice_org').html(''); }
                if(roofLowPitchRoofingPrice > 0) { $('.roofLowPitchRoofingPrice').html('$' + numberWithCommas(Number(roofLowPitchRoofingPrice * computationDiscount * 2.2).toFixed(2))); $('.roofLowPitchRoofingPrice_org').html('$' + numberWithCommas(Number(roofLowPitchRoofingPrice).toFixed(2))); } else { $('.roofLowPitchRoofingPrice').html(''); $('.roofLowPitchRoofingPrice_org').html(''); }
                if(roofPitch812 > 0) { $('.roofPitch812').html('$' + numberWithCommas(Number(roofPitch812 * computationDiscount * 2.2).toFixed(2)));  $('.roofPitch812_org').html('$' + numberWithCommas(Number(roofPitch812).toFixed(2))); } else { $('.roofPitch812').html(''); $('.roofPitch812_org').html(''); }
                if(roofPitch1112 > 0) { $('.roofPitch1112').html('$' + numberWithCommas(Number(roofPitch1112 * computationDiscount * 2.2).toFixed(2))); $('.roofPitch1112_org').html('$' + numberWithCommas(Number(roofPitch1112).toFixed(2))); } else { $('.roofPitch1112').html(''); $('.roofPitch1112_org').html(''); }
                if(roofLayers > 0) { $('.roofLayers').html('$' + numberWithCommas(Number(roofLayers * computationDiscount * 2.2).toFixed(2))); $('.roofLayers_org').html('$' + numberWithCommas(Number(roofLayers).toFixed(2))); } else { $('.roofLayers').html(''); $('.roofLayers_org').html(''); }
                if(roofShake > 0) { $('.roofShake').html('$' + numberWithCommas(Number(roofShake * computationDiscount * 2.2).toFixed(2))); $('.roofShake_org').html('$' + numberWithCommas(Number(roofShake).toFixed(2))); } else { $('.roofShake').html(''); $('.roofShake_org').html(''); }
                if(roof2Story > 0) { $('.roof2Story').html('$' + numberWithCommas(Number(roof2Story * computationDiscount * 2.2).toFixed(2))); $('.roof2Story_org').html('$' + numberWithCommas(Number(roof2Story).toFixed(2))); } else { $('.roof2Story').html(''); $('.roof2Story_org').html(''); }
                if(roofChimneyFlashing > 0) { $('.roofChimneyFlashing').html('$' + numberWithCommas(Number(roofChimneyFlashing * computationDiscount * 2.2).toFixed(2))); $('.roofChimneyFlashing_org').html('$' + numberWithCommas(Number(roofChimneyFlashing).toFixed(2))); } else { $('.roofChimneyFlashing').html(''); $('.roofChimneyFlashing_org').html(''); }
                if(roofSkylightFlashing > 0) { $('.roofSkylightFlashing').html('$' + numberWithCommas(Number(roofSkylightFlashing * computationDiscount * 2.2).toFixed(2))); $('.roofSkylightFlashing_org').html('$' + numberWithCommas(Number(roofSkylightFlashing).toFixed(2))); } else { $('.roofSkylightFlashing').html(''); $('.roofSkylightFlashing_org').html(''); }
                if(roofPlywood > 0) { $('.roofPlywood').html('$' + numberWithCommas(Number(roofPlywood * computationDiscount * 2.2).toFixed(2))); $('.roofPlywood_org').html('$' + numberWithCommas(Number(roofPlywood).toFixed(2))); } else { $('.roofPlywood').html(''); $('.roofPlywood_org').html(''); }
     
                $('#price-roof').html('$' + numberWithCommas(Number(roofPrice * computationDiscount * 2.2).toFixed(2)));
                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#price-roof-org').html('$' + numberWithCommas(Number(roofPrice).toFixed(2)));
                }else{
                    $('#price-roof-org').html('');
                }
                localStorage.setItem('price-roof', JSON.stringify(roofPrice));

                

                if(sidingTIWithFanfold > 0) { $('.sidingTIWithFanfold').html('$' + numberWithCommas(Number(sidingTIWithFanfold * computationDiscount * 2.2).toFixed(2))); $('.sidingTIWithFanfold_org').html('$' + numberWithCommas(Number(sidingTIWithFanfold).toFixed(2))); } else { $('.sidingTIWithFanfold').html(''); $('.sidingTIWithFanfold_org').html(''); }
                if(sidingTIWithOutFanfold > 0) { $('.sidingTIWithOutFanfold').html('$' + numberWithCommas(Number(sidingTIWithOutFanfold * computationDiscount * 2.2).toFixed(2))); $('.sidingTIWithOutFanfold_org').html('$' + numberWithCommas(Number(sidingTIWithOutFanfold).toFixed(2))); } else { $('.sidingTIWithOutFanfold').html(''); $('.sidingTIWithOutFanfold_org').html(''); }
                if(sidingGableVent > 0) { $('.sidingGableVent').html('$' + numberWithCommas(Number(sidingGableVent * computationDiscount * 2.2).toFixed(2))); $('.sidingGableVent_org').html('$' + numberWithCommas(Number(sidingGableVent).toFixed(2))); } else { $('.sidingGableVent').html(''); $('.sidingGableVent_org').html(''); }
                if(sidingWindowWrapSmall > 0) { $('.sidingWindowWrapSmall').html('$' + numberWithCommas(Number(sidingWindowWrapSmall * computationDiscount * 2.2).toFixed(2))); $('.sidingWindowWrapSmall_org').html('$' + numberWithCommas(Number(sidingWindowWrapSmall).toFixed(2))); } else { $('.sidingWindowWrapSmall').html(''); $('.sidingWindowWrapSmall_org').html(''); }
                if(sidingWindowWrapMedium > 0) { $('.sidingWindowWrapMedium').html('$' + numberWithCommas(Number(sidingWindowWrapMedium * computationDiscount * 2.2).toFixed(2))); $('.sidingWindowWrapMedium_org').html('$' + numberWithCommas(Number(sidingWindowWrapMedium).toFixed(2))); } else { $('.sidingWindowWrapMedium').html(''); $('.sidingWindowWrapMedium_org').html(''); }
                if(sidingWindowWrapLarge > 0) { $('.sidingWindowWrapLarge').html('$' + numberWithCommas(Number(sidingWindowWrapLarge * computationDiscount * 2.2).toFixed(2))); $('.sidingWindowWrapLarge_org').html('$' + numberWithCommas(Number(sidingWindowWrapLarge).toFixed(2))); } else { $('.sidingWindowWrapLarge').html(''); $('.sidingWindowWrapLarge_org').html(''); }
                if(sidingGarageDoorWrap > 0) { $('.sidingGarageDoorWrap').html('$' + numberWithCommas(Number(sidingGarageDoorWrap * computationDiscount * 2.2).toFixed(2))); $('.sidingGarageDoorWrap_org').html('$' + numberWithCommas(Number(sidingGarageDoorWrap).toFixed(2))); } else { $('.sidingGarageDoorWrap').html(''); $('.sidingGarageDoorWrap_org').html(''); }
                if(sidingDoorWrap > 0) { $('.sidingDoorWrap').html('$' + numberWithCommas(Number(sidingDoorWrap * computationDiscount * 2.2).toFixed(2))); $('.sidingDoorWrap_org').html('$' + numberWithCommas(Number(sidingDoorWrap).toFixed(2))); } else { $('.sidingDoorWrap').html(''); $('.sidingDoorWrap_org').html(''); }
                if(sidingSoffit12 > 0) { $('.sidingSoffit12').html('$' + numberWithCommas(Number(sidingSoffit12 * computationDiscount * 2.2).toFixed(2))); $('.sidingSoffit12_org').html('$' + numberWithCommas(Number(sidingSoffit12).toFixed(2))); } else { $('.sidingSoffit12').html(''); $('.sidingSoffit12_org').html(''); }
                if(sidingSoffit24 > 0) { $('.sidingSoffit24').html('$' + numberWithCommas(Number(sidingSoffit24 * computationDiscount * 2.2).toFixed(2))); $('.sidingSoffit24_org').html('$' + numberWithCommas(Number(sidingSoffit24).toFixed(2))); } else { $('.sidingSoffit24').html(''); $('.sidingSoffit24_org').html(''); }
                if(sidingFascia > 0) { $('.sidingFascia').html('$' + numberWithCommas(Number(sidingFascia * computationDiscount * 2.2).toFixed(2))); $('.sidingFascia_org').html('$' + numberWithCommas(Number(sidingFascia).toFixed(2))); } else { $('.sidingFascia').html(''); $('.sidingFascia_org').html(''); }

                $('#price-siding').html('$' + numberWithCommas(Number(sidingPrice * computationDiscount * 2.2).toFixed(2)));
                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#price-siding-org').html('$' + numberWithCommas(Number(sidingPrice).toFixed(2)));
                }else{
                    $('#price-siding-org').html('');
                }
                localStorage.setItem('price-siding', JSON.stringify(sidingPrice));



                if(guttersInstall > 0) { $('.guttersInstall').html('$' + numberWithCommas(Number(guttersInstall * computationDiscount * 2.2).toFixed(2))); $('.guttersInstall_org').html('$' + numberWithCommas(Number(guttersInstall).toFixed(2))); } else { $('.guttersInstall').html(''); $('.guttersInstall_org').html(''); }
                if(guttersOSGutters > 0) { $('.guttersOSGutters').html('$' + numberWithCommas(Number(guttersOSGutters * computationDiscount * 2.2).toFixed(2))); $('.guttersOSGutters_org').html('$' + numberWithCommas(Number(guttersOSGutters).toFixed(2))); } else { $('.guttersOSGutters').html(''); $('.guttersOSGutters_org').html(''); }
                if(guttersDownspouts > 0) { $('.guttersDownspouts').html('$' + numberWithCommas(Number(guttersDownspouts * computationDiscount * 2.2).toFixed(2))); $('.guttersDownspouts_org').html('$' + numberWithCommas(Number(guttersDownspouts).toFixed(2))); } else { $('.guttersDownspouts').html(''); $('.guttersDownspouts_org').html(''); }
                if(guttersOSDownspouts > 0) { $('.guttersOSDownspouts').html('$' + numberWithCommas(Number(guttersOSDownspouts * computationDiscount * 2.2).toFixed(2))); $('.guttersOSDownspouts_org').html('$' + numberWithCommas(Number(guttersOSDownspouts).toFixed(2))); } else { $('.guttersOSDownspouts').html(''); $('.guttersOSDownspouts_org').html(''); }
                if(guttersApron > 0) { $('.guttersApron').html('$' + numberWithCommas(Number(guttersApron * computationDiscount * 2.2).toFixed(2))); $('.guttersApron_org').html('$' + numberWithCommas(Number(guttersApron).toFixed(2))); } else { $('.guttersApron').html(''); $('.guttersApron_org').html(''); }
                if(guttersScreens > 0) { $('.guttersScreens').html('$' + numberWithCommas(Number(guttersScreens * computationDiscount * 2.2).toFixed(2))); $('.guttersScreens_org').html('$' + numberWithCommas(Number(guttersScreens).toFixed(2))); } else { $('.guttersScreens').html(''); $('.guttersScreens_org').html(''); }
                if(guttersGuards > 0) { $('.guttersGuards').html('$' + numberWithCommas(Number(guttersGuards * computationDiscount * 2.2).toFixed(2))); $('.guttersGuards_org').html('$' + numberWithCommas(Number(guttersGuards).toFixed(2))); } else { $('.guttersGuards').html(''); $('.guttersGuards_org').html(''); }

                $('#price-gutter').html('$' + numberWithCommas(Number(gutterPrice * computationDiscount * 2.2).toFixed(2)));
                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#price-gutter-org').html('$' + numberWithCommas(Number(gutterPrice).toFixed(2)));
                }else{
                    $('#price-gutter-org').html('');
                }
                localStorage.setItem('price-gutter', JSON.stringify(gutterPrice));

                var tmpFinalDPF = localStorage.getItem('finalDeliveryPickupFees');
                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#finalDeliveryPickupFees-disc').html('$' + numberWithCommas(Number(tmpFinalDPF).toFixed(2)));
                }else{
                    $('#finalDeliveryPickupFees-disc').html('');
                }

                var tmpTaxesATSP = localStorage.getItem('taxesAddedTSP', taxesAddedTSP);
                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#taxesAddedTSP-disc').html('$' + numberWithCommas(Number(tmpTaxesATSP).toFixed(2)));
                }else{
                    $('#taxesAddedTSP-disc').html('');
                }

                var tmpTotalEstimatedCost = ( Number(localStorage.getItem('totalEstimatedCost')) - Number(localStorage.getItem('finalDeliveryPickupFees')) - Number(localStorage.getItem('taxesAddedTSP')) );
                //tmpTotalEstimatedCost = ( (tmpTotalEstimatedCost * computationDiscount * 2.2) + Number(localStorage.getItem('finalDeliveryPickupFees')) + Number(localStorage.getItem('taxesAddedTSP')) );
                tmpTotalEstimatedCost = (tmpTotalEstimatedCost * computationDiscount * 2.2);
                $('#TotalEstimatedCost-disc').html('$' + numberWithCommas(Number(tmpTotalEstimatedCost).toFixed(2)));
                localStorage.setItem('TotalEstimatedCost-disc', tmpTotalEstimatedCost);
             }
         }
     }
 
 
    async function startComputation(location_id){
        resetValues()
        RoofEstimatePricing25SQInner()
        setLaborPrice()

        
        $('.input-number').each(function(){
            var name = $(this).attr('data-name');
            var labor_type_id = $(this).attr('data-laborType');
            var trade_type = $(this).attr('data-tradeType');
            var value = $(this).val();

            var trade_type = $(this).attr('data-tradeType');
            var supplier_id = $(this).attr('data-supplier');
 
             if(name == "No Of Salesman"){
                 if(value > 0){
                     var foundThreshold = false;
                     const _salesCommission = JSON.parse(localStorage.getItem('_salesCommission'))
                     for (const item of _salesCommission) {
                         if(item.locationid == location_id && value <= item.threshold && !foundThreshold){
                             var salesCost = item.percent / 100;
                             salesCost *= $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val();
                             $('#salesCost').html('$' + numberWithCommas(Number(salesCost).toFixed(2)));
                             foundThreshold = true;
                         }
                     }
                 }
            }else{
                 if(value > 0) {
                     ComputeCost(location_id, labor_type_id, value, name, trade_type)
                 }
            }
        })

        $('.compute-cost-cement').each(function(){
            var name = $(this).attr('data-name');
            var labor_type_id = $(this).attr('data-laborType');
            var trade_type = $(this).attr('data-tradeType');
            var supplier_id = $(this).attr('data-supplier');
            var value = $(this).val();

            if(value > 0) {
                computeCementSiding(name, trade_type, location_id, supplier_id, value)
            }
            
        });	
     }
 
    function clearValues(){
        $('.roofOCOakridgePrice').html('')
        $('.roofDurationHDZPrice').html('')
        $('.roofDurationStormPrice').html('')
        $('.roofLowPitchRoofingPrice').html('')
        $('.roofPitch812').html('')
        $('.roofPitch1112').html('')
        $('.roofLayers').html('')
        $('.roofShake').html('')
        $('.roof2Story').html('')
        $('.roofChimneyFlashing').html('')
        $('.roofSkylightFlashing').html('')
        $('.roofPlywood').html('')

        $('#price-roof').html('$0.00')
        localStorage.setItem('price-roof', 0)

        $('#BSANRDOverhead').html('')
        $('#BSANRDCredit').html('')
        $('#absoluteLowest').html('')
        $('#finalDeliveryPickupFees').html('')
        $('#taxesAddedTSP').html('')

        var taxesAddedTSP = 0;
        localStorage.setItem('taxAvgOakridgeCost', 0);
        localStorage.setItem('taxAvgDurationCost', 0);
        localStorage.setItem('taxAvgDStormCost', 0);
        localStorage.setItem('taxAvgSidingCost', 0);
        localStorage.setItem('taxAvgSidingFFCost', 0);
    }
 
 
     
    function totalEstimatedComputation(location_id){
         RoofEstimatePricing25SQInner()
         var tmpVal = ""
 
         //var location_id = $('#locations option:selected').val();
 
         var _taxRates = JSON.parse(localStorage.getItem('_taxRates'))
         for (const item of _taxRates) {
             taxRates[item.locationid] = item.tax;
         }
         var _overhead = JSON.parse(localStorage.getItem('_overhead'))
         for (const item of _overhead) {
             overhead[item.locationid] = item.percent;
         }
         
         
         var taxesAddedTSP = 0;
         if($('input[data-name="OC Oakridge Arch*"]').val() > 0){
             taxAvgOakridgeCost = (avgOakridgeCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="OC Oakridge Arch*"]').value))
         }
         if($('input[data-name="OC Duration Tru-Def*"]').val() > 0){
             taxAvgDurationCost = (avgDurationHDZCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="OC Duration Tru-Def*"]').value))
         }
         if($('input[data-name="Duration Storm"]').val() > 0){
             taxAvgDStormCost = (avgDStormCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Duration Storm"]').value))
         }
         if($('input[data-name="Tear-Off SQ"]').val() > 0){
             taxAvgSidingCost = (avgSidingCostSQ * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Tear-Off SQ"]').value))
         }
         if($('input[data-name="Install SQ"]').val() > 0){
             taxAvgSidingFFCost = (avgSidingCostFFSQ * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Install SQ"]').value))
         }
         
         
        taxesAddedTSP = 0;
        var taxesAddedTSPA = taxAvgOakridgeCost + taxAvgDurationCost + taxAvgDStormCost + taxAvgSidingCost + taxAvgSidingFFCost;   
        
        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#taxesAddedTSP').html('');
                $('#taxesAddedTSPA').html('');
        }else{
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                $('#taxesAddedTSP').html('$' + numberWithCommas(Number(taxesAddedTSP).toFixed(2)));
                $('#taxesAddedTSPA').html('$' + numberWithCommas(Number(taxesAddedTSPA).toFixed(2)));
            }else{
                $('#taxesAddedTSP').html('');
                $('#taxesAddedTSPA').html('');
            }
        }
        localStorage.setItem('taxesAddedTSP', taxesAddedTSP);
        localStorage.setItem('taxesAddedTSPA', taxesAddedTSPA);

 
        var TotalEstimatedCost = Number(localStorage.getItem('price-roof'))
        TotalEstimatedCost = (TotalEstimatedCost + Number(localStorage.getItem('price-siding')))
        TotalEstimatedCost = (TotalEstimatedCost + Number(localStorage.getItem('price-gutter')))
 
        var finalDeliveryPickupFees = 0;
        var finalDeliveryPickupFeesA = 0;
        if(($('input[data-name="OC Oakridge Arch*"]').val() +
            $('input[data-name="OC Duration Tru-Def*"]').val() +
            $('input[data-name="Duration Storm"]').val() + 
            $('input[data-name="Low-Pitch Roofing"]').val()) > 0){
                finalDeliveryPickupFeesA += 75;
                finalDeliveryPickupFees = 0;
        }
        if(($('input[data-name="Tear-Off SQ"]').val() +
            $('input[data-name="Install SQ"]').val() +
            $('input[data-name="S Window Wrap"]').val() + 
            $('input[data-name="Door Wrap"]').val() +
            $('input[data-name="Soffit"]').val() +
            $('input[data-name="Tear-Off Sof/Fas"]').val() +
            $('input[data-name="Fascia"]').val()) > 0){
                finalDeliveryPickupFeesA += 75;				
                finalDeliveryPickupFees = 0;
        }
        
        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#finalDeliveryPickupFees').html('');
                $('#finalDeliveryPickupFeesA').html('');
        }else{
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                $('#finalDeliveryPickupFees').html('$' + numberWithCommas(Number(finalDeliveryPickupFees).toFixed(2)));
                $('#finalDeliveryPickupFeesA').html('$' + numberWithCommas(Number(finalDeliveryPickupFeesA).toFixed(2)));
            }else{
                $('#finalDeliveryPickupFees').html('');
                $('#finalDeliveryPickupFeesA').html('');
            }
        }
         localStorage.setItem('finalDeliveryPickupFees', finalDeliveryPickupFees);
         localStorage.setItem('finalDeliveryPickupFeesA', finalDeliveryPickupFeesA);
         TotalEstimatedCost = (TotalEstimatedCost + taxesAddedTSP)
         TotalEstimatedCost = (TotalEstimatedCost + finalDeliveryPickupFees)


        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#TotalEstimatedCost').html('');
        }else{       
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#TotalEstimatedCost').html('');
                }else{
                    //$('#TotalEstimatedCost').html('$' + numberWithCommas(Number(TotalEstimatedCost).toFixed(2)));
                    $('#TotalEstimatedCost').html('$' + numberWithCommas((Number(TotalEstimatedCost) + Number(finalDeliveryPickupFeesA) + Number(taxesAddedTSPA)).toFixed(2)));
                }
            }else{
                $('#TotalEstimatedCost').html('');
            }
        }
        localStorage.setItem('totalEstimatedCost', TotalEstimatedCost)
 
 
         
         var targetSalesPrice = ((TotalEstimatedCost * 2) * 1.1)
         localStorage.setItem('targetSalesPrice2', targetSalesPrice);
         if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
            if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
            document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
            document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                $('#targetSalesPrice').html('');
            }else{
                $('#targetSalesPrice').html('$' + numberWithCommas(Number(targetSalesPrice).toFixed(2)));
            }
        }else{
            $('#targetSalesPrice').html('');
        }
         $('.targetSalesPrice').html('$' + numberWithCommas(Number(targetSalesPrice).toFixed(2)));
         
         var numberTEN = ((TotalEstimatedCost * 2) * 1.1) * 0.9;
         $('#numberTEN').html('$' + numberWithCommas(Number(numberTEN).toFixed(2)));
         
         
         var BSANRDCredit = $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val();
         $('#BSANRDCredit').html('$' + numberWithCommas(Number(BSANRDCredit).toFixed(2)));
 
         var currentRevenue = BSANRDCredit + Number($('input[data-name="Agreed Retail Amounts"]').val());
         $('#currentRevenue').html('$' + numberWithCommas(Number(currentRevenue).toFixed(2)));
         
         var BSANRDOverhead = BSANRDCredit;
         BSANRDOverhead *= overhead[location_id] / 100;
         BSANRDOverhead += TotalEstimatedCost;
         $('#BSANRDOverhead').html('$' + numberWithCommas(Number(BSANRDOverhead).toFixed(2)));
         
         var absoluteLowest = targetSalesPrice * 0.55
         $('#absoluteLowest').html('$' + numberWithCommas(Number(absoluteLowest).toFixed(2)));
 
         var lowTargetSalesPrice = (TotalEstimatedCost -finalDeliveryPickupFees - taxesAddedTSP)* 1.6364
         localStorage.setItem('targetSalesPrice1', lowTargetSalesPrice);
         var lowTargetSalesPrice_step1 = lowTargetSalesPrice * 0.9
         if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
            if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
            document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
            document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                $('#lowTargetSalesPrice').html('');
            }else{
                $('#lowTargetSalesPrice').html('$' + numberWithCommas(Number(lowTargetSalesPrice_step1).toFixed(2)));
            }
        }else{
            $('#lowTargetSalesPrice').html('');
        }
         $('.lowTargetSalesPrice').html('$' + numberWithCommas(Number(lowTargetSalesPrice_step1).toFixed(2)));
 
         var additionalDollarRequired = "N/A"
         if((lowTargetSalesPrice_step1 - currentRevenue) > 0){
             additionalDollarRequired = (lowTargetSalesPrice_step1 - currentRevenue)
             $('#additionalDollarRequired').html('$' + numberWithCommas(Number(additionalDollarRequired).toFixed(2)));
         }else{
             $('#additionalDollarRequired').html(additionalDollarRequired);
         }
         if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#additionalDollarRequired').html('');
        }
 
 
         var overheadCost = currentRevenue * 0.22
         $('#overheadCost').html('$' + numberWithCommas(Number(overheadCost).toFixed(2)));
 
         var salesCommissionCost = currentRevenue * 0.12
         $('#salesCommissionCost').html('$' + numberWithCommas(Number(salesCommissionCost).toFixed(2)));
 
         var estimatedNetProfitAfterOverhead = currentRevenue - (overheadCost + salesCommissionCost) - TotalEstimatedCost
         if(estimatedNetProfitAfterOverhead < 0) { tmpVal = '$ (' + numberWithCommas(Number(estimatedNetProfitAfterOverhead).toFixed(2)) + ')'
         } else { tmpVal = '$' + numberWithCommas(Number(estimatedNetProfitAfterOverhead).toFixed(2)) }
         $('#estimatedNetProfitAfterOverhead').html(tmpVal);
 
         var netProfitAfterOverhead = estimatedNetProfitAfterOverhead / TotalEstimatedCost * 100
         $('#netProfitAfterOverhead').html(numberWithCommas(Number(netProfitAfterOverhead).toFixed(2)) + '%');
 
         
         var estimatedNetProfit = $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val() - BSANRDOverhead;
         if(estimatedNetProfit < 0) {
             $('#estimatedNetProfit').html('$(' + numberWithCommas(Number((estimatedNetProfit * -1)).toFixed(2)) + ')');
         }else{
             $('#estimatedNetProfit').html('$' + numberWithCommas(Number(estimatedNetProfit).toFixed(2)));
         }
         
         var awayFromTarget = 1 - (BSANRDCredit / targetSalesPrice);
         awayFromTarget = awayFromTarget * 100;
         $('#awayFromTarget').html(Number(awayFromTarget).toFixed(1) + '%');
         
         
         var additionalRequiredAmount = 0;
         if(awayFromTarget < 45){
             additionalRequiredAmount = "N/A";
             $('#estimatedNetProfit').html(additionalRequiredAmount);
         }else{
             additionalRequiredAmount = absoluteLowest - ($('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val());
             $('#additionalRequiredAmount').html('$' + numberWithCommas(Number(additionalRequiredAmount).toFixed(2)));
         }
 
 
         var targetSalesDiscount = (Number(localStorage.getItem('targetSalesPrice2')) - Number(lowTargetSalesPrice_step1))
         $('.targetSalesDiscount').html('$' + numberWithCommas(Number(targetSalesDiscount).toFixed(2)))
         localStorage.setItem('targetSalesDiscount', targetSalesDiscount);
         var val3 = (Number(localStorage.getItem('targetSalesPrice2')) - Number(lowTargetSalesPrice_step1)) / Number(localStorage.getItem('targetSalesPrice2')) * 100
         var commission = 0
         var median = (Number(localStorage.getItem('targetSalesPrice2')) + (Number(localStorage.getItem('targetSalesPrice1')) * 0.9)) / 2;
         var medianDiscount = (lowTargetSalesPrice_step1 - median) / median * 100;
         if(medianDiscount > 19.9 && medianDiscount <= 40.9) { commission = 11 / 100 } else
            if(medianDiscount > 9.9 && medianDiscount <= 19.9) { commission = 10 / 100 } else
            if(medianDiscount > -1.9 && medianDiscount <= 9.9) { commission = 9 / 100 } else
            if(medianDiscount > -9.9 && medianDiscount <= -1.9) { commission = 7 / 100 } else
            if(medianDiscount > -15.9 && medianDiscount <= -9.9) { commission = 5 / 100 } else
            if(medianDiscount > -16.9 && medianDiscount <= -15.9) { commission = 3 / 100 } else
            if(medianDiscount > -25.9 && medianDiscount <= -16.9) { commission = 2 / 100 } else
            { commission = 0 }
         /*
         if(val3 > -1 && val3 <= 5.9) { commission = 12 / 100 } else
         if(val3 > 5.9 && val3 <= 10.9) { commission = 9.5 / 100 } else
         if(val3 > 10.9 && val3 <= 15.9) { commission = 8.5 / 100 } else
         if(val3 > 15.9 && val3 <= 20.9) { commission = 7 / 100 } else
         if(val3 > 20.9 && val3 <= 30.9) { commission = 5 / 100 } else
         if(val3 > 30.9 && val3 <= 40.9) { commission = 2.5 / 100 } else { commission = 0 }
         */
         var targetSalesCommission = Number(lowTargetSalesPrice_step1) * commission
         localStorage.setItem('targetSalesCommissionPercentage', (commission * 100));
         localStorage.setItem('targetSalesCommission', targetSalesCommission);
         $('.targetSalesCommission').html('$' + numberWithCommas(Number(targetSalesCommission).toFixed(2)));
    }
    /********************************
     * Computation - Roofing - End
     ********************************/




    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    async function getproductListPrice(){
        $.ajax({
            url: baseURL + '/api/product-list-price',
            type: "GET",
            success: function(data){
                localStorage.setItem('_productListPrice', JSON.stringify(data));
            },
            error: function(errors){
                console.log(errors);
            },
            complete: function(){
            }
        })
    }


    async function getLaborPrice(){
        $.ajax({
            url: baseURL + '/api/labor-price',
            type: "GET",
            success: function(data){
                localStorage.setItem('_laborPrice', JSON.stringify(data));
            },
            error: function(errors){
                console.log(errors);
            },
            complete: function(){
            }
        })
    }

    async function getTaxRates(){
        $.ajax({
            url: baseURL + '/api/tax-rates',
            type: "GET",
            success: function(data){
                localStorage.setItem('_taxRates', JSON.stringify(data));
            },
            error: function(errors){
                console.log(errors);
            },
            complete: function(){
            }
        })
    }

    async function getOverhead(){
        $.ajax({
            url: baseURL + '/api/overhead',
            type: "GET",
            success: function(data){
                localStorage.setItem('_overhead', JSON.stringify(data));
            },
            error: function(errors){
                console.log(errors);
            },
            complete: function(){
            }
        })
    }

    async function getSalesCommission(){
        $.ajax({
            url: baseURL + '/api/sales-commission',
            type: "GET",
            success: function(data){
                localStorage.setItem('_salesCommission', JSON.stringify(data));
            },
            error: function(errors){
                console.log(errors);
            },
            complete: function(){
            }
        })
    }

    async function getCementSiding(location_id){
        if(loadingHardie) { return; }

        loadingHardie = true;
        localStorage.setItem('_cementSiding', '');
        $.ajax({
            url: baseURL + '/api/hardie-estimate',
            type: "GET",
            async: false,
            cache: false,
            data: {
                location_id: location_id
            },
            success: function(data){
                localStorage.setItem('_cementSiding', JSON.stringify(data));
            },
            error: function(errors){
                console.log(errors);
            },
            complete: function(){
                loadingHardie = false;
            }
        })
    }



    async function RoofEstimatePricing25SQ(location_id){
        if(loadingAVG) { return; }

        loadingAVG = true;
        localStorage.setItem('_averageCostRoofing', '');
        $.ajax({
            url: baseURL + '/api/average-cost/' + location_id,
            type: "GET",
            cache: false,
            async: false,
            success: function(data){
                localStorage.setItem('_averageCostRoofing', JSON.stringify(data));
                document.querySelector('.calWidthSlide').style.display="table-cell";
            },
            error: function(errors){
                document.querySelector('.calWidthSlide').style.display="none";
                console.log(errors);
            },
            complete: function(){
                loadingAVG = false;
            }
        })
    }


    async function getSavedEstimate(id){

        var settings = {
            "url": "https://aprocal.plego.cloud/buildfly_api/api/get_estimate",
            "method": "GET",
            "timeout": 0,
            "headers": {
                "Client-Service": "app-client",
                "Auth-Key": "AmeriPro App",
                "Content-Type": "application/json",
                "User-ID": localStorage.getItem('userID'),
                "Authorization": localStorage.getItem('userToken'),
                "id": id,
            },
        };
            
        $.ajax(settings).done(function (data) {
            // Step 1: Get Location
            const results = []
            const location = data.location
            location.forEach((value) => {
                results.push({
                    key: value.name,
                    value: value.id,
                })
            })
            setLocations([
                ...results
            ])
            // Step 2: Get RoofEstimatePricing25SQ
            localStorage.setItem('_averageCostRoofing', data.average_cost)
            // Step 3: Get getCementSiding
            localStorage.setItem('_cementSiding', data.cement_siding)
            // Step 4: Get getLaborPrice
            localStorage.setItem('_laborPrice', data.labor_price)
            // Step 5: Get getTaxRates
            localStorage.setItem('_taxRates', data.tax_rates)
            // Step 6: Get getOverhead
            localStorage.setItem('_overhead', data.overhead)
            // Step 7: Get getSalesCommission
            localStorage.setItem('_salesCommission', data.sales_commission)

            const estimate = JSON.parse(data.estimate)
            estimate.forEach((d) => {
                document.querySelector("input[data-name='" + d.name + "']").value = d.value
            })
            $('#txt-estimate-name').val(data.name)
            $('#address').val(data.address)
            localStorage.setItem('discountPercentage', data.discount_percentage);
            localStorage.setItem('current_address', data.address);
            
            var nrd_or_credit = JSON.parse(data.nrd_or_credit)
            var spanTxt = '';
            var inpTxt = '';
            var closeItm = '';
            var totalNRDorCredit = 0;
            for(var i=0; i<nrd_or_credit.length; i++){
                spanTxt = '<span class="nrd-or-credit-txt">' + nrd_or_credit[i]['text'] + '</span>'
                inpTxt = '<input type="text" class="nrd-or-credit-val quantity form-control input-number" value="' + nrd_or_credit[i]['value'] + '"/>'
                closeItm = '<span class="close-icon">-</span>'
                $('#appendNRD').before('<tr collspan="10"><td class="listner">' + closeItm + spanTxt + '</td><td>' + inpTxt + '</td></tr>')
                totalNRDorCredit = totalNRDorCredit + Number(nrd_or_credit[i]['value'])
            }
            //$("input[data-name='NRD or Credit']").val(totalNRDorCredit).trigger("input");

            document.querySelector('input[id="discountPercentage"]').value = data.discount_percentage

            startComputation(location[0].id)
            totalEstimatedComputation(location[0].id)

        }).fail(function(data){
            console.log(data)
        });
    }


    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
    
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
    
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    }

    async function loadPageData(){
        var mode = getUrlParameter('mode')
        if(mode){
            var id = getUrlParameter('id')
            if(mode == 'view') { $('.div-btn-save').hide(); }
            getSavedEstimate(id)
        }else{
            componentDidMount()
            await getLaborPrice()
            await getTaxRates()
            await getOverhead()
            await getSalesCommission()
            //getproductListPrice()
        }
    }

    async function componentDidMount() {
        var userID = localStorage.getItem('userID');
        fetch(url + "/api/locations?userID=" + userID)
        .then((res) => res.json())
        .then((json) => {
            const results = []
            json.forEach((value) => {
                results.push({
                    key: value.name,
                    value: value.id,
                })
            })
            setLocations([
                ...results
            ])
            RoofEstimatePricing25SQ(results[0].value)
            getCementSiding(results[0].value)
        })
    };


    useEffect(() => {
        loadPageData()
    }, [])


    $(function() {        
        $(document).on('change', '#locations', async function(e){
            var cells = document.getElementsByClassName( 'emptyVal' );
            [].slice.call( cells ).forEach(function ( cell ) {
                cell.innerHTML = '';
            });
            var cells = document.getElementsByClassName( 'bx-align-center' );
            [].slice.call( cells ).forEach(function ( cell ) {
                cell.innerHTML = '';
            });
            var cells = document.getElementsByClassName( 'rightAlign' );
            [].slice.call( cells ).forEach(function ( cell ) {
                cell.innerHTML = '';
            });

            document.getElementById('finalDeliveryPickupFeesA').innerHTML = '';
            document.getElementById('taxesAddedTSPA').innerHTML = '';
            document.getElementById('TotalEstimatedCost').innerHTML = '';
            
            
            
            var location_id = $('#locations option:selected').val()
            await RoofEstimatePricing25SQ(location_id)
            await getCementSiding(location_id)
            await startComputation(location_id)

            var cells = document.getElementsByClassName( 'compute-cost-roofing' );
            [].slice.call( cells ).forEach(function ( cell ) {
                var ev = new Event('input', { bubbles: true});
                ev.simulated = true;
                cell.value = cell.value;
                cell.dispatchEvent(ev);
            });


            $('input[data-name="Total BSA"]').val('')
            $('input[data-name="NRD or Credit"]').val('')
            $('input[data-name="Agreed Retail Amounts"]').val('')
        })
    })


    return (
        <select className="form-select" aria-label=".form-select-lg example mgb-20" style={{marginBottom: 20}} id="locations">
            {locations.map((option) => {
                return (
                    <option key={option.key} value={option.value}>
                    {option.key}
                    </option>
                );
            })}
        </select>
        )
}

export default Home