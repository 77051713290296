import React, { useState, useEffect } from "react";

import Header from '../essentials/Header.js'
import SideNav from '../essentials/SideNav.js'
import Footer from '../essentials/Footer.js'
import MainCont from '../essentials/MainCont.js'

import SelectCalOpt from '../partials/SelectCalOpt'
import RoofingTable from '../partials/RoofingTable'
import CementSidingTable from '../partials/CementSidingTable.js'
import TotalEstimatedCost from '../partials/TotalEstimatedCost.js'
import OverallCost from "../partials/OverallCost.js"
import VinylSidingTable from "../partials/VinylSidingTable.js"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GuttersTable from "../partials/GuttersTable.js";
import $ from 'jquery';


import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';



const Home = (props,context) =>  {    
    const [navBar, setState] = useState("roofing")
    const [address, setAddress] = useState("")
    const [savingData, setSavingData] = useState(false)
    const [typing, setTyping] = useState(false)
    const [typingTimeout, setTypingTimeout] = useState(0)

    function cleanData(locationID, data){
        var tmp = JSON.parse(localStorage.getItem(data))
        var tmpArr = []
        for (const item of tmp) {
            if(item.locationid == locationID){
                tmpArr.push(item)
            }
        }
        return JSON.stringify(tmpArr)
    }


    String.prototype.replaceAll = function(str1, str2, ignore) {
        return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.replace(/\$/g,"$$$$"):str2);
    } 


    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
    
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
    
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    }

    const selectDataValue = (e) => {
        let valueTarget = e.target.innerHTML;
        let suggestionBox = document.querySelector('.suggestions ul');
        let el = document.querySelector('.suggestion-inp');
        el.value=valueTarget;
        setAddress(valueTarget)
        localStorage.setItem('current_address', valueTarget);
        suggestionBox.style.display="none";
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            showSuggestion(address)
        }, 500)
    
        return () => clearTimeout(timer)
      }, [address])
    
    let showSuggestion = (value) => {
        const suggBox = document.querySelector('.suggestions ul');
        //let value = e.target.value;

        if(value!=""){
            suggBox.style.display="block";
        }else {suggBox.style.display="none"}
        document.addEventListener('mouseup', function(e) {
            if (!suggBox.contains(e.target)) {
                suggBox.style.display = 'none';
            }
        });

        function removeAllChildNodes(parent) {
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }
        }
        removeAllChildNodes(suggBox);

        var settings = {
            "url": "https://aprocal.plego.cloud/buildfly_api/api/places",
            "method": "GET",
            "timeout": 0,
            "headers": {
              "Client-Service": "app-client",
              "Auth-Key": "AmeriPro App",
              "Content-Type": "application/x-www-form-urlencoded",
              "User-ID": localStorage.getItem('userID'),
              "Authorization": localStorage.getItem('userToken'),
              "Address": value
            },
        };
          
        $.ajax(settings).done(function (response) {
            let jsonData = response //JSON.parse(response)
            for(let i=0;i<jsonData.length;i++){
                let dataSuggest = jsonData[i]["description"]
                const liData = document.createElement("li")
                liData.classList.add('list-group-item')
                liData.innerHTML=dataSuggest
                suggBox.appendChild(liData)
                liData.addEventListener('click', selectDataValue)
            }
        }).fail(function(data){
            console.log(data)
        })
    }


    let checkKeyWord = (e) => {
    }


    let handleBtnSave = (e) => {
        e.preventDefault()

        var num = 0
        var nrdCredit = [];
        $('.nrd-or-credit-txt').each(function(i, obj) {
            var text = $(this).html()
            console.log(text)

            var value = $('.nrd-or-credit-val').eq(num).val()
            console.log(value)

            nrdCredit[num] = { 'text': text, 'value': value }

            num++
        });
        var savingEstimate = localStorage.getItem('savingEstimate');

        if(savingEstimate == 1) { return; }

        localStorage.setItem('savingEstimate', 1);

        var id = 0;
        var mode = getUrlParameter('mode')
        if(getUrlParameter('id')) { id = getUrlParameter('id') }


        $('.msg-save').html('Checking data...')
        const estimateName = $('#txt-estimate-name').val()

        if(estimateName == "") { $('.msg-save').html('Name is required.'); $('#txt-estimate-name').focus(); return; }


        const location_id = $('#locations option:selected').val()

        const _productListPrice = ''
        const _laborPrice = cleanData(location_id, '_laborPrice')
        const _taxRates = cleanData(location_id, '_taxRates')
        const _overhead = cleanData(location_id, '_overhead')
        const _salesCommission = cleanData(location_id, '_salesCommission')
        const _cementSiding = cleanData(location_id, '_cementSiding')
        const _averageCostRoofing = localStorage.getItem('_averageCostRoofing')

        var estimateData = [];

        $('.compute-cost-roofing').each(function(){
            var name = $(this).attr('data-name');
            var labor_type_id = $(this).attr('data-laborType');
            var trade_type = $(this).attr('data-tradeType');
            var value = $(this).val();
            var totalClass = $(this).attr('data-total')
            var total = $('.' + totalClass).html()
            total = total.replaceAll('$', '')
            total = total.replaceAll(',', '')

            if(value > 0) {
                var obj = {}
                obj["name"] = name.replaceAll('"', '\"')
                obj["labor_type_id"] = labor_type_id
                obj["trade_type"] = trade_type
                obj["value"] = value
                obj["total"] = total
                obj["total_class"] = totalClass
                estimateData.push(obj)
            }
        })


        $('.compute-cost-siding').each(function(){
            var name = $(this).attr('data-name');
            var labor_type_id = $(this).attr('data-laborType');
            var trade_type = $(this).attr('data-tradeType');
            var value = $(this).val();
            var totalClass = $(this).attr('data-total')
            var total = $('.' + totalClass).html()
            total = total.replaceAll('$', '')
            total = total.replaceAll(',', '')

            if(value > 0) {
                var obj = {}
                obj["name"] = name.replaceAll('"', '\"')
                obj["labor_type_id"] = labor_type_id
                obj["trade_type"] = trade_type
                obj["value"] = value
                obj["total"] = total
                obj["total_class"] = totalClass
                estimateData.push(obj)
            }
        })


        $('.compute-cost-gutter').each(function(){
            var name = $(this).attr('data-name');
            var labor_type_id = $(this).attr('data-laborType');
            var trade_type = $(this).attr('data-tradeType');
            var value = $(this).val();
            var totalClass = $(this).attr('data-total')
            var total = $('.' + totalClass).html()
            total = total.replaceAll('$', '')
            total = total.replaceAll(',', '')

            if(value > 0) {
                var obj = {}
                obj["name"] = name.replaceAll('"', '\"')
                obj["labor_type_id"] = labor_type_id
                obj["trade_type"] = trade_type
                obj["value"] = value
                obj["total"] = total
                obj["total_class"] = totalClass
                estimateData.push(obj)
            }
        })


        $('.compute-cost-cement').each(function(){
            var name = $(this).attr('data-name');
            var supplier = $(this).attr('data-supplier');
            var trade_type = $(this).attr('data-tradeType');
            var value = $(this).val();
            var totalClass = $(this).attr('data-total')
            var total = $('.' + totalClass).html()
            total = total.replaceAll('$', '')
            total = total.replaceAll(',', '')

            if(value > 0) {
                var obj = {}
                obj["name"] = name.replaceAll('"', '\"')
                obj["supplier"] = supplier
                obj["trade_type"] = trade_type
                obj["value"] = value
                obj["total"] = total
                obj["total_class"] = totalClass
                estimateData.push(obj)
            }
        })

        estimateData = JSON.stringify(estimateData)

        var form = new FormData();
        form.append("address", localStorage.getItem('current_address'));
        form.append("user_id", localStorage.getItem('userID'));
        form.append("id", id);
        form.append("name", estimateName);
        form.append("data", estimateData);
        form.append("location_id", location_id);
        form.append("commission", localStorage.getItem('targetSalesCommission'));
        form.append("commissionPercentage", localStorage.getItem('targetSalesCommissionPercentage'));
        form.append("discountPercentage", localStorage.getItem('discountPercentage'));
        form.append("targetSalesDiscount", localStorage.getItem('targetSalesDiscount'));
        form.append("tradetypeid", JSON.parse(localStorage.getItem('_cementSiding'))[0]['tradetypeid']);
        form.append("overhead", JSON.parse(localStorage.getItem('_overhead'))[0]['percent']);
        form.append("tax", localStorage.getItem('taxesAddedTSP'));
        form.append("totalEstimatedCost", localStorage.getItem('totalEstimatedCost'));
        form.append("nrd_or_credit", JSON.stringify(nrdCredit));

        var settings = {
            "url": "https://apro-cal.plego.cloud/api/save-estimate",
            "method": "POST",
            "timeout": 0,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": form
        };

        $.ajax(settings).done(function (response) {
            $('.msg-save').html('Estimation Saved Successfully.');
            localStorage.setItem('savingEstimate', 0);
        }).fail(function(data){
            $('.msg-save').html('NOT saved: Network Error.');
            localStorage.setItem('savingEstimate', 0);
        });

        /*
        var baseURL = "https://apro-cal.plego.cloud"
        $.ajax({
            url: baseURL + '/api/save-estimate/',
            type: "POST",
            cache: false,
            async: false,
            crossDomain: true,
            dataType: 'jsonp',
            data:{
                address: localStorage.getItem('current_address'),
                user_id: localStorage.getItem('userID'),
                id: id,
                name: estimateName,
                data: estimateData,
                location_id: location_id,
                commission: localStorage.getItem('targetSalesCommission'),
                commissionPercentage:  localStorage.getItem('targetSalesCommissionPercentage'),
                discountPercentage: localStorage.getItem('discountPercentage'),
                targetSalesDiscount: localStorage.getItem('targetSalesDiscount'),
                tradetypeid: JSON.parse(localStorage.getItem('_cementSiding'))[0]['tradetypeid'],
                overhead: JSON.parse(localStorage.getItem('_overhead'))[0]['percent'],
                tax: localStorage.getItem('taxesAddedTSP') //JSON.parse(localStorage.getItem('_taxRates'))[0]['tax'],
            },
            success: function(data){
                $('.msg-save').html('Estimation Saved Successfully.');
            },
            error: function(jqXHR, textStatus, errorThrown) {
                if(jqXHR.status == 200) {
                    $('.msg-save').html('Estimation Saved Successfully.');
                    return;
                }

                //$('.msg-save').html('NOT saved: Network Error.');
                $('.msg-save').html('Estimation Saved Successfully.');
            },
            complete: function(){
                localStorage.setItem('savingEstimate', 0);
            }
        })
        */
    }


    let handleBtnClear = (e) => {
        e.preventDefault()

        $('.compute-cost-roofing').each(function(){
            $(this).val('');
        })
        $('.compute-cost-cement').each(function(){
            $(this).val('');
        })
        $('.compute-cost-siding').each(function(){
            $(this).val('');
        })
        $('.compute-cost-gutter').each(function(){
            $(this).val('');
        })         

        $('.emptyVal').each(function(){
            $(this).html('');
        })  

        $('.rightAlign').each(function(){
            $(this).html('');
        })  
        
        $('.bxWidth-fx').each(function(){
            $(this).html('');
        })          
    }


    $(function() {

        $(".suggestions ul li").click(function(e){
            let valueTarget = e.target.innerHTML;
            let suggestionBox = document.querySelector('.suggestions ul');
            let el = document.querySelector('.suggestion-inp');
            el.value=valueTarget;
            suggestionBox.style.display="none";
        });
        $(".suggestions ul li").hover(function(){
            $(this).addClass('hoverActive');
        }, function(){
                $(this).removeClass('hoverActive');
        });
    })

    return (
        <div className="ameriproBk">
            <Header></Header>
            <div className="mid-cont">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-2">
                            {/* <SideNav getSelectedEvent={selectedEv}></SideNav> */}
                            <div className="navbar-vertical">
                                <ul>
                                    <li onClick={() => setState("roofing")} className={navBar == "roofing" ? 'active' : ""} >
                                        <a>
                                            <img src={require('../assets/images/roof.png')}></img>
                                            <span>Roofing</span>
                                        </a>
                                    </li>
                                    <li onClick={() => setState("cement")} className={navBar == "cement" ? 'active' : ""} >
                                        <a>
                                            <img src={require('../assets/images/cement.png')}></img>
                                            <span>Cement Siding</span>
                                        </a>
                                    </li>
                                    <li onClick={() => setState("vinyl")} className={navBar == "vinyl" ? 'active' : ""} >
                                        <a>
                                            <img src={require('../assets/images/vinyl.png')}></img>
                                            <span>Vinyl Siding</span>
                                        </a>
                                    </li>
                                    <li onClick={() => setState("gutters")} className={navBar == "gutters" ? 'active' : ""} >
                                        <a>
                                            <img src={require('../assets/images/gutters.png')}></img>
                                            <span>Gutters</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
						</div>
                        <div className="col-md-10">
                            <div className="right">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <SelectCalOpt></SelectCalOpt>
                                    </div>
                                    <div className="col-sm-4">
                                        <input id="txt-estimate-name" type="text" className="quantity form-control input-number estmInpt" width={"100%"} placeholder={"Estimation Name"}></input>
                                    </div>
                                    <div className="col-sm-2 div-btn-save">
                                        <button className="saveBtn" onClick={handleBtnSave}>Save</button>
                                    </div>

                                    <div className="col-sm-2 div-btn-save">
                                        <button className="saveBtn" onClick={handleBtnClear}>Clear</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <span className="msg-save success"></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7">
                                        <div className="suggestions mb-4">
                                            <input id="address" 
                                                type="text" 
                                                className="suggestion-inp form-control input-number estmInpt" 
                                                width={"100%"} 
                                                placeholder={"Address Field"}
                                                onKeyUp={e => setAddress(e.target.value)}
                                                onBlur={checkKeyWord}
                                                />
                                            <ul class="list-group">
                                                {/* <li class="list-group-item" aria-current="true">An active item</li>
                                                <li class="list-group-item">A second item</li>
                                                <li class="list-group-item">A third item</li>
                                                <li class="list-group-item">A fourth item</li>
                                                <li class="list-group-item">And a fifth one</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7">
                                            <div style={{display: navBar == "cement" ? 'block' : 'none' }}>
                                                <VinylSidingTable></VinylSidingTable>
                                            </div>
                                            <div style={{display: navBar == "vinyl" ? 'block' : 'none' }}>
                                                <CementSidingTable></CementSidingTable>
                                            </div>
                                            <div style={{display: navBar == "gutters" ? 'block' : 'none' }}>
                                                <GuttersTable></GuttersTable>
                                            </div>
                                            <div style={{display: navBar == "roofing" ? 'block' : 'none' }}>
                                                <RoofingTable></RoofingTable>
                                            </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <TotalEstimatedCost></TotalEstimatedCost>
                                    </div>
                                </div>
                                {/*
                                <div className="row">
                                    <div className="col-sm-12">
                                        <OverallCost></OverallCost>
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
        
        
        )
}

<script>
    
</script>

export default Home