import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link
} from "react-router-dom";

import Home from './components/Home';
import Login from './components/auth/Login.js';
import ViewEstimates from './components/ViewEstimates.js';

function App() {
  var userID = localStorage.getItem('userID')
  var userToken = localStorage.getItem('userToken')
  localStorage.setItem('savingEstimate', 0);
  if(userID === null || userToken === null || userID == "" || userToken == ""){
    if(window.location.pathname != "/login"){
      window.location.href = "/login"
    }
  }


  return (
    <Router>
        <Routes>
          <Route path="/"  element={<Home/>} ></Route>
          <Route path="/login"  element={<Login/>} ></Route>
          <Route path="/view-estimates"  element={<ViewEstimates/>} ></Route>
        </Routes>
    </Router>
  )
}

export default App;
