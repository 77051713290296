import React, { useEffect, useState } from "react"
import Logo from './../assets/images/logo.png'
import { Redirect } from "react-router-dom"
import { NavLink } from "react-router-dom";
import $ from 'jquery';


const Header = () =>  {

    function getUserData(){
        localStorage.setItem('taxAvgSidingCost', 0);
        localStorage.setItem('taxAvgDurationCost', 0);
        localStorage.setItem('finalDeliveryPickupFees', 0);
        localStorage.setItem('taxAvgDStormCost', 0);
        localStorage.setItem('targetSalesCommission', 0);

        localStorage.setItem('taxesAddedTSPA', 0);
        localStorage.setItem('finalDeliveryPickupFeesA', 0);
        localStorage.setItem('taxAvgSidingFFCost', 0);
        localStorage.setItem('totalEstimatedCost', 0);

        localStorage.setItem('price-roof', 0);
        localStorage.setItem('targetSalesPrice2', 0);
        localStorage.setItem('targetSalesPrice1', 0);
        localStorage.setItem('lowTargetSalesPrice_step1', 0);        
        
        localStorage.setItem('targetSalesDiscount', 0);
        localStorage.setItem('targetSalesCommission', 0);


        $('.targetSalesDiscount').html('');
        $('.targetSalesCommission').html('');

        $('#estimatedNetProfitAfterOverhead').html('');
        $('#netProfitAfterOverhead').html('');

        var settings = {
            "url": "https://aprocal.plego.cloud/buildfly_api/api/user",
            "method": "GET",
            "timeout": 0,
            "headers": {
              "Client-Service": "app-client",
              "Auth-Key": "AmeriPro App",
              "Content-Type": "application/json",
              "User-ID": localStorage.getItem('userID'),
              "Authorization": localStorage.getItem('userToken')
            },
        };
          
        $.ajax(settings).done(function (response) {
            var userName = response.fullname
            userName = userName.split(' ')[0]
            $('#user-name').html(userName)
            localStorage.setItem('user_data', JSON.stringify(response))
        }).fail(function(data){
            localStorage.clear()
            localStorage.setItem('userID', "")
            localStorage.setItem('userToken', "")
            window.location.href = "/login"
        });
    }

    useEffect(() => {
        getUserData()
    }, [])



    $(function(){
        if(localStorage.getItem('hard_reload') == 1){
            localStorage.setItem('hard_reload', 0);
            setTimeout(function () {
                window.location.reload();
              }, 3000)
        }

        $(document).on('click', '#menu-logout', function(e){
            e.preventDefault()
            var settings = {
                "url": "https://aprocal.plego.cloud/buildfly_api/api/logout",
                "method": "POST",
                "timeout": 0,
                "headers": {
                  "Client-Service": "app-client",
                  "Auth-Key": "AmeriPro App",
                  "Content-Type": "application/x-www-form-urlencoded",
                  "User-ID": localStorage.getItem('userID'),
                  "Authorization": localStorage.getItem('userToken')
                },
            };
            localStorage.clear();
            localStorage.setItem('userID', "")
            localStorage.setItem('userToken', "")
            localStorage.setItem('hard_reload', 1);
            $.ajax(settings).done(function (response) {
                window.location.href = "/login"
            }).fail(function(data){
                localStorage.setItem('userID', "")
                localStorage.setItem('userToken', "")
                window.location.href = "/login"
            });
        })
    })
    

    return (
        <div className="menu">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2">
                        <div className="left">
                            <a href="#">
                                <img width="100%" src={ Logo } alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-sm-10">
                        <div className="right">
                            <ul>
                                <li>
                                {/* <NavLink activeClassName="inactive">Hi Haris</NavLink> */}
                                <a>Hi <b id="user-name"></b>,</a>
                                <NavLink to="/" activeClassName="active">Pricing Calculator</NavLink>
                                <NavLink to="/view-estimates" activeClassName="active">View Estimates</NavLink>
                                <NavLink id="menu-logout" style={{borderBottom: "0px"}} >Logout</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header