import React, { useEffect, useState, useRef } from "react"
import $ from 'jquery';


const RoofingTable = () =>  {

    var roofPrice = 0;
    let laborPrice = [];
    let taxRates = [];
    let overhead = [];

    var roofOCOakridgePrice = 0;
    var roofDurationHDZPrice = 0;
    var roofDurationStormPrice = 0;
    var roofLowPitchRoofingPrice = 0;
    var roofPitch812 = 0;
    var roofPitch1112 = 0;
    var roofLayers = 0;
    var roofShake = 0;
    var roof2Story = 0;
    var roofChimneyFlashing = 0;
    var roofSkylightFlashing = 0;
    var roofPlywood = 0;

    var roofing25SQDurationStormCount = 0;
    var roofing25SQDurationStormABC = 0;
    var roofing25SQDurationStormBeacon = 0;
    var roofing25SQDurationStormMidwest = 0;

    var roofing25SQDurationCount = 0;
    var roofing25SQDurationABC = 0;
    var roofing25SQDurationBeacon = 0;
    var roofing25SQDurationMidwest = 0;

    var avgOakridgeCostCount = 0;
    var avgOakridgeCostABC = 0;
    var avgOakridgeCostBeacon = 0;
    var avgOakridgeCostMidwest = 0;

    var avgOakridgeCost = 0;
    var avgDurationCost = 0;
    var avgDStormCost = 0;
    var avgDurationHDZCost = 0;
    var avgPeelStickCapCost = 0;
    var avgPeelStickBaseCost = 0;

    var avgSidingCostSQ = 0;
    var avgSidingCostFFSQ = 0;
    var avgSidingWindowWrap = 0;
    var avgSidingT412SolidVSE = 0;
    var avgSidingTrimCoil0192450 = 0;

    var taxesAddedTSP = 0;
    var taxAvgOakridgeCost = 0;
    var taxAvgDurationCost = 0;
    var taxAvgDStormCost = 0;
    var taxAvgSidingCost = 0;
    var taxAvgSidingFFCost = 0;


    function resetValues(){        
        roofPrice = 0;
        
        roofOCOakridgePrice = 0;
        roofDurationHDZPrice = 0;
        roofDurationStormPrice = 0;
        roofLowPitchRoofingPrice = 0;
        roofPitch812 = 0;
        roofPitch1112 = 0;
        roofLayers = 0;
        roofShake = 0;
        roof2Story = 0;
        roofChimneyFlashing = 0;
        roofSkylightFlashing = 0;
        roofPlywood = 0;

        roofing25SQDurationStormCount = 0;
        roofing25SQDurationStormABC = 0;
        roofing25SQDurationStormBeacon = 0;
        roofing25SQDurationStormMidwest = 0;

        roofing25SQDurationCount = 0;
        roofing25SQDurationABC = 0;
        roofing25SQDurationBeacon = 0;
        roofing25SQDurationMidwest = 0;

        avgOakridgeCostCount = 0;
        avgOakridgeCostABC = 0;
        avgOakridgeCostBeacon = 0;
        avgOakridgeCostMidwest = 0;

        avgOakridgeCost = 0;
        avgDurationCost = 0;
        avgDStormCost = 0;
        avgDurationHDZCost = 0;
        avgPeelStickCapCost = 0;
        avgPeelStickBaseCost = 0;

        avgSidingCostSQ = 0;
        avgSidingCostFFSQ = 0;
        avgSidingWindowWrap = 0;
        avgSidingT412SolidVSE = 0;
        avgSidingTrimCoil0192450 = 0;

        taxesAddedTSP = 0;
        taxAvgOakridgeCost = 0;
        taxAvgDurationCost = 0;
        taxAvgDStormCost = 0;
        taxAvgSidingCost = 0;
        taxAvgSidingFFCost = 0;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function computeRoofingPrice(labor_type_id, location_id, name, val){
        if(name == 'OC Oakridge Arch*'){
            roofOCOakridgePrice = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofOCOakridgePrice += avgOakridgeCost * val;
            roofPrice += roofOCOakridgePrice;
        }else if(name == 'OC Duration Tru-Def*'){
            roofDurationHDZPrice = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofDurationHDZPrice += avgDurationHDZCost * val;
            roofPrice += roofDurationHDZPrice;
        }else if(name == 'Duration Storm'){
            roofDurationStormPrice = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofDurationStormPrice += avgDStormCost * val;
            roofPrice += roofDurationStormPrice;
        }else if(name == 'Low-Pitch Roofing'){
            roofLowPitchRoofingPrice = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofLowPitchRoofingPrice += avgPeelStickCapCost * val;
            roofLowPitchRoofingPrice += avgPeelStickBaseCost * val;
            roofPrice += roofLowPitchRoofingPrice;
        }else if(name == 'Pitch 8/12+'){
            roofPitch812 = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofPitch812;
        }else if(name == 'Pitch 11/12+'){
            roofPitch1112 = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofPitch1112;
        }else if(name == 'Layers'){
            roofLayers = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofLayers;
        }else if(name == 'Shake'){
            roofShake = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofShake;
        }else if(name == '2-Story'){
            roof2Story = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roof2Story;
        }else if(name == 'Chimney Flashing'){
            roofChimneyFlashing = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofChimneyFlashing;
        }else if(name == 'Skylight Flashing'){
            roofSkylightFlashing = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPrice += roofSkylightFlashing;
        }else if(name == 'Plywood'){		
            roofPlywood = 48 * val;
            roofPlywood += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            roofPlywood = roofPlywood * 3;
            roofPrice += roofPlywood;
        }
    }


    function RoofEstimatePricing25SQ(){
        avgOakridgeCostCount = 0;
        roofing25SQDurationCount = 0;
        roofing25SQDurationStormCount = 0;
        
        const items = JSON.parse(localStorage.getItem('_averageCostRoofing'));
        for (const item of items) {
            if(item.name == 'ABC 25SQ Duration Storm ABC') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration Storm Beacon') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration Storm Midwest') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormMidwest = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ ABC') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ Beacon') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ Midwest') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationMidwest = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge ABC') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge Beacon') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge Midwest') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostMidwest = item.avg_cost;
            }else if(item.name == 'Peel & Stick Cap') {
                avgPeelStickCapCost = item.avg_cost;
            }else if(item.name == 'Peel & Stick Base') {
                avgPeelStickBaseCost = item.avg_cost;
            }else if(item.name == 'avg siding cost sq') {
                avgSidingCostSQ = item.avg_cost;
            }else if(item.name == 'avg siding ff sq') {
                avgSidingCostFFSQ = item.avg_cost;
            }else if(item.name == 'siding window wrap') {
                avgSidingWindowWrap = item.avg_cost;
            }else if(item.name == 'T4 12" Solid/Vented Soffit Economy') {
                avgSidingT412SolidVSE = item.avg_cost;
            }else if(item.name == '.019 24X50 Trim Coil') {
                avgSidingTrimCoil0192450 = item.avg_cost;
            }
        }
        
        avgDStormCost = ((roofing25SQDurationStormABC + roofing25SQDurationStormBeacon + roofing25SQDurationStormMidwest) / roofing25SQDurationStormCount) /25;
        avgDurationHDZCost = ((roofing25SQDurationABC + roofing25SQDurationBeacon + roofing25SQDurationMidwest) / roofing25SQDurationCount) /25;
        avgOakridgeCost = ((avgOakridgeCostABC + avgOakridgeCostBeacon + avgOakridgeCostMidwest) / avgOakridgeCostCount) /25;
    }


    function ComputeCost(location_id, labor_type_id, val, name, trade_type) {
        var computationDiscount = $('#discountPercentage').val()
        if(computationDiscount == "" || computationDiscount < 0){ computationDiscount = 9 }
        computationDiscount = (100 - computationDiscount) / 100;

        const localData = JSON.parse(localStorage.getItem('_laborPrice'));
        for (const item of localData) {
            if(item.locationid == location_id && item.labortypeid == labor_type_id){
                laborPrice[labor_type_id + '-' + location_id + '-' + name] = item.price;
                computeRoofingPrice(labor_type_id, location_id, name, val);

                if(roofOCOakridgePrice > 0) { $('.roofOCOakridgePrice').html('$' + numberWithCommas(Number(roofOCOakridgePrice * computationDiscount * 2.2).toFixed(2))); $('.roofOCOakridgePrice_org').html('$' + numberWithCommas(Number(roofOCOakridgePrice).toFixed(2))); } else { $('.roofOCOakridgePrice').html(''); $('.roofOCOakridgePrice_org').html(''); }
                if(roofDurationHDZPrice > 0) { $('.roofDurationHDZPrice').html('$' + numberWithCommas(Number(roofDurationHDZPrice * computationDiscount * 2.2).toFixed(2))); $('.roofDurationHDZPrice_org').html('$' + numberWithCommas(Number(roofDurationHDZPrice).toFixed(2))); } else { $('.roofDurationHDZPrice').html(''); $('.roofDurationHDZPrice_org').html(''); }
                if(roofDurationStormPrice > 0) { $('.roofDurationStormPrice').html('$' + numberWithCommas(Number(roofDurationStormPrice * computationDiscount * 2.2).toFixed(2))); $('.roofDurationStormPrice_org').html('$' + numberWithCommas(Number(roofDurationStormPrice).toFixed(2))); } else { $('.roofDurationStormPrice').html(''); $('.roofDurationStormPrice_org').html(''); }
                if(roofLowPitchRoofingPrice > 0) { $('.roofLowPitchRoofingPrice').html('$' + numberWithCommas(Number(roofLowPitchRoofingPrice * computationDiscount * 2.2).toFixed(2))); $('.roofLowPitchRoofingPrice_org').html('$' + numberWithCommas(Number(roofLowPitchRoofingPrice).toFixed(2))); } else { $('.roofLowPitchRoofingPrice').html(''); $('.roofLowPitchRoofingPrice_org').html(''); }
                if(roofPitch812 > 0) { $('.roofPitch812').html('$' + numberWithCommas(Number(roofPitch812 * computationDiscount * 2.2).toFixed(2)));  $('.roofPitch812_org').html('$' + numberWithCommas(Number(roofPitch812).toFixed(2))); } else { $('.roofPitch812').html(''); $('.roofPitch812_org').html(''); }
                if(roofPitch1112 > 0) { $('.roofPitch1112').html('$' + numberWithCommas(Number(roofPitch1112 * computationDiscount * 2.2).toFixed(2))); $('.roofPitch1112_org').html('$' + numberWithCommas(Number(roofPitch1112).toFixed(2))); } else { $('.roofPitch1112').html(''); $('.roofPitch1112_org').html(''); }
                if(roofLayers > 0) { $('.roofLayers').html('$' + numberWithCommas(Number(roofLayers * computationDiscount * 2.2).toFixed(2))); $('.roofLayers_org').html('$' + numberWithCommas(Number(roofLayers).toFixed(2))); } else { $('.roofLayers').html(''); $('.roofLayers_org').html(''); }
                if(roofShake > 0) { $('.roofShake').html('$' + numberWithCommas(Number(roofShake * computationDiscount * 2.2).toFixed(2))); $('.roofShake_org').html('$' + numberWithCommas(Number(roofShake).toFixed(2))); } else { $('.roofShake').html(''); $('.roofShake_org').html(''); }
                if(roof2Story > 0) { $('.roof2Story').html('$' + numberWithCommas(Number(roof2Story * computationDiscount * 2.2).toFixed(2))); $('.roof2Story_org').html('$' + numberWithCommas(Number(roof2Story).toFixed(2))); } else { $('.roof2Story').html(''); $('.roof2Story_org').html(''); }
                if(roofChimneyFlashing > 0) { $('.roofChimneyFlashing').html('$' + numberWithCommas(Number(roofChimneyFlashing * computationDiscount * 2.2).toFixed(2))); $('.roofChimneyFlashing_org').html('$' + numberWithCommas(Number(roofChimneyFlashing).toFixed(2))); } else { $('.roofChimneyFlashing').html(''); $('.roofChimneyFlashing_org').html(''); }
                if(roofSkylightFlashing > 0) { $('.roofSkylightFlashing').html('$' + numberWithCommas(Number(roofSkylightFlashing * computationDiscount * 2.2).toFixed(2))); $('.roofSkylightFlashing_org').html('$' + numberWithCommas(Number(roofSkylightFlashing).toFixed(2))); } else { $('.roofSkylightFlashing').html(''); $('.roofSkylightFlashing_org').html(''); }
                if(roofPlywood > 0) { $('.roofPlywood').html('$' + numberWithCommas(Number(roofPlywood * computationDiscount * 2.2).toFixed(2))); $('.roofPlywood_org').html('$' + numberWithCommas(Number(roofPlywood).toFixed(2))); } else { $('.roofPlywood').html(''); $('.roofPlywood_org').html(''); }
                
                
                $('#price-roof').html('$' + numberWithCommas(Number(roofPrice * computationDiscount * 2.2).toFixed(2)));
                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#price-roof-org').html('$' + numberWithCommas(Number(roofPrice).toFixed(2)));
                }else{
                    $('#price-roof-org').html('');
                }

                var tmpFinalDPF = localStorage.getItem('finalDeliveryPickupFees');
                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#finalDeliveryPickupFees-disc').html('$' + numberWithCommas(Number(tmpFinalDPF).toFixed(2)));
                }else{
                    $('#finalDeliveryPickupFees-disc').html('');
                }

                var tmpTaxesATSP = localStorage.getItem('taxesAddedTSP', taxesAddedTSP);
                if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                    $('#taxesAddedTSP-disc').html('$' + numberWithCommas(Number(tmpTaxesATSP).toFixed(2)));
                }else{
                    $('#taxesAddedTSP-disc').html('');
                }

                var tmpTotalEstimatedCost = ( Number(localStorage.getItem('totalEstimatedCost')) - Number(localStorage.getItem('finalDeliveryPickupFees')) - Number(localStorage.getItem('taxesAddedTSP')) );
                //tmpTotalEstimatedCost = ( (tmpTotalEstimatedCost * computationDiscount * 2.2) + Number(localStorage.getItem('finalDeliveryPickupFees')) + Number(localStorage.getItem('taxesAddedTSP')) );
                tmpTotalEstimatedCost = (tmpTotalEstimatedCost * computationDiscount * 2.2);
                $('#TotalEstimatedCost-disc').html('$' + numberWithCommas(Number(tmpTotalEstimatedCost).toFixed(2)));
                localStorage.setItem('TotalEstimatedCost-disc', tmpTotalEstimatedCost);

                
                localStorage.setItem('price-roof', JSON.stringify(roofPrice));
            }
        }
    }


    function startComputation(){
        resetValues();
        RoofEstimatePricing25SQ()
        $('.compute-cost-roofing').each(function(){
            var name = $(this).attr('data-name');
            var labor_type_id = $(this).attr('data-laborType');
            var trade_type = $(this).attr('data-tradeType');
            var location_id = $('#locations option:selected').val();
            var value = $(this).val();

            if(name == "No Of Salesman"){
                if(value > 0){
                    var foundThreshold = false;
                    const _salesCommission = JSON.parse(localStorage.getItem('_salesCommission'))
                    for (const item of _salesCommission) {
                        if(item.locationid == location_id && value <= item.threshold && !foundThreshold){
                            var salesCost = item.percent / 100;
                            salesCost *= $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val();
                            $('#salesCost').html('$' + numberWithCommas(Number(salesCost).toFixed(2)));
                            foundThreshold = true;
                        }
                    }
                }
            }else{
                if(value > 0) {
                    ComputeCost(location_id, labor_type_id, value, name, trade_type);
                }
            }
        });	
    }

    function clearValues(){
        $('.roofOCOakridgePrice').html('')
        $('.roofDurationHDZPrice').html('')
        $('.roofDurationStormPrice').html('')
        $('.roofLowPitchRoofingPrice').html('')
        $('.roofPitch812').html('')
        $('.roofPitch1112').html('')
        $('.roofLayers').html('')
        $('.roofShake').html('')
        $('.roof2Story').html('')
        $('.roofChimneyFlashing').html('')
        $('.roofSkylightFlashing').html('')
        $('.roofPlywood').html('')

        $('#price-roof').html('$0.00')
        localStorage.setItem('price-roof', 0)

        $('#BSANRDOverhead').html('')
        $('#BSANRDCredit').html('')
        $('#absoluteLowest').html('')
        $('#finalDeliveryPickupFees').html('')
        $('#taxesAddedTSP').html('')

        var taxesAddedTSP = 0;
        localStorage.setItem('taxAvgOakridgeCost', 0);
        localStorage.setItem('taxAvgDurationCost', 0);
        localStorage.setItem('taxAvgDStormCost', 0);
        localStorage.setItem('taxAvgSidingCost', 0);
        localStorage.setItem('taxAvgSidingFFCost', 0);
    }


    
    function totalEstimatedComputation(){
        RoofEstimatePricing25SQ()
        var tmpVal = ""

        var location_id = $('#locations option:selected').val();

        var _taxRates = JSON.parse(localStorage.getItem('_taxRates'))
        for (const item of _taxRates) {
            taxRates[item.locationid] = item.tax;
        }
        var _overhead = JSON.parse(localStorage.getItem('_overhead'))
        for (const item of _overhead) {
            overhead[item.locationid] = item.percent;
        }
        
        


        var taxesAddedTSP = 0;
        if($('input[data-name="OC Oakridge Arch*"]').val() > 0){
            taxAvgOakridgeCost = (avgOakridgeCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="OC Oakridge Arch*"]').value))
        }
        if($('input[data-name="OC Duration Tru-Def*"]').val() > 0){
            taxAvgDurationCost = (avgDurationHDZCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="OC Duration Tru-Def*"]').value))
        }
        if($('input[data-name="Duration Storm"]').val() > 0){
            taxAvgDStormCost = (avgDStormCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Duration Storm"]').value))
        }
        if($('input[data-name="Tear-Off SQ"]').val() > 0){
            taxAvgSidingCost = (avgSidingCostSQ * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Tear-Off SQ"]').value))
        }
        if($('input[data-name="Install SQ"]').val() > 0){
            taxAvgSidingFFCost = (avgSidingCostFFSQ * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Install SQ"]').value))
        }
        
        
        taxesAddedTSP = 0;
        var taxesAddedTSPA = taxAvgOakridgeCost + taxAvgDurationCost + taxAvgDStormCost + taxAvgSidingCost + taxAvgSidingFFCost;   
        
        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#taxesAddedTSP').html('');
                $('#taxesAddedTSPA').html('');
        }else{
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                $('#taxesAddedTSP').html('$' + numberWithCommas(Number(taxesAddedTSP).toFixed(2)));
                $('#taxesAddedTSPA').html('$' + numberWithCommas(Number(taxesAddedTSPA).toFixed(2)));
            }else{
                $('#taxesAddedTSP').html('');
                $('#taxesAddedTSPA').html('');
            }
        }
        localStorage.setItem('taxesAddedTSP', taxesAddedTSP);
        localStorage.setItem('taxesAddedTSPA', taxesAddedTSPA);


        var TotalEstimatedCost = Number(localStorage.getItem('price-roof'))
        TotalEstimatedCost = (TotalEstimatedCost + Number(localStorage.getItem('price-siding')))
        TotalEstimatedCost = (TotalEstimatedCost + Number(localStorage.getItem('price-gutter')))

        var finalDeliveryPickupFees = 0;
        var finalDeliveryPickupFeesA = 0;
        if(($('input[data-name="OC Oakridge Arch*"]').val() +
            $('input[data-name="OC Duration Tru-Def*"]').val() +
            $('input[data-name="Duration Storm"]').val() + 
            $('input[data-name="Low-Pitch Roofing"]').val()) > 0){
                finalDeliveryPickupFeesA += 75;
                finalDeliveryPickupFees = 0;
        }
        if(($('input[data-name="Tear-Off SQ"]').val() +
            $('input[data-name="Install SQ"]').val() +
            $('input[data-name="S Window Wrap"]').val() + 
            $('input[data-name="Door Wrap"]').val() +
            $('input[data-name="Soffit"]').val() +
            $('input[data-name="Tear-Off Sof/Fas"]').val() +
            $('input[data-name="Fascia"]').val()) > 0){
                finalDeliveryPickupFeesA += 75;				
                finalDeliveryPickupFees = 0;
        }
        

        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#finalDeliveryPickupFees').html('');
                $('#finalDeliveryPickupFeesA').html('');
        }else{
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                $('#finalDeliveryPickupFees').html('$' + numberWithCommas(Number(finalDeliveryPickupFees).toFixed(2)));
                $('#finalDeliveryPickupFeesA').html('$' + numberWithCommas(Number(finalDeliveryPickupFeesA).toFixed(2)));
            }else{
                $('#finalDeliveryPickupFees').html('');
                $('#finalDeliveryPickupFeesA').html('');
            }
        }
        localStorage.setItem('finalDeliveryPickupFees', finalDeliveryPickupFees);
        localStorage.setItem('finalDeliveryPickupFeesA', finalDeliveryPickupFeesA);
        TotalEstimatedCost = (TotalEstimatedCost + taxesAddedTSP)
        TotalEstimatedCost = (TotalEstimatedCost + finalDeliveryPickupFees)

        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#TotalEstimatedCost').html('');
        }else{       
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                    document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                    document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                        $('#TotalEstimatedCost').html('');
                    }else{
                        //$('#TotalEstimatedCost').html('$' + numberWithCommas(Number(TotalEstimatedCost).toFixed(2)));
                        $('#TotalEstimatedCost').html('$' + numberWithCommas((Number(TotalEstimatedCost) + Number(finalDeliveryPickupFeesA) + Number(taxesAddedTSPA)).toFixed(2)));
                    }
            }else{
                $('#TotalEstimatedCost').html('');
            }
        }
        localStorage.setItem('totalEstimatedCost', TotalEstimatedCost)


        
        var targetSalesPrice = ((TotalEstimatedCost * 2) * 1.1)
        localStorage.setItem('targetSalesPrice2', targetSalesPrice);
        if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
            if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#targetSalesPrice').html('');
                }else{
                    $('#targetSalesPrice').html('$' + numberWithCommas(Number(targetSalesPrice).toFixed(2)));
                }
        }else{
            $('#targetSalesPrice').html('');
        }
        $('.targetSalesPrice').html('$' + numberWithCommas(Number(targetSalesPrice).toFixed(2)));
        
        var numberTEN = ((TotalEstimatedCost * 2) * 1.1) * 0.9;
        $('#numberTEN').html('$' + numberWithCommas(Number(numberTEN).toFixed(2)));
        
        
        var BSANRDCredit = $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val();
        $('#BSANRDCredit').html('$' + numberWithCommas(Number(BSANRDCredit).toFixed(2)));

        var currentRevenue = BSANRDCredit + Number($('input[data-name="Agreed Retail Amounts"]').val());
        $('#currentRevenue').html('$' + numberWithCommas(Number(currentRevenue).toFixed(2)));
        
        var BSANRDOverhead = BSANRDCredit;
        BSANRDOverhead *= overhead[location_id] / 100;
        BSANRDOverhead += TotalEstimatedCost;
        $('#BSANRDOverhead').html('$' + numberWithCommas(Number(BSANRDOverhead).toFixed(2)));
        
        var absoluteLowest = targetSalesPrice * 0.55
        $('#absoluteLowest').html('$' + numberWithCommas(Number(absoluteLowest).toFixed(2)));

        var lowTargetSalesPrice = (TotalEstimatedCost -finalDeliveryPickupFees - taxesAddedTSP)* 1.6364
        localStorage.setItem('targetSalesPrice1', lowTargetSalesPrice)
        var lowTargetSalesPrice_step1 = lowTargetSalesPrice * 0.9
        if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
            if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#lowTargetSalesPrice').html('');
                }else{
                    $('#lowTargetSalesPrice').html('$' + numberWithCommas(Number(lowTargetSalesPrice_step1).toFixed(2)));
                }
        }else{
            $('#lowTargetSalesPrice').html('');
        }
        $('.lowTargetSalesPrice').html('$' + numberWithCommas(Number(lowTargetSalesPrice_step1).toFixed(2)));

        var additionalDollarRequired = "N/A"
        if((lowTargetSalesPrice_step1 - currentRevenue) > 0){
            additionalDollarRequired = (lowTargetSalesPrice_step1 - currentRevenue)
            $('#additionalDollarRequired').html('$' + numberWithCommas(Number(additionalDollarRequired).toFixed(2)));
        }else{
            $('#additionalDollarRequired').html(additionalDollarRequired);
        }
        if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#additionalDollarRequired').html('');
        }


        var overheadCost = currentRevenue * 0.22
        $('#overheadCost').html('$' + numberWithCommas(Number(overheadCost).toFixed(2)));

        var salesCommissionCost = currentRevenue * 0.12
        $('#salesCommissionCost').html('$' + numberWithCommas(Number(salesCommissionCost).toFixed(2)));

        var estimatedNetProfitAfterOverhead = currentRevenue - (overheadCost + salesCommissionCost) - TotalEstimatedCost
        if(estimatedNetProfitAfterOverhead < 0) { tmpVal = '$ (' + numberWithCommas(Number(estimatedNetProfitAfterOverhead).toFixed(2)) + ')'
        } else { tmpVal = '$' + numberWithCommas(Number(estimatedNetProfitAfterOverhead).toFixed(2)) }
        $('#estimatedNetProfitAfterOverhead').html(tmpVal);

        var netProfitAfterOverhead = estimatedNetProfitAfterOverhead / TotalEstimatedCost * 100
        $('#netProfitAfterOverhead').html(numberWithCommas(Number(netProfitAfterOverhead).toFixed(2)) + '%');

        
        var estimatedNetProfit = $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val() - BSANRDOverhead;
        if(estimatedNetProfit < 0) {
            $('#estimatedNetProfit').html('$(' + numberWithCommas(Number((estimatedNetProfit * -1)).toFixed(2)) + ')');
        }else{
            $('#estimatedNetProfit').html('$' + numberWithCommas(Number(estimatedNetProfit).toFixed(2)));
        }
        
        var awayFromTarget = 1 - (BSANRDCredit / targetSalesPrice);
        awayFromTarget = awayFromTarget * 100;
        $('#awayFromTarget').html(Number(awayFromTarget).toFixed(1) + '%');
        
        
        var additionalRequiredAmount = 0;
        if(awayFromTarget < 45){
            additionalRequiredAmount = "N/A";
            $('#estimatedNetProfit').html(additionalRequiredAmount);
        }else{
            additionalRequiredAmount = absoluteLowest - ($('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val());
            $('#additionalRequiredAmount').html('$' + numberWithCommas(Number(additionalRequiredAmount).toFixed(2)));
        }


        var targetSalesDiscount = (Number(localStorage.getItem('targetSalesPrice2')) - Number(lowTargetSalesPrice_step1))
        $('.targetSalesDiscount').html('$' + numberWithCommas(Number(targetSalesDiscount).toFixed(2)))
        localStorage.setItem('targetSalesDiscount', targetSalesDiscount);
        var val3 = (Number(localStorage.getItem('targetSalesPrice2')) - Number(lowTargetSalesPrice_step1)) / Number(localStorage.getItem('targetSalesPrice2')) * 100
        var commission = 0
        var median = (Number(localStorage.getItem('targetSalesPrice2')) + (Number(localStorage.getItem('targetSalesPrice1')) * 0.9)) / 2;
         var medianDiscount = (lowTargetSalesPrice_step1 - median) / median * 100;
         if(medianDiscount > 19.9 && medianDiscount <= 40.9) { commission = 11 / 100 } else
            if(medianDiscount > 9.9 && medianDiscount <= 19.9) { commission = 10 / 100 } else
            if(medianDiscount > -1.9 && medianDiscount <= 9.9) { commission = 9 / 100 } else
            if(medianDiscount > -9.9 && medianDiscount <= -1.9) { commission = 7 / 100 } else
            if(medianDiscount > -15.9 && medianDiscount <= -9.9) { commission = 5 / 100 } else
            if(medianDiscount > -16.9 && medianDiscount <= -15.9) { commission = 3 / 100 } else
            if(medianDiscount > -25.9 && medianDiscount <= -16.9) { commission = 2 / 100 } else
            { commission = 0 }
         /*
         if(val3 > -1 && val3 <= 5.9) { commission = 12 / 100 } else
         if(val3 > 5.9 && val3 <= 10.9) { commission = 9.5 / 100 } else
         if(val3 > 10.9 && val3 <= 15.9) { commission = 8.5 / 100 } else
         if(val3 > 15.9 && val3 <= 20.9) { commission = 7 / 100 } else
         if(val3 > 20.9 && val3 <= 30.9) { commission = 5 / 100 } else
         if(val3 > 30.9 && val3 <= 40.9) { commission = 2.5 / 100 } else { commission = 0 }
         */
        var targetSalesCommission = Number(lowTargetSalesPrice_step1) * commission
        localStorage.setItem('targetSalesCommissionPercentage', (commission * 100));
        localStorage.setItem('targetSalesCommission', targetSalesCommission);
        $('.targetSalesCommission').html('$' + numberWithCommas(Number(targetSalesCommission).toFixed(2)));

        /***** Computation of default Discount - Begin *****/
        var inputDiscount = $('#discountPercentage').val()
        if(inputDiscount == "" || inputDiscount < 0){
            inputDiscount = 9
        }
        $('#discountPercentage').val(inputDiscount).trigger("input")
        /***** Computation of default Discount - End *****/
    }


    $(function() {  
        startComputation()
        totalEstimatedComputation()        
        $(document).on('input', '.compute-cost-roofing', function(e){
            startComputation()
            totalEstimatedComputation()
        })

        
        $(document).on('change', '#locations', function(e){
            clearValues()
            startComputation()
            totalEstimatedComputation()
        })
        
    })

    return (                    
        <table className="table table-bordered table-striped table-hover PriceInput-Bx centerPC">
            <thead className="tableHead">
                <tr>
                    <th >Roofing</th>
                    <th ></th>
                    <th className="wd-20">Quantity</th>
                    <th className="wd-220">Price</th>
                    <th className="wd-220 admin-content">Cost</th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>OC Oakridge / GAF NS</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                        <td><input data-laborType="1"  data-tradeType="1" data-name="OC Oakridge Arch*" data-total="roofOCOakridgePrice" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofOCOakridgePrice emptyVal"></td>
                <td className="roofOCOakridgePrice_org emptyVal admin-content"></td>
            </tr>
            <tr>
                <td>Duration / HDZ</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="1"  data-tradeType="1" data-name="OC Duration Tru-Def*" data-total="roofDurationHDZPrice" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofDurationHDZPrice emptyVal" ></td>
                <td className="roofDurationHDZPrice_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Duration Storm</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                        <td><input data-laborType="1"  data-tradeType="1" data-name="Duration Storm" data-total="roofDurationStormPrice" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofDurationStormPrice emptyVal" ></td>
                <td className="roofDurationStormPrice_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Low-Pitch Roofing</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="2"  data-tradeType="1" data-name="Low-Pitch Roofing" data-total="roofLowPitchRoofingPrice" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofLowPitchRoofingPrice emptyVal" ></td>
                <td className="roofLowPitchRoofingPrice_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Pitch 8/12+</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="5"  data-tradeType="1" data-name="Pitch 8/12+" data-total="roofPitch812" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofPitch812 emptyVal" ></td>
                <td className="roofPitch812_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Pitch 11/12+</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="6"  data-tradeType="1" data-name="Pitch 11/12+" data-total="roofPitch1112" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofPitch1112 emptyVal" ></td>
                <td className="roofPitch1112_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Add Layer</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="3"  data-tradeType="1" data-name="Layers" data-total="roofLayers" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofLayers emptyVal" ></td>
                <td className="roofLayers_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Shake Layer</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="4"  data-tradeType="1" data-name="Shake" type="text" data-total="roofShake" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofShake emptyVal" ></td>
                <td className="roofShake_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>2nd Story</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="7"  data-tradeType="1" data-name="2-Story" type="text" data-total="roof2Story" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roof2Story emptyVal" ></td>
                <td className="roof2Story_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Chimney Flashing</td>
                <td>EA</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="10"  data-tradeType="1" data-name="Chimney Flashing" data-total="roofChimneyFlashing" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofChimneyFlashing emptyVal" ></td>
                <td className="roofChimneyFlashing_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Skylight Flashing</td>
                <td>EA</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="11"  data-tradeType="1" data-name="Skylight Flashing" data-total="roofSkylightFlashing" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofSkylightFlashing emptyVal" ></td>
                <td className="roofSkylightFlashing_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Decking</td>
                <td>SQ</td>
                <td>
                    <div className="input-group inpuBx-val">
                    <td><input data-laborType="8"  data-tradeType="1" data-name="Plywood" data-total="roofPlywood" type="text" className="compute-cost-roofing quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="roofPlywood emptyVal" ></td>
                <td className="roofPlywood_org emptyVal admin-content" ></td>
            </tr>
            </tbody>
        </table>
    )
}

export default RoofingTable