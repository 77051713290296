import React, { useEffect, Component, useState  } from "react"
import $ from 'jquery';

import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { useNavigate } from "react-router-dom";

 
class YourEstimates extends Component {
    
  render() {
    
    var inProcess = false;
    var baseURL = "https://apro-cal.plego.cloud";

    var paginationPerPage = 10
    var paginationPage = 1
    var paginationTotalRecords = 0
    var paginationTotalPages = 0
    var searchText = ""

    function setPagination(data){
        var html = '';
        html += '<li class="page-item page-previous"><a class="page-link" href="#">Previous</a></li>'
        for(var i=1; i<=paginationTotalPages; i++){
            if(paginationPage == i){
                html += '<li class="page-item active page-num" data-page="' + i + '"><a class="page-link" href="#">' + i + '</a></li>'
            }else{
                html += '<li class="page-item page-num" data-page="' + i + '"><a class="page-link" href="#">' + i + '</a></li>'
            }
        }
        html += '<li class="page-item page-next"><a class="page-link" href="#">Next</a></li>'
        $('#estimate-pagination').html(html)
    }

    function setDataTable(data){
        var html = '';
        var i = (paginationPage * paginationPerPage) - paginationPerPage + 1;
        for (let item of data){
            html += '<tr>'
            html += '<td>' + i + '</td>'
            html += '<td>' + item.name + '</td>'
            html += '<td>' + item.dt + '</td>'
            html += '<td>'
            html += '<button data-id="'+ item.id+'" data-name="'+ item.name+'" class="actions btn-edit"><img src=' + require("../assets/images/edit.png") + '></img></button>'
            html += '<button data-id="'+ item.id+'" data-name="'+ item.name+'" class="actions btn-view"><img src=' + require("../assets/images/viewIcon.png") + '></img></button>'
            html += '<button data-id="'+ item.id+'" data-name="'+ item.name+'" class="actions btn-recycle"><img src=' + require("../assets/images/recycle.png") + '></img></button>'
            //html += '<button data-id="'+ item.id+'" data-name="'+ item.name+'" class="ShareTo btn-share">Share To</button>'
            html += '</td>'
            html += '</tr>'
            i++
        }
        $('#table-saved-estiamte').html(html)
        setPagination(data)

        $(document).on('click', '.btn-edit', function(e){
            var id = $(this).attr('data-id')
            var url = "/?id="+id+"&mode=edit"
            window.location.href = url
        })
        $(document).on('click', '.btn-view', function(e){
            var id = $(this).attr('data-id')
            var url = "/?id="+id+"&mode=view"
            window.location.href = url
        })
        $(document).on('click', '.btn-recycle', function(e){
            if(inProcess) { return; }
            inProcess = true;
            var id = $(this).attr('data-id')
            var name = $(this).attr('data-name')
            if (window.confirm("Are you sure you want to delete? " + name) ) {
                $.ajax({
                    url: baseURL + "/api/delete-estimate/" + id,
                    type: "GET",
                    success: function(data){
                        inProcess = false
                        window.location.reload()
                    },
                    error: function(error){
                        inProcess = false
                        console.log(error)
                    }
                })
            }
        })
        $(document).on('click', '.btn-share', function(e){
            var id = $(this).attr('data-id')
            var url = "/?id="+id+"&mode=share"
            window.location.href = url
        })

        $(document).on('click', '.page-num', function(e){
            e.preventDefault()
            var pageNum = $(this).data('page')
            paginationPage = pageNum
            fetchData(paginationPage)
        })
        $(document).on('click', '.page-next', function(e){
            e.preventDefault()
            if(inProcess) { return; }
            paginationPage++
            if(paginationPage >= paginationTotalPages) {
                paginationPage = paginationTotalPages
            }
            fetchData(paginationPage)
        })
        $(document).on('click', '.page-previous', function(e){
            e.preventDefault()
            if(inProcess) { return; }
            paginationPage--
            if(paginationPage <= 1) {
                paginationPage = 1
            }
            fetchData(paginationPage)
        })

        $(document).on('input', '#search-text', function(e){
            e.preventDefault()
            if(inProcess) { return; }
            var text = $(this).val()
            searchText = text
            paginationPage = 1
            fetchData(paginationPage)
        })
    }

    function fetchData(page = 1){
        if(inProcess) { return; }

        inProcess = true
        var settings = {
            "url": "https://aprocal.plego.cloud/buildfly_api/api/estimates",
            "method": "GET",
            "timeout": 0,
            "headers": {
              "Client-Service": "app-client",
              "Auth-Key": "AmeriPro App",
              "Content-Type": "application/x-www-form-urlencoded",
              "User-ID": localStorage.getItem('userID'),
              "Authorization": localStorage.getItem('userToken'),
              "page": page,
              "records": paginationPerPage,
              "text": searchText
            },
        };
          
        $.ajax(settings).done(function (response) {
            paginationTotalRecords = response.total
            paginationTotalPages = Math.ceil(response.total/paginationPerPage)
            setDataTable(response.data)
            inProcess = false
        }).fail(function(data){
            inProcess = false
            console.log(data)
        })
    }

    $(function() {
        fetchData()
    })


    return (
        <div>
            <div className="row">
                <div className="col-5">
                    <input type="text" placeholder="Search" id="search-text" className="mt-3 mb-4 compute-cost-roofing quantity form-control input-number"></input>
                </div>
            </div>
            
            <table className="table table-bordered table-striped table-hover PriceInputtow-Bx">
                <thead className="tableHead">
                    <tr>
                        <th>S.No</th>
                        <th>Title</th>
                        <th>Created On</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody id="table-saved-estiamte">                		
                </tbody>
            </table>

            <nav aria-label="Page navigation example">
                <ul class="pagination" id="estimate-pagination">
                </ul>
            </nav>

        </div>
        
        
      
    )
  }
}

export default YourEstimates