import React, { useEffect, useState } from "react"
import $ from 'jquery';


const VinylSidingTable = () =>  {

    var sidingPrice = 0;
    let laborPrice = [];
    let taxRates = [];
    let overhead = [];

    var sidingTIWithFanfold = 0;
    var sidingTIWithOutFanfold = 0;
    var sidingGableVent = 0;
    var sidingWindowWrapSmall = 0;
    var sidingWindowWrapMedium = 0;
    var sidingWindowWrapLarge = 0;
    var sidingGarageDoorWrap = 0;
    var sidingDoorWrap = 0;
    var sidingSoffit12 = 0;
    var sidingSoffit24 = 0;
    var sidingFascia = 0;

    var roofing25SQDurationStormCount = 0;
    var roofing25SQDurationStormABC = 0;
    var roofing25SQDurationStormBeacon = 0;
    var roofing25SQDurationStormMidwest = 0;

    var roofing25SQDurationCount = 0;
    var roofing25SQDurationABC = 0;
    var roofing25SQDurationBeacon = 0;
    var roofing25SQDurationMidwest = 0;

    var avgOakridgeCostCount = 0;
    var avgOakridgeCostABC = 0;
    var avgOakridgeCostBeacon = 0;
    var avgOakridgeCostMidwest = 0;

    var avgOakridgeCost = 0;
    var avgDurationCost = 0;
    var avgDStormCost = 0;
    var avgDurationHDZCost = 0;
    var avgPeelStickCapCost = 0;
    var avgPeelStickBaseCost = 0;

    var avgSidingCostSQ = 0;
    var avgSidingCostFFSQ = 0;
    var avgSidingTrimCoil0192450 = 0;
    var avgSidingT412SolidVSE = 0;
    var avgSidingWindowWrap = 0;

    var taxesAddedTSP = 0;
    var taxAvgOakridgeCost = 0;
    var taxAvgDurationCost = 0;
    var taxAvgDStormCost = 0;
    var taxAvgSidingCost = 0;
    var taxAvgSidingFFCost = 0;

    var Housewrap3x165 = 0;
    var HousewrapTape = 0;


    function resetValues(){        
        sidingPrice = 0;
        
        sidingTIWithFanfold = 0;
        sidingTIWithOutFanfold = 0;
        sidingGableVent = 0;
        sidingWindowWrapSmall = 0;
        sidingWindowWrapMedium = 0;
        sidingWindowWrapLarge = 0;
        sidingGarageDoorWrap = 0;
        sidingDoorWrap = 0;
        sidingSoffit12 = 0;
        sidingSoffit24 = 0;
        sidingFascia = 0;

        roofing25SQDurationStormCount = 0;
        roofing25SQDurationStormABC = 0;
        roofing25SQDurationStormBeacon = 0;
        roofing25SQDurationStormMidwest = 0;

        roofing25SQDurationCount = 0;
        roofing25SQDurationABC = 0;
        roofing25SQDurationBeacon = 0;
        roofing25SQDurationMidwest = 0;

        avgOakridgeCostCount = 0;
        avgOakridgeCostABC = 0;
        avgOakridgeCostBeacon = 0;
        avgOakridgeCostMidwest = 0;

        avgOakridgeCost = 0;
        avgDurationCost = 0;
        avgDStormCost = 0;
        avgDurationHDZCost = 0;
        avgPeelStickCapCost = 0;
        avgPeelStickBaseCost = 0;

        avgSidingCostSQ = 0;
        avgSidingCostFFSQ = 0;
        avgSidingTrimCoil0192450 = 0;
        avgSidingT412SolidVSE = 0;
        avgSidingWindowWrap = 0;

        taxesAddedTSP = 0;
        taxAvgOakridgeCost = 0;
        taxAvgDurationCost = 0;
        taxAvgDStormCost = 0;
        taxAvgSidingCost = 0;
        taxAvgSidingFFCost = 0;

        Housewrap3x165 = 0;
        HousewrapTape = 0;

        $('.hardie1').html('');
        $('.hardie2').html('');
        $('.hardie3').html('');
        $('.hardie4').html('');
        $('.hardie5').html('');
        $('.hardie6').html('');
        $('.hardie7').html('');
        $('.hardie8').html('');
        $('.hardie9').html('');
        $('.hardie10').html('');
        $('.hardie11').html('');
        $('.hardie12').html('');
        $('.accessories1').html('');
        $('.accessories2').html('');
        $('.accessories3').html('');
        $('.accessories4').html('');
        $('.accessories5').html('');
        $('.accessories6').html('');
        $('.accessories7').html('');
        $('.accessories8').html('');
        $('.accessories9').html('');
        $('.accessories10').html('');
        $('.accessories11').html('');
        $('.accessories12').html('');
        $('.accessories13').html('');
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function computeRoofingPrice(labor_type_id, location_id, name, val){
        if(name == 'Tear-Off SQ'){		
            sidingTIWithFanfold = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingTIWithFanfold += laborPrice['13' + '-' + location_id + '-' + 'Install SQ'] * val;
            sidingTIWithFanfold += laborPrice['14' + '-' + location_id + '-' + 'Fanfold'] * val;
            sidingTIWithFanfold += avgSidingCostSQ * val;
            sidingPrice += sidingTIWithFanfold;
        }else if(name == 'Install SQ'){
            sidingTIWithOutFanfold = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingTIWithOutFanfold += laborPrice['12' + '-' + location_id + '-' + 'Tear-Off SQ'] * val;
            sidingTIWithOutFanfold += avgSidingCostFFSQ * val;
            sidingPrice += sidingTIWithOutFanfold;
        }else if(name == 'Gable Vent'){
            sidingGableVent = laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingGableVent;
        }else if(name == 'S Window Wrap'){
            sidingWindowWrapSmall = (avgSidingWindowWrap/10) * val;
            sidingWindowWrapSmall += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapSmall;
        }else if(name == 'M Window Wrap'){
            sidingWindowWrapMedium = (avgSidingWindowWrap/8) * val;
            sidingWindowWrapMedium += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapMedium;
        }else if(name == 'L Window Wrap'){
            sidingWindowWrapLarge = (avgSidingWindowWrap/6) * val;
            sidingWindowWrapLarge += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingWindowWrapLarge;
        }else if(name == 'Car Door Wrap'){
            sidingGarageDoorWrap = (avgSidingWindowWrap/4) * val;
            sidingGarageDoorWrap += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingGarageDoorWrap;
        }else if(name == 'Door Wrap'){
            sidingDoorWrap = (avgSidingWindowWrap/5) * val;
            sidingDoorWrap += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingPrice += sidingDoorWrap;
        }else if(name == 'Soffit'){
            sidingSoffit12 = (avgSidingTrimCoil0192450/50) * val;
            sidingSoffit12 += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingSoffit12 += laborPrice['17' + '-' + location_id + '-' + 'Tear-Off Sof/Fas'] * val;
            sidingPrice += sidingSoffit12;
        }else if(name == 'Tear-Off Sof/Fas'){
            sidingSoffit24 = (avgSidingTrimCoil0192450/50) * val;
            sidingSoffit24 += 0.49 * val;
            sidingSoffit24 += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingSoffit24 += laborPrice['15' + '-' + location_id + '-' + 'Soffit'] * val;
            sidingPrice += sidingSoffit24;
        }else if(name == 'Fascia'){
            sidingFascia = (avgSidingT412SolidVSE/12) * val;
            sidingFascia += laborPrice[labor_type_id + '-' + location_id + '-' + name] * val;
            sidingFascia += laborPrice['17' + '-' + location_id + '-' + 'Tear-Off Sof/Fas'] * val;
            sidingPrice += sidingFascia;
        }
    }


    function RoofEstimatePricing25SQ(){
        avgOakridgeCostCount = 0;
        roofing25SQDurationCount = 0;
        roofing25SQDurationStormCount = 0;
        
        const items = JSON.parse(localStorage.getItem('_averageCostRoofing'));
        for (const item of items) {
            if(item.name == 'ABC 25SQ Duration Storm ABC') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration Storm Beacon') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration Storm Midwest') {
                if(item.avg_cost > 0){ roofing25SQDurationStormCount++; }
                roofing25SQDurationStormMidwest = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ ABC') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ Beacon') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ Duration HDZ Midwest') {
                if(item.avg_cost > 0){ roofing25SQDurationCount++; }
                roofing25SQDurationMidwest = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge ABC') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostABC = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge Beacon') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostBeacon = item.avg_cost;
            }else if(item.name == 'ABC 25SQ OC Oakridge Midwest') {
                if(item.avg_cost > 0){ avgOakridgeCostCount++; }
                avgOakridgeCostMidwest = item.avg_cost;
            }else if(item.name == 'Peel & Stick Cap') {
                avgPeelStickCapCost = item.avg_cost;
            }else if(item.name == 'Peel & Stick Base') {
                avgPeelStickBaseCost = item.avg_cost;
            }else if(item.name == 'avg siding cost sq') {
                avgSidingCostSQ = item.avg_cost;
            }else if(item.name == 'avg siding ff sq') {
                avgSidingCostFFSQ = item.avg_cost;
            }else if(item.name == 'siding window wrap') {
                avgSidingWindowWrap = item.avg_cost;
            }else if(item.name == 'T4 12" Solid/Vented Soffit Economy') {
                avgSidingT412SolidVSE = item.avg_cost;
            }else if(item.name == '.019 24X50 Trim Coil') {
                avgSidingTrimCoil0192450 = item.avg_cost;
            }
        }

        avgDStormCost = ((roofing25SQDurationStormABC + roofing25SQDurationStormBeacon + roofing25SQDurationStormMidwest) / roofing25SQDurationStormCount) /25;
        avgDurationHDZCost = ((roofing25SQDurationABC + roofing25SQDurationBeacon + roofing25SQDurationMidwest) / roofing25SQDurationCount) /25;
        avgOakridgeCost = ((avgOakridgeCostABC + avgOakridgeCostBeacon + avgOakridgeCostMidwest) / avgOakridgeCostCount) /25;
    }


    function ComputeCost(location_id, labor_type_id, val, name, trade_type, supplier_id) {
        const localData = JSON.parse(localStorage.getItem('_laborPrice'));
        for (const item of localData) {
            if(item.locationid == location_id && item.labortypeid == labor_type_id){
                //computeRoofingPrice(labor_type_id, location_id, name, val);
                //$('#price-siding').html('$' + numberWithCommas(Number(sidingPrice).toFixed(2)));
                //localStorage.setItem('price-siding', JSON.stringify(sidingPrice));
            }
        }
    }

    function setLaborPrice(){
        const localData = JSON.parse(localStorage.getItem('_laborPrice'))
        for (const item of localData) {
            laborPrice[item.labortypeid + '-' + item.locationid + '-' + item.labor_type] = item.price
        }
    }


    function computeCementSiding(name, trade_type, location_id, supplier_id, value){
        var computationDiscount = $('#discountPercentage').val()
        if(computationDiscount == "" || computationDiscount < 0){ computationDiscount = 9 }
        computationDiscount = (100 - computationDiscount) / 100;

        const items = JSON.parse(localStorage.getItem('_cementSiding'));
        var laborPriceSQ = 250;
        var trimPriceLF = 2;
        for (const item of items) {
            if(item.name == '5.25 Primed CM/SM' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie1 = item.price * value * item.quantity
                hardie1 = hardie1 + laborPriceSQ
                hardie1 = hardie1 * 2 * 1.1
                $('.hardie1-pieces').html(item.quantity)
                if(hardie1 > 0) { $('.hardie1').html('$' + numberWithCommas(Number(hardie1 * computationDiscount * 2.2).toFixed(2))); $('.hardie1_org').html('$' + numberWithCommas(Number(hardie1).toFixed(2))); } else { $('.hardie1').html('');  $('.hardie1_org').html(''); }
            }else if(item.name == '6.25 Primed CM/SM' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie2 = item.price * value * item.quantity
                hardie2 = hardie2 + laborPriceSQ
                hardie2 = hardie2 * 2 * 1.1
                $('.hardie2-pieces').html(item.quantity)
                if(hardie2 > 0) { $('.hardie2').html('$' + numberWithCommas(Number(hardie2 * computationDiscount * 2.2).toFixed(2))); $('.hardie2_org').html('$' + numberWithCommas(Number(hardie2).toFixed(2))); } else { $('.hardie2').html(''); $('.hardie2_org').html(''); }
            }else if(item.name == '7.25 Primed CM/SM' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie3 = item.price * value * item.quantity
                hardie3 = hardie3 + laborPriceSQ
                hardie3 = hardie3 * 2 * 1.1
                $('.hardie3-pieces').html(item.quantity)
                if(hardie3 > 0) { $('.hardie3').html('$' + numberWithCommas(Number(hardie3 * computationDiscount * 2.2).toFixed(2))); $('.hardie3_org').html('$' + numberWithCommas(Number(hardie3).toFixed(2))); } else { $('.hardie3').html(''); $('.hardie3_org').html(''); }
            }else if(item.name == '8.25 Primed CM/SM' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie4 = item.price * value * item.quantity
                hardie4 = hardie4 + laborPriceSQ
                hardie4 = hardie4 * 2 * 1.1
                $('.hardie4-pieces').html(item.quantity)
                if(hardie4 > 0) { $('.hardie4').html('$' + numberWithCommas(Number(hardie4 * computationDiscount * 2.2).toFixed(2))); $('.hardie4_org').html('$' + numberWithCommas(Number(hardie4).toFixed(2))); } else { $('.hardie4').html(''); $('.hardie4_org').html(''); }
            }else if(item.name == '4/4 2.5 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie5 = item.price * value / item.quantity
                hardie5 = hardie5 + trimPriceLF
                hardie5 = hardie5 * 2 * 1.1
                $('.hardie5-pieces').html(item.quantity / 12)
                if(hardie5 > 0) { $('.hardie5').html('$' + numberWithCommas(Number(hardie5 * computationDiscount * 2.2).toFixed(2))); $('.hardie5_org').html('$' + numberWithCommas(Number(hardie5).toFixed(2))); } else { $('.hardie5').html(''); $('.hardie5_org').html(''); }
            }else if(item.name == '4/4 5.5 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie6 = item.price * value / item.quantity
                hardie6 = hardie6 + trimPriceLF
                hardie6 = hardie6 * 2 * 1.1
                $('.hardie6-pieces').html(item.quantity / 12)
                if(hardie6 > 0) { $('.hardie6').html('$' + numberWithCommas(Number(hardie6 * computationDiscount * 2.2).toFixed(2))); $('.hardie6_org').html('$' + numberWithCommas(Number(hardie6).toFixed(2))); } else { $('.hardie6').html(''); $('.hardie6_org').html(''); }
            }else if(item.name == '4/4 7.25 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie7 = item.price * value / item.quantity
                hardie7 = hardie7 + trimPriceLF
                hardie7 = hardie7 * 2 * 1.1
                $('.hardie7-pieces').html(item.quantity / 12)
                if(hardie7 > 0) { $('.hardie7').html('$' + numberWithCommas(Number(hardie7 * computationDiscount * 2.2).toFixed(2))); $('.hardie7_org').html('$' + numberWithCommas(Number(hardie7).toFixed(2))); } else { $('.hardie7').html(''); $('.hardie7_org').html(''); }
            }else if(item.name == '4/4 11.25 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie8 = item.price * value / item.quantity
                hardie8 = hardie8 + trimPriceLF
                hardie8 = hardie8 * 2 * 1.1
                $('.hardie8-pieces').html(item.quantity / 12)
                if(hardie8 > 0) { $('.hardie8').html('$' + numberWithCommas(Number(hardie8 * computationDiscount * 2.2).toFixed(2))); $('.hardie8_org').html('$' + numberWithCommas(Number(hardie8).toFixed(2))); } else { $('.hardie8').html(''); $('.hardie8_org').html(''); }
            }else if(item.name == '5/4 3.5 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie9 = item.price * value / item.quantity
                hardie9 = hardie9 + trimPriceLF
                hardie9 = hardie9 * 2 * 1.1
                $('.hardie9-pieces').html(item.quantity / 12)
                if(hardie9 > 0) { $('.hardie9').html('$' + numberWithCommas(Number(hardie9 * computationDiscount * 2.2).toFixed(2))); $('.hardie9_org').html('$' + numberWithCommas(Number(hardie9).toFixed(2))); } else { $('.hardie9').html(''); $('.hardie9_org').html(''); }
            }else if(item.name == '5/4 5.5 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie10 = item.price * value / item.quantity
                hardie10 = hardie10 + trimPriceLF
                hardie10 = hardie10 * 2 * 1.1
                $('.hardie10-pieces').html(item.quantity / 12)
                if(hardie10 > 0) { $('.hardie10').html('$' + numberWithCommas(Number(hardie10 * computationDiscount * 2.2).toFixed(2))); $('.hardie10_org').html('$' + numberWithCommas(Number(hardie10).toFixed(2))); } else { $('.hardie10').html(''); $('.hardie10_org').html(''); }
            }else if(item.name == '5/4 7.25 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie11 = item.price * value / item.quantity
                hardie11 = hardie11 + trimPriceLF
                hardie11 = hardie11 * 2 * 1.1
                $('.hardie11-pieces').html(item.quantity / 12)
                if(hardie11 > 0) { $('.hardie11').html('$' + numberWithCommas(Number(hardie11 * computationDiscount * 2.2).toFixed(2))); $('.hardie11_org').html('$' + numberWithCommas(Number(hardie11).toFixed(2))); } else { $('.hardie11').html(''); $('.hardie11_org').html(''); }
            }else if(item.name == '5/4 11.25 Rustic Primed' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var hardie12 = item.price * value / item.quantity
                hardie12 = hardie12 + trimPriceLF
                hardie12 = hardie12 * 2 * 1.1
                $('.hardie12-pieces').html(item.quantity / 12)
                if(hardie12 > 0) { $('.hardie12').html('$' + numberWithCommas(Number(hardie12 * computationDiscount * 2.2).toFixed(2))); $('.hardie12_org').html('$' + numberWithCommas(Number(hardie12).toFixed(2))); } else { $('.hardie12').html(''); $('.hardie12_org').html(''); }
            }


            else if(item.name == 'Housewrap 3X165' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories1 = item.price * value;
                accessories1 = accessories1 * 2 * 1.1
                if(accessories1 > 0) { $('.accessories1').html('$' + numberWithCommas(Number(accessories1 * computationDiscount * 2.2).toFixed(2))); $('.accessories1_org').html('$' + numberWithCommas(Number(accessories1).toFixed(2))); } else { $('.accessories1').html(''); $('.accessories1_org').html(''); }
            }else if(item.name == 'Housewrap Tape' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories2 = item.price * value;
                accessories2 = accessories2 * 2 * 1.1
                if(accessories2 > 0) { $('.accessories2').html('$' + numberWithCommas(Number(accessories2 * computationDiscount * 2.2).toFixed(2))); $('.accessories2_org').html('$' + numberWithCommas(Number(accessories2).toFixed(2))); } else { $('.accessories2').html('');  $('.accessories2_org').html(''); }
            }else if(item.name == 'Touch Up Kits' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories3 = item.price * value;
                accessories3 = accessories3 * 2 * 1.1
                if(accessories3 > 0) { $('.accessories3').html('$' + numberWithCommas(Number(accessories3 * computationDiscount * 2.2).toFixed(2))); $('.accessories3_org').html('$' + numberWithCommas(Number(accessories3).toFixed(2))); } else { $('.accessories3').html(''); $('.accessories3_org').html(''); }
            }else if(item.name == '.019 24X50 Trim Coil' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories4 = item.price * value;
                accessories4 = accessories4 * 2 * 1.1
                if(accessories4 > 0) { $('.accessories4').html('$' + numberWithCommas(Number(accessories4 * computationDiscount * 2.2).toFixed(2))); $('.accessories4_org').html('$' + numberWithCommas(Number(accessories4).toFixed(2))); } else { $('.accessories4').html(''); $('.accessories4_org').html(''); }
            }else if(item.name == 'J-Block Light Block' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories5 = item.price * value;
                accessories5 = accessories5 * 2 * 1.1
                if(accessories5 > 0) { $('.accessories5').html('$' + numberWithCommas(Number(accessories5 * computationDiscount * 2.2).toFixed(2))); $('.accessories5_org').html('$' + numberWithCommas(Number(accessories5).toFixed(2))); } else { $('.accessories5').html(''); $('.accessories5_org').html(''); }
            }

            else if(item.name == 'Recessed J-Block/Split Mini' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories6 = item.price * value;
                accessories6 = accessories6 * 2 * 1.1
                if(accessories6 > 0) { $('.accessories6').html('$' + numberWithCommas(Number(accessories6 * computationDiscount * 2.2).toFixed(2))); $('.accessories6_org').html('$' + numberWithCommas(Number(accessories6).toFixed(2))); } else { $('.accessories6').html('');  $('.accessories6_org').html(''); }
            }else if(item.name == 'Dryer Vent' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories7 = item.price * value;
                accessories7 = accessories7 * 2 * 1.1
                if(accessories7 > 0) { $('.accessories7').html('$' + numberWithCommas(Number(accessories7 * computationDiscount * 2.2).toFixed(2))); $('.accessories7_org').html('$' + numberWithCommas(Number(accessories7).toFixed(2))); } else { $('.accessories7').html(''); $('.accessories7_org').html(''); }
            }else if(item.name == 'Hooded Vent' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories8 = item.price * value;
                accessories8 = accessories8 * 2 * 1.1
                if(accessories8 > 0) { $('.accessories8').html('$' + numberWithCommas(Number(accessories8 * computationDiscount * 2.2).toFixed(2))); $('.accessories8_org').html('$' + numberWithCommas(Number(accessories8).toFixed(2))); } else { $('.accessories8').html('');  $('.accessories8_org').html(''); }
            }else if(item.name == 'Color Match Caulk' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories9 = item.price * value;
                accessories9 = accessories9 * 2 * 1.1
                if(accessories9 > 0) { $('.accessories9').html('$' + numberWithCommas(Number(accessories9 * computationDiscount * 2.2).toFixed(2))); $('.accessories9_org').html('$' + numberWithCommas(Number(accessories9).toFixed(2))); } else { $('.accessories9').html(''); $('.accessories9_org').html(''); }
            }else if(item.name == 'Joint Flashing (Bear Skins)' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories10 = item.price * value;
                accessories10 = accessories10 * 2 * 1.1
                if(accessories10 > 0) { $('.accessories10').html('$' + numberWithCommas(Number(accessories10 * computationDiscount * 2.2).toFixed(2))); $('.accessories10_org').html('$' + numberWithCommas(Number(accessories10).toFixed(2))); } else { $('.accessories10').html(''); $('.accessories10_org').html(''); }
            }

            else if(item.name == 'Siding Coil Nail 2-3/16"' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories11 = item.price * value;
                accessories11 = accessories11 * 2 * 1.1
                if(accessories11 > 0) { $('.accessories11').html('$' + numberWithCommas(Number(accessories11 * computationDiscount * 2.2).toFixed(2)));  $('.accessories11_org').html('$' + numberWithCommas(Number(accessories11).toFixed(2))); } else { $('.accessories11').html(''); $('.accessories11_org').html(''); }
            }else if(item.name == 'Finish Nail 2.5" Straight' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories12 = item.price * value;
                accessories12 = accessories12 * 2 * 1.1
                if(accessories12 > 0) { $('.accessories12').html('$' + numberWithCommas(Number(accessories12 * computationDiscount * 2.2).toFixed(2))); $('.accessories12_org').html('$' + numberWithCommas(Number(accessories12).toFixed(2))); } else { $('.accessories12').html(''); $('.accessories12_org').html(''); }
            }else if(item.name == '18X24 Rectangle Gable Vent' && item.name == name && item.tradetypeid == trade_type && item.supplierid == supplier_id && item.locationid == location_id) {
                var accessories13 = item.price * value;
                accessories13 = accessories13 * 2 * 1.1
                if(accessories13 > 0) { $('.accessories13').html('$' + numberWithCommas(Number(accessories13 * computationDiscount * 2.2).toFixed(2))); $('.accessories13_org').html('$' + numberWithCommas(Number(accessories13).toFixed(2))); } else { $('.accessories13').html(''); $('.accessories13_org').html(''); }
            }
        }
    }


    function startComputation(){
        resetValues();
        RoofEstimatePricing25SQ()
        $('.compute-cost-cement').each(function(){
            var name = $(this).attr('data-name');
            var labor_type_id = $(this).attr('data-laborType');
            var trade_type = $(this).attr('data-tradeType');
            var location_id = $('#locations option:selected').val();
            var supplier_id = $(this).attr('data-supplier');
            var value = $(this).val();

            if(name == "No Of Salesman"){
                if(value > 0){
                    var foundThreshold = false;
                    const _salesCommission = JSON.parse(localStorage.getItem('_salesCommission'))
                    for (const item of _salesCommission) {
                        if(item.locationid == location_id && value <= item.threshold && !foundThreshold){
                            var salesCost = item.percent / 100;
                            salesCost *= $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val();
                            $('#salesCost').html('$' + numberWithCommas(Number(salesCost).toFixed(2)));
                            foundThreshold = true;
                        }
                    }
                }
            }else{
                if(value > 0) {
                    computeCementSiding(name, trade_type, location_id, supplier_id, value)                    
                    //ComputeCost(location_id, labor_type_id, value, name, trade_type, supplier_id);
                }
            }
        });	
    }

    function clearValues(){
        $('.sidingTIWithFanfold').html('')
        $('.sidingTIWithOutFanfold').html('')
        $('.sidingGableVent').html('')
        $('.sidingWindowWrapSmall').html('')
        $('.sidingWindowWrapMedium').html('')
        $('.sidingWindowWrapLarge').html('')
        $('.sidingGarageDoorWrap').html('')
        $('.sidingDoorWrap').html('')
        $('.sidingSoffit12').html('')
        $('.sidingSoffit24').html('')
        $('.sidingFascia').html('')

        //$('#price-siding').html('$0.00')
        localStorage.setItem('price-siding', 0)

        $('#BSANRDOverhead').html('')
        $('#BSANRDCredit').html('')
        $('#absoluteLowest').html('')
        $('#finalDeliveryPickupFees').html('')
        $('#taxesAddedTSP').html('')

        localStorage.setItem('taxAvgOakridgeCost', 0);
        localStorage.setItem('taxAvgDurationCost', 0);
        localStorage.setItem('taxAvgDStormCost', 0);
        localStorage.setItem('taxAvgSidingCost', 0);
        localStorage.setItem('taxAvgSidingFFCost', 0);
    }


    function totalEstimatedComputation(){
        RoofEstimatePricing25SQ()
        var tmpVal = ""

        var location_id = $('#locations option:selected').val();

        var _taxRates = JSON.parse(localStorage.getItem('_taxRates'))
        for (const item of _taxRates) {
            taxRates[item.locationid] = item.tax;
        }
        var _overhead = JSON.parse(localStorage.getItem('_overhead'))
        for (const item of _overhead) {
            overhead[item.locationid] = item.percent;
        }
        
        
        var taxesAddedTSP = 0;
        if($('input[data-name="OC Oakridge Arch*"]').val() > 0){
            taxAvgOakridgeCost = (avgOakridgeCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="OC Oakridge Arch*"]').value))
        }
        if($('input[data-name="OC Duration Tru-Def*"]').val() > 0){
            taxAvgDurationCost = (avgDurationHDZCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="OC Duration Tru-Def*"]').value))
        }
        if($('input[data-name="Duration Storm"]').val() > 0){
            taxAvgDStormCost = (avgDStormCost * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Duration Storm"]').value))
        }
        if($('input[data-name="Tear-Off SQ"]').val() > 0){
            taxAvgSidingCost = (avgSidingCostSQ * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Tear-Off SQ"]').value))
        }
        if($('input[data-name="Install SQ"]').val() > 0){
            taxAvgSidingFFCost = (avgSidingCostFFSQ * (taxRates[location_id] / 100) * Number(document.querySelector('[data-name="Install SQ"]').value))
        }
        
        
        taxesAddedTSP = 0;
        var taxesAddedTSPA = taxAvgOakridgeCost + taxAvgDurationCost + taxAvgDStormCost + taxAvgSidingCost + taxAvgSidingFFCost;       
        
        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#taxesAddedTSP').html('');
                $('#taxesAddedTSPA').html('');
        }else{
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                $('#taxesAddedTSP').html('$' + numberWithCommas(Number(taxesAddedTSP).toFixed(2)));
                $('#taxesAddedTSPA').html('$' + numberWithCommas(Number(taxesAddedTSPA).toFixed(2)));
            }else{
                $('#taxesAddedTSP').html('');
                $('#taxesAddedTSPA').html('');
            }
        }
        localStorage.setItem('taxesAddedTSP', taxesAddedTSP);
        localStorage.setItem('taxesAddedTSPA', taxesAddedTSPA);


        var TotalEstimatedCost = Number(localStorage.getItem('price-roof'))
        TotalEstimatedCost = (TotalEstimatedCost + Number(localStorage.getItem('price-siding')))
        TotalEstimatedCost = (TotalEstimatedCost + Number(localStorage.getItem('price-gutter')))

        var finalDeliveryPickupFees = 0;
        var finalDeliveryPickupFeesA = 0;
        if(($('input[data-name="OC Oakridge Arch*"]').val() +
            $('input[data-name="OC Duration Tru-Def*"]').val() +
            $('input[data-name="Duration Storm"]').val() + 
            $('input[data-name="Low-Pitch Roofing"]').val()) > 0){
                finalDeliveryPickupFeesA += 75;
                finalDeliveryPickupFees = 0;
        }
        if(($('input[data-name="Tear-Off SQ"]').val() +
            $('input[data-name="Install SQ"]').val() +
            $('input[data-name="S Window Wrap"]').val() + 
            $('input[data-name="Door Wrap"]').val() +
            $('input[data-name="Soffit"]').val() +
            $('input[data-name="Tear-Off Sof/Fas"]').val() +
            $('input[data-name="Fascia"]').val()) > 0){
                finalDeliveryPickupFeesA += 75;				
                finalDeliveryPickupFees = 0;
        }
        
        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#finalDeliveryPickupFees').html('');
                $('#finalDeliveryPickupFeesA').html('');
        }else{
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                $('#finalDeliveryPickupFees').html('$' + numberWithCommas(Number(finalDeliveryPickupFees).toFixed(2)));
                $('#finalDeliveryPickupFeesA').html('$' + numberWithCommas(Number(finalDeliveryPickupFeesA).toFixed(2)));
            }else{
                $('#finalDeliveryPickupFees').html('');
                $('#finalDeliveryPickupFeesA').html('');
            }
        }
        localStorage.setItem('finalDeliveryPickupFees', finalDeliveryPickupFees);
        localStorage.setItem('finalDeliveryPickupFeesA', finalDeliveryPickupFeesA);
        TotalEstimatedCost = (TotalEstimatedCost + taxesAddedTSP)
        TotalEstimatedCost = (TotalEstimatedCost + finalDeliveryPickupFees)

        if(document.getElementById('price-roof-org').innerHTML == '' && 
            document.getElementById('price-siding-org').innerHTML == '' &&
            document.getElementById('price-gutter-org').innerHTML == ''){
                $('#TotalEstimatedCost').html('');
        }else{    
            if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
                if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                    document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                    document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                        $('#TotalEstimatedCost').html('');
                    }else{
                        //$('#TotalEstimatedCost').html('$' + numberWithCommas(Number(TotalEstimatedCost).toFixed(2)));
                        $('#TotalEstimatedCost').html('$' + numberWithCommas((Number(TotalEstimatedCost) + Number(finalDeliveryPickupFeesA) + Number(taxesAddedTSPA)).toFixed(2)));
                    }
            }else{
                $('#TotalEstimatedCost').html('');
            }
        }
        localStorage.setItem('totalEstimatedCost', TotalEstimatedCost)


        
        var targetSalesPrice = ((TotalEstimatedCost * 2) * 1.1)
        localStorage.setItem('targetSalesPrice2', targetSalesPrice);
        if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
            if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#targetSalesPrice').html('');
                }else{
                    $('#targetSalesPrice').html('$' + numberWithCommas(Number(targetSalesPrice).toFixed(2)));
                }
        }else{
            $('#targetSalesPrice').html('');
        }
        $('.targetSalesPrice').html('$' + numberWithCommas(Number(targetSalesPrice).toFixed(2)));
        
        var numberTEN = ((TotalEstimatedCost * 2) * 1.1) * 0.9;
        $('#numberTEN').html('$' + numberWithCommas(Number(numberTEN).toFixed(2)));
        
        
        var BSANRDCredit = $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val();
        $('#BSANRDCredit').html('$' + numberWithCommas(Number(BSANRDCredit).toFixed(2)));

        var currentRevenue = BSANRDCredit + Number($('input[data-name="Agreed Retail Amounts"]').val());
        $('#currentRevenue').html('$' + numberWithCommas(Number(currentRevenue).toFixed(2)));
        
        var BSANRDOverhead = BSANRDCredit;
        BSANRDOverhead *= overhead[location_id] / 100;
        BSANRDOverhead += TotalEstimatedCost;
        $('#BSANRDOverhead').html('$' + numberWithCommas(Number(BSANRDOverhead).toFixed(2)));
        
        var absoluteLowest = targetSalesPrice * 0.55
        $('#absoluteLowest').html('$' + numberWithCommas(Number(absoluteLowest).toFixed(2)));

        var lowTargetSalesPrice = (TotalEstimatedCost -finalDeliveryPickupFees - taxesAddedTSP)* 1.6364
        localStorage.setItem('targetSalesPrice1', lowTargetSalesPrice);
        var lowTargetSalesPrice_step1 = lowTargetSalesPrice * 0.9
        if(localStorage.getItem('user_data') != null && JSON.parse(localStorage.getItem('user_data'))['admin'] == '1'){
            if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#lowTargetSalesPrice').html('');
                }else{
                    $('#lowTargetSalesPrice').html('$' + numberWithCommas(Number(lowTargetSalesPrice_step1).toFixed(2)));
                }
        }else{
            $('#lowTargetSalesPrice').html('');
        }
        $('.lowTargetSalesPrice').html('$' + numberWithCommas(Number(lowTargetSalesPrice_step1).toFixed(2)));

        var additionalDollarRequired = "N/A"
        if((lowTargetSalesPrice_step1 - currentRevenue) > 0){
            additionalDollarRequired = (lowTargetSalesPrice_step1 - currentRevenue)
            $('#additionalDollarRequired').html('$' + numberWithCommas(Number(additionalDollarRequired).toFixed(2)));
        }else{
            $('#additionalDollarRequired').html(additionalDollarRequired);
        }
        if(document.getElementById('price-roof-org').innerHTML == '$0.00' &&
                document.getElementById('price-siding-org').innerHTML ==  '$0.00' &&
                document.getElementById('price-gutter-org').innerHTML ==  '$0.00'){
                    $('#additionalDollarRequired').html('');
        }


        var overheadCost = currentRevenue * 0.22
        $('#overheadCost').html('$' + numberWithCommas(Number(overheadCost).toFixed(2)));

        var salesCommissionCost = currentRevenue * 0.12
        $('#salesCommissionCost').html('$' + numberWithCommas(Number(salesCommissionCost).toFixed(2)));

        var estimatedNetProfitAfterOverhead = currentRevenue - (overheadCost + salesCommissionCost) - TotalEstimatedCost
        if(estimatedNetProfitAfterOverhead < 0) { tmpVal = '$ (' + numberWithCommas(Number(estimatedNetProfitAfterOverhead).toFixed(2)) + ')'
        } else { tmpVal = '$' + numberWithCommas(Number(estimatedNetProfitAfterOverhead).toFixed(2)) }
        $('#estimatedNetProfitAfterOverhead').html(tmpVal);

        var netProfitAfterOverhead = estimatedNetProfitAfterOverhead / TotalEstimatedCost * 100
        $('#netProfitAfterOverhead').html(numberWithCommas(Number(netProfitAfterOverhead).toFixed(2)) + '%');

        
        var estimatedNetProfit = $('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val() - BSANRDOverhead;
        if(estimatedNetProfit < 0) {
            $('#estimatedNetProfit').html('$(' + numberWithCommas(Number((estimatedNetProfit * -1)).toFixed(2)) + ')');
        }else{
            $('#estimatedNetProfit').html('$' + numberWithCommas(Number(estimatedNetProfit).toFixed(2)));
        }
        
        var awayFromTarget = 1 - (BSANRDCredit / targetSalesPrice);
        awayFromTarget = awayFromTarget * 100;
        $('#awayFromTarget').html(Number(awayFromTarget).toFixed(1) + '%');
        
        
        var additionalRequiredAmount = 0;
        if(awayFromTarget < 45){
            additionalRequiredAmount = "N/A";
            $('#estimatedNetProfit').html(additionalRequiredAmount);
        }else{
            additionalRequiredAmount = absoluteLowest - ($('input[data-name="Total BSA"]').val() - $('input[data-name="NRD or Credit"]').val());
            $('#additionalRequiredAmount').html('$' + numberWithCommas(Number(additionalRequiredAmount).toFixed(2)));
        }

        
        var targetSalesDiscount = (Number(localStorage.getItem('targetSalesPrice2')) - Number(lowTargetSalesPrice_step1))
        $('.targetSalesDiscount').html('$' + numberWithCommas(Number(targetSalesDiscount).toFixed(2)))
        localStorage.setItem('targetSalesDiscount', targetSalesDiscount);
        var val3 = (Number(localStorage.getItem('targetSalesPrice2')) - Number(lowTargetSalesPrice_step1)) / Number(localStorage.getItem('targetSalesPrice2')) * 100
        var commission = 0
        var median = (Number(localStorage.getItem('targetSalesPrice2')) + (Number(localStorage.getItem('targetSalesPrice1')) * 0.9)) / 2;
         var medianDiscount = (lowTargetSalesPrice_step1 - median) / median * 100;
         if(medianDiscount > 19.9 && medianDiscount <= 40.9) { commission = 11 / 100 } else
            if(medianDiscount > 9.9 && medianDiscount <= 19.9) { commission = 10 / 100 } else
            if(medianDiscount > -1.9 && medianDiscount <= 9.9) { commission = 9 / 100 } else
            if(medianDiscount > -9.9 && medianDiscount <= -1.9) { commission = 7 / 100 } else
            if(medianDiscount > -15.9 && medianDiscount <= -9.9) { commission = 5 / 100 } else
            if(medianDiscount > -16.9 && medianDiscount <= -15.9) { commission = 3 / 100 } else
            if(medianDiscount > -25.9 && medianDiscount <= -16.9) { commission = 2 / 100 } else
            { commission = 0 }
         /*
         if(val3 > -1 && val3 <= 5.9) { commission = 12 / 100 } else
         if(val3 > 5.9 && val3 <= 10.9) { commission = 9.5 / 100 } else
         if(val3 > 10.9 && val3 <= 15.9) { commission = 8.5 / 100 } else
         if(val3 > 15.9 && val3 <= 20.9) { commission = 7 / 100 } else
         if(val3 > 20.9 && val3 <= 30.9) { commission = 5 / 100 } else
         if(val3 > 30.9 && val3 <= 40.9) { commission = 2.5 / 100 } else { commission = 0 }
         */
        var targetSalesCommission = Number(lowTargetSalesPrice_step1) * commission
        localStorage.setItem('targetSalesCommissionPercentage', (commission * 100));
        localStorage.setItem('targetSalesCommission', targetSalesCommission);
        $('.targetSalesCommission').html('$' + numberWithCommas(Number(targetSalesCommission).toFixed(2)));

        /***** Computation of default Discount - Begin *****/
        var inputDiscount = $('#discountPercentage').val()
        
        if(inputDiscount == "" || inputDiscount < 0){
            inputDiscount = 9
        }
        $('#discountPercentage').val(inputDiscount).trigger("input")
        /***** Computation of default Discount - End *****/
    }



    $(function() {
        $(document).on('input', '.compute-cost-cement', function(e){
            setLaborPrice()
            startComputation()
            totalEstimatedComputation()
        })

        
        $(document).on('change', '#locations', function(e){
            clearValues()
            setLaborPrice()
            startComputation()
            totalEstimatedComputation()
        })
        
    })

    return (
        <table class="table table-bordered table-striped table-hover PriceInput-Bx centerPC">
            <thead class="tableHead">
                <tr>
                    <th>Cement Siding</th>
                    <th className="wd-50">Quantity</th>
                    <th className="wd-50">Pcs Needed</th>
                    <th className="wd-220">Price</th>
                    <th className="wd-220 admin-content">Cost</th>
                </tr>
            </thead>
            <tbody>



            <tr>
                <td>5.25 Primed CM/SM</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="5.25 Primed CM/SM" data-total="hardie1" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie1-pieces emptyVal"></td>
                <td class="hardie1 emptyVal" ></td>
                <td class="hardie1_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>6.25 Primed CM/SM</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="6.25 Primed CM/SM" data-total="hardie2" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie2-pieces emptyVal"></td>
                <td class="hardie2 emptyVal" ></td>
                <td class="hardie2_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>7.25 Primed CM/SM</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="7.25 Primed CM/SM" data-total="hardie3" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie3-pieces emptyVal"></td>
                <td class="hardie3 emptyVal" ></td>
                <td class="hardie3_org emptyVal admin-content" ></td>
            </tr>


            <tr>
                <td>8.25 Primed CM/SM</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="8.25 Primed CM/SM" data-total="hardie4" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie4-pieces emptyVal"></td>
                <td class="hardie4 emptyVal" ></td>
                <td class="hardie4_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>4/4 2.5 Rustic Primed</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="4/4 2.5 Rustic Primed" data-total="hardie5" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie5-pieces emptyVal"></td>
                <td class="hardie5 emptyVal" ></td>
                <td class="hardie5_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>4/4 5.5 Rustic Primed</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="4/4 5.5 Rustic Primed" data-total="hardie6" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie6-pieces emptyVal"></td>
                <td class="hardie6 emptyVal" ></td>
                <td class="hardie6_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>4/4 7.25 Rustic Primed</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="4/4 7.25 Rustic Primed" data-total="hardie7" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie7-pieces emptyVal"></td>
                <td class="hardie7 emptyVal" ></td>
                <td class="hardie7_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>4/4 11.25 Rustic Primed</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="4/4 11.25 Rustic Primed" data-total="hardie8" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie8-pieces emptyVal"></td>
                <td class="hardie8 emptyVal" ></td>
                <td class="hardie8_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>5/4 3.5 Rustic Primed</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="5/4 3.5 Rustic Primed" data-total="hardie9" type="text" className="compute-cost-cement quantity form-control input-number" /></td> 
                    </div>
                </td>
                <td className="hardie9-pieces emptyVal"></td>
                <td class="hardie9 emptyVal" ></td>
                <td class="hardie9_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>5/4 5.5 Rustic Primed</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="5/4 5.5 Rustic Primed" data-total="hardie10" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie10-pieces emptyVal"></td>
                <td class="hardie10 emptyVal" ></td>
                <td class="hardie10_org emptyVal admin-content" ></td>
            </tr>

            <tr>
                <td>5/4 7.25 Rustic Primed</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="5/4 7.25 Rustic Primed" data-total="hardie11" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie11-pieces emptyVal"></td>
                <td class="hardie11 emptyVal" ></td>
                <td class="hardie11_org emptyVal admin-content" ></td>
            </tr>

            <tr>
                <td>5/4 11.25 Rustic Primed</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="4" data-tradeType="2" data-name="5/4 11.25 Rustic Primed" data-total="hardie12" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td className="hardie12-pieces emptyVal"></td>
                <td class="hardie12 emptyVal" ></td>
                <td class="hardie12_org emptyVal admin-content" ></td>
            </tr>






            <tr>
                <th colSpan={5}>Accessories</th>
            </tr>


            <tr>
                <td>Housewrap 3x165</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="Housewrap 3X165" data-total="accessories1" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories1 emptyVal" ></td>
                <td class="accessories1_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Housewrap Tape</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="Housewrap Tape" data-total="accessories2" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories2 emptyVal" ></td>
                <td class="accessories2_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Touch Up Kits</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="Touch Up Kits" data-total="accessories3" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories3 emptyVal" ></td>
                <td class="accessories3_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>.019 24X50 Trim Coil</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name=".019 24X50 Trim Coil" data-total="accessories4" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories4 emptyVal" ></td>
                <td class="accessories4_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>J-Block Light Block</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="J-Block Light Block" data-total="accessories5" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories5 emptyVal" ></td>
                <td class="accessories5_org emptyVal admin-content" ></td>
            </tr>



            <tr>
                <td>Recessed J-Block/Split Mini</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="Recessed J-Block/Split Mini" data-total="accessories6" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories6 emptyVal" ></td>
                <td class="accessories6_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Dryer Vent</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="Dryer Vent" data-total="accessories7" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories7 emptyVal" ></td>
                <td class="accessories7_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Hooded Vent</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="Hooded Vent" data-total="accessories8" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories8 emptyVal" ></td>
                <td class="accessories8_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Color Match Caulk</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="Color Match Caulk" data-total="accessories9" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories9 emptyVal" ></td>
                <td class="accessories9_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Joint Flashing (Bear Skins)</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="Joint Flashing (Bear Skins)" data-total="accessories10" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories10 emptyVal" ></td>
                <td class="accessories10_org emptyVal admin-content" ></td>
            </tr>




            <tr>
                <td>Siding Coil Nail 2-3/16"</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name='Siding Coil Nail 2-3/16"' data-total="accessories11" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories11 emptyVal" ></td>
                <td class="accessories11_org emptyVal admin-content" ></td>
            </tr>
            <tr>
                <td>Finish Nail 2.5" Straight</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name='Finish Nail 2.5" Straight' data-total="accessories12" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories12 emptyVal" ></td>
                <td class="accessories12_org emptyVal admin-content" ></td>
            </tr><tr>
                <td>18X24 Rectangle Gable Vent</td>
                <td>
                    <div class="input-group inpuBx-val">
                    <td><input data-supplier="1" data-tradeType="2" data-name="18X24 Rectangle Gable Vent" data-total="accessories13" type="text" className="compute-cost-cement quantity form-control input-number" /></td>
                    </div>
                </td>
                <td></td>
                <td class="accessories13 emptyVal"></td>
                <td class="accessories13_org emptyVal admin-content" ></td>
            </tr>

            </tbody>
        </table>
        )
}

export default VinylSidingTable