import React, { useState, useEffect, Component } from "react";

import Header from '../essentials/Header.js'
import SideNav from '../essentials/SideNav.js'
import Footer from '../essentials/Footer.js'
import MainCont from '../essentials/MainCont.js'

import YourEstimates from '../partials/YourEstimates.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GuttersTable from "../partials/GuttersTable.js";
import $ from 'jquery';


import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';



class ViewEstimates extends Component {
    render() {
      return (
        <div className="ameriproBk">
            <Header></Header>
            <div className="mid-cont">
				<div className="container">
					<div className="row">
            <div className="col-sm-12">
                <YourEstimates></YourEstimates>
            </div>
					</div>
				</div>
			</div>
		</div>
      )
    }
}

export default ViewEstimates